import { Component, Input } from '@angular/core';

export enum InsigExpansionPanelState {
  EXPANDED = 'expanded',
  COLLAPSED = 'collapsed',
}

@Component({
  selector: 'insig-ui-expansion-panel',
  templateUrl: './insig-expansion-panel.component.html',
  styleUrls: ['./insig-expansion-panel.component.scss'],
})
export class InsigExpansionPanelComponent {
  @Input() expanded = false;
  @Input() flushStyle = false;
  @Input() disableBoxShadow = false;
  public state = this.expanded ? InsigExpansionPanelState.EXPANDED : InsigExpansionPanelState.COLLAPSED;

  handleExpansionPanelOpened(): void {
    this.state = InsigExpansionPanelState.EXPANDED;
  }

  handleExpansionPanelClosed(): void {
    this.state = InsigExpansionPanelState.COLLAPSED;
  }
}
