/**
 * Insig Booking Flow APIs
 * The specification contains all booking flow APIs
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: support@insighealth.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type BookingFlowVersion = 'refreshed1' | 'quickConnect1' | 'legacy';

export const BookingFlowVersion = {
    REFRESHED_1: 'refreshed1' as BookingFlowVersion,
    QUICK_CONNECT_1: 'quickConnect1' as BookingFlowVersion,
    LEGACY: 'legacy' as BookingFlowVersion
};

