import { Component, Input } from '@angular/core';

@Component({
  selector: 'insig-ui-table',
  templateUrl: './insig-table.component.html',
  styleUrls: ['./insig-table.component.scss'],
})
export class InsigTableComponent {
  @Input() smallBorderSpacing = false;
}
