<div class="px-2 px-md-5">
  <h1>Choose a time</h1>
  <p role="doc-subtitle" class="mb-3">Select the medium & choose a time</p>

  <ng-container *ngIf="!loading; then chooseTimeScreen else loadingScreen"></ng-container>

  <insig-ui-card class="sticky-bottom d-flex d-lg-none">
    <insig-ui-button
      stylePreset="tertiary"
      (click)="navigateToChooseDoctorPage()"
    >
      Back
    </insig-ui-button>
  </insig-ui-card>
</div>

<ng-template #chooseTimeScreen>
  <insig-ui-card>
    <div class="row">
      <div class="col-12 col-lg-2 d-flex flex-column justify-content-between">
        <insig-booking-appointment-summary [doctorId]="doctorId" [serviceId]="serviceId">
        </insig-booking-appointment-summary>
        <div class="d-none d-lg-flex align-self-end me-auto">
          <insig-ui-button
            stylePreset="tertiary"
            (click)="navigateToChooseDoctorPage()"
          >
            Back
          </insig-ui-button>
        </div>
      </div>

      <div class="col-12 col-lg-10">
        <insig-booking-appointment-medium-select [availableMediums]="availableAppointmentMediums" (selectedMediumChange)="selectedMedium = $event">
        </insig-booking-appointment-medium-select>

        <hr/>

        <insig-booking-appointment-slot-week-select *ngIf="currentStartDate !== undefined"
          [initialStartDate]="currentStartDate"
          [earliestAvailableTime]="appointment.earliestAvailabilityForAppointment"
          (startDateChange)="currentStartDate = $event"
        >
        </insig-booking-appointment-slot-week-select>

        <insig-booking-appointment-slot-calendar
          [doctorId]="doctorId"
          [startDate]="currentStartDate"
          [slotDuration]="appointment.duration"
        >
        </insig-booking-appointment-slot-calendar>
      </div>
    </div>
  </insig-ui-card>
</ng-template>

<ng-template #loadingScreen>
  <div class="h-100 d-flex justify-content-center align-items-center">
    <mat-progress-spinner class="m-auto" mode="indeterminate"></mat-progress-spinner>
  </div>
</ng-template>
