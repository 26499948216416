import { init as sentryInit } from '@sentry/angular-ivy';

export function initializeSentry(environment: { production?: boolean, version: string }): void {
  const appEnvironment = environment.production ? 'production' : 'development';
  sentryInit({
    dsn: 'https://5ecb704c8b4a495f8ca8f780a55a0cb7@o495184.ingest.sentry.io/5815990',
    release: `booking-app@${environment.version}-${appEnvironment}`,
    environment: appEnvironment,
    denyUrls: [/.*localhost.*/],
    attachStacktrace: true,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'Non-Error exception captured with keys: error, headers, message, name, ok',
    ],
  });
}
