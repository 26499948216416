import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ExtendedSlotData } from '@insig-health/services/doctor/doctor.service';

const HOURS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23] as const;
export type AvailableTimeSlotsByHour = {
  [hour in typeof HOURS[number]]: ExtendedSlotData[];
};

@Component({
  selector: 'insig-booking-daily-slot',
  templateUrl: './daily-slot.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DailySlotComponent implements OnChanges {

  @Input() availabilityData: ExtendedSlotData[] = [];
  @Input() currentWeekStartDate: string | undefined;

  public timeSlots: ExtendedSlotData[] = [];
  public availableTimeSlotsByHour: AvailableTimeSlotsByHour = this.getEmptyAvailableTimeSlotsByHour();
  public hours = HOURS;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.availabilityData === undefined) {
      return;
    }

    const availabilityData = changes.availabilityData !== undefined ?
      changes.availabilityData.currentValue :
      this.availabilityData;

    this.availableTimeSlotsByHour = this.mapAvailableTimeSlotsToHour(availabilityData);
  }

  mapAvailableTimeSlotsToHour(availabilityData: ExtendedSlotData[]): AvailableTimeSlotsByHour {
    return availabilityData.reduce((availableTimeSlotsByHour, timeSlot) => {
      const hour = this.getHourOfTimeSlot(timeSlot);
      availableTimeSlotsByHour[hour].push(timeSlot);
      return availableTimeSlotsByHour;
    }, this.getEmptyAvailableTimeSlotsByHour());
  }

  getEmptyAvailableTimeSlotsByHour(): AvailableTimeSlotsByHour {
    return HOURS.reduce((availableTimeSlots, hour) => {
      availableTimeSlots[hour] = [];
      return availableTimeSlots;
    }, {} as AvailableTimeSlotsByHour);
  }

  getHourOfTimeSlot(timeSlot: ExtendedSlotData): typeof HOURS[number] {
    return timeSlot.startDate.getHours() as typeof HOURS[number];
  }

  getHourLabel(hour: typeof HOURS[number]): string {
    const amOrPm = hour < 11 || hour === 23 ? 'AM' : 'PM';
    const startHour = hour % 12 || 12;
    const endHour = (startHour + 1) % 12 || 12;

    return `${startHour} - ${endHour} ${amOrPm}`;
  }
}
