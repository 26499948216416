import { Component, inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DateAndTimeService } from '@insig-health/services/date-and-time/date-and-time.service';

export interface DateChangeDialogData {
  startTime: string;
}

@Component({
  selector: 'insig-booking-date-change-dialog',
  templateUrl: './date-change-dialog.component.html',
})
export class DateChangeDialogComponent {
  private readonly data = inject<DateChangeDialogData>(MAT_DIALOG_DATA);
  public readonly dialogRef = inject<MatDialogRef<DateChangeDialogComponent>>(MatDialogRef<DateChangeDialogComponent>);
  private readonly dateAndTimeService = inject(DateAndTimeService);
  public dayOfWeek: string;
  public month: string;
  public date: string;
  public year: string;

  constructor(
  ) {
    const timezone = this.dateAndTimeService.getLocalTimeZone();
    const startTimeIso = this.dateAndTimeService.getDateAsIsoString(
      new Date(this.data.startTime),
      timezone,
    );

    this.dayOfWeek = this.dateAndTimeService.getDayOfTheWeek(
      new Date(startTimeIso),
      timezone,
    );
    this.month = this.dateAndTimeService.getMonthName(new Date(startTimeIso), timezone);
    this.date = this.dateAndTimeService.getDateWithOrdinal(
      new Date(startTimeIso),
      timezone,
    );
    this.year = this.dateAndTimeService.getYear(new Date(startTimeIso), timezone);
  }
}
