import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DoctorMetadata } from '@insig-health/api/doctor-booking-flow-api-v1';

@Pipe({
  standalone: true,
  name: 'doctorTitleAndNamePipe',
})
@Injectable()
export class DoctorTitleAndNamePipe implements PipeTransform {
  transform(value: DoctorMetadata): string {
    if (value.title) {
      return `${value.title} ${value.fullName}`;
    } else {
      return `${value.fullName}`;
    }
  }

}
