/**
 * Insig Booking Flow APIs
 * The specification contains all booking flow APIs
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: support@insighealth.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type AppointmentServiceMedium = 'IN_CLINIC' | 'PHONE' | 'MESSAGING' | 'VIDEO';

export const AppointmentServiceMedium = {
    InClinic: 'IN_CLINIC' as AppointmentServiceMedium,
    Phone: 'PHONE' as AppointmentServiceMedium,
    Messaging: 'MESSAGING' as AppointmentServiceMedium,
    Video: 'VIDEO' as AppointmentServiceMedium
};

