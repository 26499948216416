<label class="w-100 bold">
  <span class="textGray">{{ label }}</span>
  <span *ngIf="_isRequired" class="textPink">&nbsp;*</span>
  <div class="mt-3 position-relative">
    <input
      tabindex="0"
      #matDatepickerInput="matDatepickerInput"
      class="textDark bold fs-6"
      [matDatepicker]="matDatepicker"
      [min]="minDate"
      [max]="maxDate"
      [value]="value"
      [disabled]="_isDisabled"
      placeholder="YYYY-MM-DD"
      (input)="handleChange($event)"
      (change)="handleChange($event)"
      (dateChange)="handleDateChange($event)"
      (blur)="handleBlur($event)"
    />
    <mat-datepicker-toggle matSuffix [for]="matDatepicker" [attr.disabled]="_isDisabled"></mat-datepicker-toggle>
    <mat-datepicker #matDatepicker></mat-datepicker>
  </div>
</label>
