import { Injectable } from '@angular/core';
import { InsigBookingTheme } from '@insig-health/config/config';
import { Province } from '@insig-health/services/province/province.service';
import { BillingRegion, RegionType } from '../billing-region/billing-region.service';
import { BillingType } from '../billing-type/billing-type.service';

export interface ThemeConfig {
  header: {
    color: string;
    logoUrl?: string;
    stepper: {
      stepBubbleColor: string;
      stepLineColor: string;
      completedStepColor: string;
      activateStepColor: string;
    };
    languageSelector: {
      iconFilter: string;
      labelColor: string;
    };
  };
  loginWidget: {
    isLogoVisible: boolean;
  };
  isRegionSelectorVisible: boolean;
  validBillingRegions: Array<BillingRegion>; // Leave empty if all regions are valid
  defaultBillingRegion: BillingRegion;
}

const ONTARIO_PUBLIC = {
  type: RegionType.PROVINCE,
  provinceAbbreviation: Province.ON,
  billingType: BillingType.PUBLIC,
} as const;

const BRITISH_COLUMBIA_PUBLIC = {
  type: RegionType.PROVINCE,
  provinceAbbreviation: Province.BC,
  billingType: BillingType.PUBLIC,
} as const;

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  public static readonly THEME_CONFIGS: { [key in InsigBookingTheme]: ThemeConfig } = Object.freeze({
    [InsigBookingTheme.DEFAULT]: {
      header: {
        color: 'white',
        logoUrl: undefined,
        stepper: {
          stepBubbleColor: 'var(--tiaBackground)',
          stepLineColor: 'var(--tiaBackground)',
          completedStepColor: 'var(--tiaDarkGray)',
          activateStepColor: 'var(--tiaLightGray)',
        },
        languageSelector: {
          iconFilter: 'none',
          labelColor: 'var(--tiaSecondaryText)',
        },
      },
      loginWidget: {
        isLogoVisible: true,
      },
      isRegionSelectorVisible: true,
      validBillingRegions: [],
      defaultBillingRegion: ONTARIO_PUBLIC,
    },
    [InsigBookingTheme.BC]: {
      header: {
        color: 'rgb(8, 49, 85)',
        logoUrl: '/assets/images/bc-tia-logo.svg',
        stepper: {
          stepBubbleColor: 'var(--tiaLightGray)',
          stepLineColor: 'var(--tiaLightGray)',
          completedStepColor: 'var(--tiaLightGray)',
          activateStepColor: 'var(--tiaLightGray)',
        },
        languageSelector: {
          iconFilter: 'invert(100%) sepia(100%) saturate(2%) hue-rotate(214deg) brightness(110%) contrast(101%)',
          labelColor: 'var(--tiaLightGray)',
        },
      },
      loginWidget: {
        isLogoVisible: false,
      },
      isRegionSelectorVisible: false,
      validBillingRegions: [
        BRITISH_COLUMBIA_PUBLIC,
      ],
      defaultBillingRegion: BRITISH_COLUMBIA_PUBLIC,
    },
  });

  private _currentTheme: InsigBookingTheme = InsigBookingTheme.DEFAULT;

  constructor() {
    this.setCurrentTheme(this._currentTheme);
  }

  public getCurrentTheme(): InsigBookingTheme {
    return this._currentTheme;
  }

  public setCurrentTheme(theme: InsigBookingTheme): void {
    const themeConfig = ThemeService.THEME_CONFIGS[theme];
    const rootElement = document.querySelector(':root') as HTMLElement;

    if (themeConfig && rootElement) {
      this._currentTheme = theme;
      rootElement.style.setProperty('--themeHeaderColor', themeConfig.header.color);

      rootElement.style.setProperty('--themeHeaderStepperStepBubbleColor', themeConfig.header.stepper.stepBubbleColor);
      rootElement.style.setProperty('--themeHeaderStepperStepLineColor', themeConfig.header.stepper.stepLineColor);
      rootElement.style.setProperty('--themeHeaderStepperActiveStepColor', themeConfig.header.stepper.activateStepColor);
      rootElement.style.setProperty('--themeHeaderStepperCompletedStepColor', themeConfig.header.stepper.completedStepColor);
      rootElement.style.setProperty('--themeHeaderStepperCompletedStepBubbleBorderColor', themeConfig.header.color);

      rootElement.style.setProperty('--themeHeaderLanguageSelectorIconFilter', themeConfig.header.languageSelector.iconFilter);
      rootElement.style.setProperty('--themeHeaderLanguageSelectorLabelColor', themeConfig.header.languageSelector.labelColor);
    }
  }

  public getCurrentThemeConfig(): ThemeConfig {
    return ThemeService.THEME_CONFIGS[this._currentTheme];
  }
}
