import { InsigBookingSite, InsigBookingTheme } from '@insig-health/config/config';

export const environment = {
  production: true,
  hmr: false,
  branch: 'production',
  inactivityTimeoutMs: 60 * 60 * 1000,
  stripeKey: 'pk_live_9WokTriA8wKYyADx7ZrgTQxQ',
  stripeClientId: 'ca_AMncGOos0gZ3yhfPWKmdLhjmJIJqme3i',
  cloudFunctionsEndpoint: 'https://us-central1-insig-ca.cloudfunctions.net/',
  version: '',
  firebaseConfig: {
    apiKey: 'AIzaSyB9e1iPCy9av-XjRK2Kp9RPjgMWJZTMqBY',
    authDomain: 'insig-ca.firebaseapp.com',
    databaseURL: 'https://insig-ca.firebaseio.com',
    projectId: 'insig-ca',
    storageBucket: 'insig-ca.appspot.com',
    messagingSenderId: '47931142088',
    appId: '1:47931142088:web:1a2314589268f1a0fcdafd',
  },
  appUrl: {
    default: 'https://app.insighealth.com/',
    map: [
      {
        origin: 'app.well.company',
        appUrl: 'https://app.wellclinics.ca/',
      },
      {
        origin: 'app.wellclinics.ca',
        appUrl: 'https://app.wellclinics.ca/',
      },
      {
        origin: 'app.jacknathanhealth.com',
        appUrl: 'https://app.jacknathanhealth.com/',
      },
      {
        origin: 'app.thevirtualdoctor.org',
        appUrl: 'https://app.thevirtualdoctor.org/',
      },
      {
        origin: 'virtual.highmark.tech',
        appUrl: 'https://virtual.highmark.tech/',
      },
      {
        origin: 'app.tiahealth.com',
        appUrl: 'https://app.tiahealth.com/',
      },
      {
        origin: 'app.yourcare.health',
        appUrl: 'https://app.yourcare.health/',
      },
      {
        origin: 'beta1.insighealth.com',
        appUrl: 'https://beta.tiahealth.com/',
      },
      {
        origin: 'beta.tiahealth.com',
        appUrl: 'https://beta.tiahealth.com/',
      },
      {
        origin: 'book-beta.insighealth.com',
        appUrl: 'https://beta.tiahealth.com/',
      },
      {
        origin: 'book-beta.tiahealth.com',
        appUrl: 'https://beta.tiahealth.com/',
      },
    ],
  },
  awsCloudFunctionsEndpoint: {
    default: 'https://api.goinsig.com/',
    map: [
      {
        origin: 'beta1.insighealth.com',
        awsCloudFunctionsEndpoint: 'https://beta.api.goinsig.com/',
      },
      {
        origin: 'beta.tiahealth.com',
        awsCloudFunctionsEndpoint: 'https://beta.api.goinsig.com/',
      },
      {
        origin: 'book-beta.insighealth.com',
        awsCloudFunctionsEndpoint: 'https://beta.api.goinsig.com/',
      },
      {
        origin: 'book-beta.tiahealth.com',
        awsCloudFunctionsEndpoint: 'https://beta.api.goinsig.com/',
      },
    ],
  },
  javaBackendEndpoint: {
    default: 'https://services-prod.insighealth.com/',
    map: [
      {
        origin: 'beta1.insighealth.com',
        javaBackendEndpoint: 'https://services-beta.goinsig.com/',
      },
      {
        origin: 'beta.tiahealth.com',
        javaBackendEndpoint: 'https://services-beta.goinsig.com/',
      },
      {
        origin: 'book-beta.insighealth.com',
        javaBackendEndpoint: 'https://services-beta.goinsig.com/',
      },
      {
        origin: 'book-beta.tiahealth.com',
        javaBackendEndpoint: 'https://services-beta.goinsig.com/',
      },
    ],
  },
  newBookingFlowUrl: {
    default: 'https://book.tiahealth.com',
    map: [
      {
        origin: 'beta1.insighealth.com',
        newBookingFlowUrl: 'https://book-beta.tiahealth.com',
      },
      {
        origin: 'beta.tiahealth.com',
        newBookingFlowUrl: 'https://book-beta.tiahealth.com',
      },
      {
        origin: 'book-beta.insighealth.com',
        newBookingFlowUrl: 'https://book-beta.tiahealth.com',
      },
      {
        origin: 'book-beta.tiahealth.com',
        newBookingFlowUrl: 'https://book-beta.tiahealth.com',
      },
    ],
  },
  insigBookingTheme: {
    map: [
      {
        origin: 'https://book-bc.tiahealth.com',
        theme: InsigBookingTheme.BC,
      },
    ],
  },
  insigBookingSite: {
    default: InsigBookingSite.TIA,
    map: [
      {
        origin: 'https://book-bc.tiahealth.com',
        site: InsigBookingSite.BC,
      },
    ],
  },
  insigCoreSite: {
    default: 'https://app.insighealth.com/app/virtual/dashboard',
    map: [
      {
        origin: 'book-beta.insighealth.com',
        site: 'https://beta.tiahealth.com/app/virtual/dashboard',
      },
      {
        origin: 'book-beta.tiahealth.com',
        site: 'https://beta.tiahealth.com/app/virtual/dashboard',
      },
    ],
  },
  awsWafCaptchaApiKey: 'jbpt3vMcCKpRmUc2shVH23ksUEknKQFJ6HnCkgdaoRJX+JHR/m4gQBc+IDLjNSFAxSgd/Nuzl6HJuUuTP55oQWojt/VC7yzAotNfwh3Z6LwdjkLS/sZTJHzmbsDGEHAhaN1hlbgZjZdtT6BzD8UXwrNRzGEJJ17jADg93sv40I8DmaHZV3YHNGwKEeBcWinw3LxoWVAp7j+KQDFCVISlZ6Ft3al5sd++q9ULp8r5ovEuKJStG87PbD7Ki9d+xzY/XCvVgYV+LyGKJcNZo3tYAEupxMaVEVmJ5eGatTVnr9mC8MzAubSaUi1l0Ru9oA4i1TjEiVM0aqNzz+0YPBvjWvmemuUhqu6+zvFm+0s9RwGAN3NvcnzRixMRZcWPkY0r7zXgGN+Udr9BQgHQNT4rNrVGHVi/WPuUYRwvi15+FB+omjMZdxYNn8kdkvJ6s7peTq7dpPLGSPEcm5YKC+0Qo9Pob2wg6EXMgv+0rzzICVraBbC0zwO7CsJEfx4dU1IOtsPdiEx8NDY8mLbuhri/WrGymGLIhuYQqFBqXI1q8S/14WyGW47lx3MjtrM0uNlaIKDhel0ARhn0RigsXbh+EGr8dhlb7eos8bSlRtALRilHqWM8tQcKhWXInwinP52SWD05wLrMXNYYGJeRBsUFw7xhMNDr0M8SMlByE3rr5tM=_0_1',
  awsWafCaptchaScriptUrl: 'https://d8a031c1915a.ca-central-1.captcha-sdk.awswaf.com/d8a031c1915a/jsapi.js',
  pdfBasePath: 'https://express.goinsig.com/s3/insig-filled-pdfs/getPdf/',
  taliAiScriptUrl: undefined,
};
