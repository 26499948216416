<mat-expansion-panel
  class="d-block bg-white px-2"
  [ngClass]="{
    'disableBoxShadow': disableBoxShadow,
    'border-0 rounded-0': flushStyle,
    'container-border-radius container-border container-box-shadow-light-grey my-2 p-2': !flushStyle
  }"
  (opened)="handleExpansionPanelOpened()"
  (closed)="handleExpansionPanelClosed()"
  [expanded]="expanded"
>
  <mat-expansion-panel-header class="px-0 px-md-4">
    <mat-panel-title class="w-100">
      <ng-content select="[header]"></ng-content>
    </mat-panel-title>
    <mat-panel-description>
      <ng-content select="[description]"></ng-content>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <div [ngClass]="{ 'mt-4': !flushStyle }">
    <ng-content select="[content]"></ng-content>
  </div>
</mat-expansion-panel>
