<div *ngIf="doctorSearchData !== undefined">
  <insig-ui-profile-picture
    class="d-none d-lg-inline"
    [imageUrl]="doctorSearchData.doctorMetadata.profilePictureUrl"
    width="64px"
    height="64px"
    alt="Doctor's profile picture"
  ></insig-ui-profile-picture>
  <div class="font-size-lg mt-0 mt-lg-3 bold notranslate">{{getDoctorTitleAndName(doctorSearchData)}}</div>
  <div>{{doctorSearchData.doctorMetadata.specialty}}</div>
</div>

<insig-booking-selected-appointment-options
  [serviceType]="serviceType"
></insig-booking-selected-appointment-options>
