import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@insig-health/config/environment';
import {
  Appearance,
  CreateTokenCardData,
  StripeCardNumberElement,
  StripeElements,
  StripeElementsOptionsClientSecret,
} from '@stripe/stripe-js';
import { firstValueFrom } from 'rxjs';
import { StripeClientService } from '../stripe-client/stripe-client.service';

export interface StripeRedirectParameters {
  doctorId: string | undefined;
  appointmentMedium: string | undefined;
  appointmentId: string | undefined;
  serviceId: string | undefined;
  serviceType: string | undefined;
  stripePaymentIntentClientSecret: string | undefined;
  stripePaymentDetails: string | undefined;
}

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  private static readonly DEFAULT_APPEARANCE: Appearance = {
    theme: 'stripe',
    variables: {
      colorText: '#7b8ca8',
      fontSizeBase: '13px',
      fontFamily: 'Poppins, sans-serif',
      fontWeightNormal: '600',
      fontLineHeight: '18px',
      borderRadius: '4px',
    },
  };
  public static readonly DEFAULT_PAYMENT_STATUS_ERROR_MESSAGE = 'Something went wrong with the payment';
  public static readonly PAYMENT_STATUS_NOT_SUPPORTED_ERROR_MESSAGE = 'Stripe payment status is not supported';

  private readonly stripeClientService = inject(StripeClientService);
  private readonly http = inject(HttpClient);

  public stripeRedirectUrl = `${window.location.origin}/booking/stripeRedirect`;

  async getStripeClientSecret(): Promise<string> {
    return 'pi_foo_secret_bar';
  }

  async validateStripeClientSecret(clientSecret: string): Promise<void> {
    const validationUrl = `https://api.stripe.com/v1/elements/sessions?key=${environment.stripeKey}&type=payment_intent&locale=en-US&client_secret=${clientSecret}&expand[0]=payment_method_preference.payment_intent.payment_method`;
    await firstValueFrom(this.http.get(validationUrl));
  }

  async mountStripePaymentForm(elementId: string, clientSecret: string, appearance: Appearance = StripeService.DEFAULT_APPEARANCE): Promise<StripeElements> {
    await this.validateStripeClientSecret(clientSecret);

    const stripeElements = (await this.stripeClientService.getStripeClientPromise()).elements({ clientSecret, appearance });
    const paymentElement = stripeElements.create('payment');
    paymentElement.mount(`#${elementId}`);

    return stripeElements;
  }

  async makeStripePayment(stripeElements: StripeElements, returnUrl: string, redirectQueryParameters: StripeRedirectParameters): Promise<void> {
    const queryParameters = Object.keys(redirectQueryParameters).map((redirectQueryParameterKey) => {
      return `${redirectQueryParameterKey}=${redirectQueryParameters[redirectQueryParameterKey as keyof StripeRedirectParameters]}&`;
    });

    const queryParameterString = queryParameters.reduce((previouseValue, currentValue) => {
      return `${previouseValue}${currentValue}`;
    });

    const stripe = await this.stripeClientService.getStripeClientPromise();
    const paymentStatus = await stripe.confirmPayment({
      elements: stripeElements,
      confirmParams: {
        // eslint-disable-next-line camelcase
        return_url: `${returnUrl}?${queryParameterString}`,
      },
    });

    if (paymentStatus.error) {
      console.error(paymentStatus.error);
      throw new Error(paymentStatus.error.message);
    }
  }

  async getStripePaymentStatus(clientPaymentIntentSecret: string): Promise<boolean> {
    const stripe = await this.stripeClientService.getStripeClientPromise();
    const paymentIntent = await stripe.retrievePaymentIntent(clientPaymentIntentSecret);

    if (paymentIntent.error) {
      console.error(paymentIntent.error);
      throw new Error(paymentIntent.error.message);
    }

    if (paymentIntent.paymentIntent?.status === 'succeeded') {
      return true;
    } else if (paymentIntent.paymentIntent?.status === 'processing' || paymentIntent.paymentIntent?.status === 'requires_payment_method') {
      console.error(paymentIntent.paymentIntent);
      throw new Error(paymentIntent.paymentIntent.description ?? StripeService.DEFAULT_PAYMENT_STATUS_ERROR_MESSAGE);
    } else {
      console.error(StripeService.PAYMENT_STATUS_NOT_SUPPORTED_ERROR_MESSAGE);
      throw new Error(StripeService.PAYMENT_STATUS_NOT_SUPPORTED_ERROR_MESSAGE);
    }
  }

  async getStripeElements(options?: StripeElementsOptionsClientSecret): Promise<StripeElements> {
    return (await this.stripeClientService.getStripeClientPromise()).elements(options);
  }

  async getStripeCardNumberElementTokenId(stripeCardNumberElement: StripeCardNumberElement, additionalData?: CreateTokenCardData): Promise<string> {
    const stripe = await this.stripeClientService.getStripeClientPromise();
    const { token, error } = await stripe.createToken(stripeCardNumberElement, additionalData);
    if (error) {
      throw new Error(error.message);
    } else {
      return token.id;
    }
  }
}
