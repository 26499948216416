import { Injectable } from '@angular/core';

export enum BillingType {
  PUBLIC = 'public',
  PRIVATE = 'private',
}

@Injectable({
  providedIn: 'root',
})
export class BillingTypeService {
  parseBillingType(billingTypeString: string): BillingType {
    const lowerCaseString = billingTypeString.trim().toLowerCase();
    for (const billingType of Object.values(BillingType)) {
      if (lowerCaseString === billingType) {
        return billingType;
      }
    }
    throw new Error(`Unknown value for billing type ${lowerCaseString}`);
  }
}
