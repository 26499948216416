import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'insig-booking-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
  @ViewChild('searchInput') searchInput: ElementRef | undefined;
  @Output() searchInputChanged = new EventEmitter();

  handleSearchInputChanged(inputEvent: Event): void {
    const targetInput = inputEvent.target as HTMLInputElement;
    this.searchInputChanged.emit(targetInput.value);
  }

  clearSearchInput(): void {
    if (this.searchInput) {
      (this.searchInput.nativeElement as HTMLInputElement).value = '';
    }
  }
}
