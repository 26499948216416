<div class="w-100">
  <div class="w-100 d-flex flex-column h-40vh overflow-auto">
    <div class="w-100 d-flex gap-0 position-sticky">
      <ng-container *ngFor="let dateDifference of DIFFERENCES_IN_DAYS">
        <ng-container *ngIf="dateAndTimeService.addDaysToDate(startDate, dateDifference) as date">
          <insig-booking-daily-slot-header class="flex-even p-1 p-md-2" [date]="date">
          </insig-booking-daily-slot-header>
        </ng-container>
      </ng-container>
    </div>

    <div class="w-100 d-flex gap-0">
      <ng-container *ngFor="let dateDifference of DIFFERENCES_IN_DAYS">
        <ng-container *ngIf="dateAndTimeService.addDaysToDate(startDate, dateDifference) as date">
          <insig-booking-daily-slot
            class="flex-even p-1 p-md-2"
            [availabilityData]="doctorSlotsByDate[dateAndTimeService.getDateAsYearMonthDay(date, dateAndTimeService.getLocalTimeZone())] ?? []"
            [currentWeekStartDate]="currentWeekStartDate"
          >
          </insig-booking-daily-slot>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
