<h1 matDialogTitle class="pt-0">Delete this patient?</h1>

<p mat-dialog-content class="pt-2 pb-3">
  Are you sure you want to delete {{ data.fullName }} from your account? This action cannot be undone.
</p>

<div mat-dialog-actions class="d-flex justify-content-between">
  <insig-ui-button mat-dialog-close stylePreset="tertiary" (click)="dialogRef.close(false)">
    No
  </insig-ui-button>
  <insig-ui-button stylePreset="primary" (click)="dialogRef.close(true)">
    Yes
  </insig-ui-button>
</div>
