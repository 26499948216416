type BooleanPromiseCallback<T> = ConstructorParameters<typeof Promise<T>>[0];
type PromiseResolveExecutor<T> = Parameters<BooleanPromiseCallback<T>>[0];
type PromiseRejectExecutor<T> = Parameters<BooleanPromiseCallback<T>>[1];
type PromiseRejectionReason<T> = Parameters<PromiseRejectExecutor<T>>[0];

export class PromiseWithExposedExecutors<T> {
  private _promise: Promise<T>;
  private _resolve: PromiseResolveExecutor<T> = () => {
    throw new Error('NavigationPromise.resolve() is not initialized');
  };
  private _reject: PromiseRejectExecutor<T> = () => {
    throw new Error('NavigationPromise.reject() is not initialized');
  };

  constructor() {
    this._promise = new Promise<T>((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }

  public toPromise(): Promise<T> {
    return this._promise;
  }

  public resolve(value: T): void {
    this._resolve(value);
  }

  public reject(error: PromiseRejectionReason<T>): void {
    this._reject(error);
  }
}
