/**
 * Insig Booking Flow APIs
 * The specification contains all booking flow APIs
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: support@insighealth.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type BookingSite = 'tiahealth' | 'bchealth' | 'other';

export const BookingSite = {
    TIA_HEALTH: 'tiahealth' as BookingSite,
    BC_HEALTH: 'bchealth' as BookingSite,
    OTHER: 'other' as BookingSite
};

