<ng-container [formGroup]="healthCardNumberForm">
  <div class="d-flex gap-2 align-items-end">
    <insig-ui-text-input
      label="Health Card Number"
      [smallMargins]="true"
      [formControlName]="HEALTH_CARD_NUMBER_FORM_CONTROL_NAME"
    ></insig-ui-text-input>
    <insig-ui-button
      class="h-48px"
      [disabled]="healthCardNumberForm.invalid"
      iconSrc="assets/img/icon_save_white.svg"
      (click)="handleSaveButtonClicked(healthCardNumberForm.controls[HEALTH_CARD_NUMBER_FORM_CONTROL_NAME].value)"
    >
      Save
    </insig-ui-button>
  </div>
  <span class="textPink fw-normal py-2">&#9888; Health card invalid, please update to continue.</span>
</ng-container>
