import { Injectable } from '@angular/core';
import { environment } from '@insig-health/config/environment';
import { Stripe, loadStripe } from '@stripe/stripe-js';

@Injectable({
  providedIn: 'root',
})
export class StripeClientService {
  private static stripeClientPromise = StripeClientService.getStripeClient(environment.stripeKey ?? '');

  private static async getStripeClient(publicKey: string): Promise<Stripe> {
    let client: Stripe | null = null;
    let numberOfRetries = 5;

    while (client === null && numberOfRetries > 0) {
      try {
        client = await loadStripe(publicKey);
      } catch (error) {
        // ignore error and retry
      }
      numberOfRetries--;
    }

    if (client === null) {
      throw new Error('loadStripe returned a null value. Make sure you are running in a browser environment.');
    }

    return client;
  }

  getStripeClientPromise(): Promise<Stripe> {
    return StripeClientService.stripeClientPromise;
  }
}
