<label class="w-100">
  <span class="bold textSecondary">{{ label }}</span>
  <span *ngIf="_isRequired" class="textPink">&nbsp;*</span>

  <select
    class="fs-6 bold mt-3 bg-white w-100"
    isRequired="_isRequired"
    [value]="value"
    (change)="handleSelectChange($event)"
  >
    <ng-content></ng-content>
  </select>
</label>
