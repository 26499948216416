<div class="mapContainer">
  <google-map
    width="100%"
    height="100%"
    [center]="mapCenter"
    [zoom]="mapZoom"
    [options]="mapOptions"
  >
    <map-marker 
      *ngFor="let pharmacy of pharmacies"
      [position]="getPositionOfPharmacy(pharmacy)"
      [title]="pharmacy.name"
      (mapClick)="handleMarkerClicked(pharmacy)"
    ></map-marker>
  </google-map>
</div>
