import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { BillingType } from '../../services/billing-type/billing-type.service';
import { Province, ProvinceService } from '@insig-health/services/province/province.service';
import { BookingStepService } from '../../services/booking-step/booking-step.service';
import { CompanyBookingComponent } from '../../app/company-booking/company-booking.component';

@Injectable({
  providedIn: 'root',
})
export class ProvincePublicAppointmentsGuard implements CanActivate {
  private readonly provinceService = inject(ProvinceService);
  private readonly bookingStepService = inject(BookingStepService);
  private readonly router = inject(Router);

  canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot): boolean | UrlTree {
    const billingType = activatedRouteSnapshot.params.billingType;

    let province: Province | undefined;
    if (activatedRouteSnapshot.parent?.params.provinceAbbreviation) {
      try {
        province = this.provinceService.parseQueryParamProvince(activatedRouteSnapshot.parent?.params.provinceAbbreviation);
      } catch (error) {
        province = undefined;
      }
    }

    if (billingType === BillingType.PRIVATE && province !== undefined) {
      return true;
    }

    if (billingType === BillingType.PUBLIC && province !== undefined && this.provinceService.isProvinceAvailableForPublicAppointments(province)) {
      return true;
    }

    const deepestRouteChild = this.bookingStepService.getDeepestActivatedRouteChild(activatedRouteSnapshot);
    const companyBookingRoute = this.bookingStepService.getActivatedRouteOfComponentType(deepestRouteChild, CompanyBookingComponent);

    const urlTree = this.router.createUrlTree([
      'company',
      companyBookingRoute.params.companyId,
      'province',
      province ?? Province.ON,
      'billing',
      BillingType.PRIVATE,
      'chooseDoctor',
    ], {
      queryParams: activatedRouteSnapshot.queryParams,
    });

    return urlTree;
  }
}
