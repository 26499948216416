import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { HealthCard, HealthCardService } from 'apps/insig-booking/src/services/health-card/health-card.service';

import { FamilyMemberProfile, FamilyMemberService } from '@insig-health/services/family-member/family-member.service';
import { PatientProfile, PatientProfileService } from '@insig-health/services/patient-profile/patient-profile.service';
import { SuccessResponse } from '@insig-health/api/patient-management-api';
import { Province } from '@insig-health/services/province/province.service';
import { SNACK_BAR_AUTO_DISMISS_MILLISECONDS } from '@insig-health/config/angular-material.config';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'insig-booking-health-card-number-form',
  templateUrl: './health-card-number-form.component.html',
  styleUrls: ['./health-card-number-form.component.scss'],
})
export class HealthCardNumberFormComponent implements OnInit {
  public static readonly DEFAULT_ERROR_SNACKBAR_STRING = 'An error was encountered while updating the health card number';

  private readonly familyMemberService = inject(FamilyMemberService);
  private readonly healthCardService = inject(HealthCardService);
  private readonly patientProfileService = inject(PatientProfileService);
  private readonly snackBar = inject(MatSnackBar);

  public readonly HEALTH_CARD_NUMBER_FORM_CONTROL_NAME = 'healthCardNumber';
  public healthCardNumberForm = new FormGroup({
    [this.HEALTH_CARD_NUMBER_FORM_CONTROL_NAME]: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
  });

  @Input() isAccountHolder: boolean | undefined;
  @Input() patientProfile: PatientProfile | FamilyMemberProfile | undefined;

  @Output() healthCardSaved = new EventEmitter();

  ngOnInit(): void {
    if (this.isAccountHolder === undefined) {
      throw new Error('isAccountHolder not defined');
    }
    if (this.patientProfile === undefined) {
      throw new Error('patientProfile not defined');
    }
    this.autofillHealthCardNumber(this.patientProfile);
  }

  autofillHealthCardNumber(patientProfile: PatientProfile | FamilyMemberProfile): void {
    this.healthCardNumberForm.patchValue({
      [this.HEALTH_CARD_NUMBER_FORM_CONTROL_NAME]: patientProfile.healthCardNumber,
    });
  }

  async handleSaveButtonClicked(newHealthCardNumber: string): Promise<void> {
    if (this.patientProfile === undefined || this.isAccountHolder === undefined) {
      return;
    }

    try {
      const patientProvince = this.healthCardService.parseHealthCardProvince(this.patientProfile.province);
      if (!(await this.isHealthCardNumberValid(newHealthCardNumber, patientProvince))) {
        this.openSnackBar('Invalid health card number', SNACK_BAR_AUTO_DISMISS_MILLISECONDS);
        return;
      }

      if (this.isAccountHolder) {
        await this.updateAccountHolderHealthCardNumber(this.patientProfile as PatientProfile, newHealthCardNumber);
      } else {
        const familyMemberProfile = this.patientProfile as FamilyMemberProfile;
        await this.updateFamilyMemberHealthCardNumber(familyMemberProfile.patientId, familyMemberProfile, newHealthCardNumber);
      }
      this.openSnackBar('Health card number saved successfully', SNACK_BAR_AUTO_DISMISS_MILLISECONDS);
      this.healthCardSaved.emit();
    } catch (error) {
      console.error(error);
      this.openSnackBar(
        (error as HttpErrorResponse).error?.errorMessage ?? HealthCardNumberFormComponent.DEFAULT_ERROR_SNACKBAR_STRING,
        SNACK_BAR_AUTO_DISMISS_MILLISECONDS,
      );
    }
  }

  openSnackBar(message: string, durationInMilliseconds: number): void {
    this.snackBar.open(message, undefined, { duration: durationInMilliseconds });
  }

  isHealthCardNumberValid(healthCardNumber: string, province: Province): Promise<boolean> {
    const healthCard: HealthCard = {
      number: healthCardNumber,
      region: province,
    };
    return this.healthCardService.isHealthCardValid(healthCard);
  }

  updateAccountHolderHealthCardNumber(accountHolderProfile: PatientProfile, newHealthCardNumber: string): Promise<SuccessResponse> {
    const patientProfileUpdateRequest = {
      uid: accountHolderProfile.uid,
      healthCardNumber: newHealthCardNumber,
      province: accountHolderProfile.province,
    };
    return this.patientProfileService.setCurrentUserPatientProfile(patientProfileUpdateRequest);
  }

  updateFamilyMemberHealthCardNumber(accountHolderProfileUid: string, familyMemberProfile: FamilyMemberProfile, newHealthCardNumber: string): Promise<SuccessResponse> {
    familyMemberProfile.healthCardNumber = newHealthCardNumber;
    return this.familyMemberService.setFamilyMember(accountHolderProfileUid, familyMemberProfile.familyMemberId, familyMemberProfile);
  }
}
