<div role="button" class="checkbox" [ngClass]="{ disabled: _isDisabled }">
  <label class="d-flex align-items-center">
    <input type="checkbox"
      class="d-none"
      [checked]="_isChecked"
      [attr.disabled]="_isDisabled || null"
      (change)="handleChange($event)"
    />
    <span class="checkmark"></span>
    <div class="ps-2">
      <span class="bold textSecondary">{{ label }}&nbsp;</span>
      <ng-content></ng-content>   
      <span *ngIf="_isRequired" class="textPink">&nbsp;*</span>
    </div>
  </label>
</div>
