<label role="button"
  class="w-100 h-100 d-block bold"
>
  <input #radioInput class="d-none" type="radio"
    [name]="radioGroup"
    [checked]="_isChecked"
    [disabled]="_isDisabled"
    [value]="value"
    (change)="handleChange($event)"
    (siblingChanged)="handleSiblingChanged($event)"
  />
  <div
    class="h-100 d-flex align-items-center justify-content-center"
    [ngClass]="{ disabled: _isDisabled }"
  >
    <img [src]="imageUrl" alt="">
    <ng-content></ng-content>
  </div>
</label>
