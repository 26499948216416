<ng-container *ngTemplateOutlet="mobileView"></ng-container>
<ng-container *ngTemplateOutlet="desktopView"></ng-container>

<ng-template #mobileView>
  <div class="d-flex d-md-none flex-column gap-2">
    <div class="d-flex justify-content-between mb-2">
      <div>
        <div class="font-size-lg bold notranslate lineClampedText" [style.-webkit-line-clamp]="2">{{ getDoctorTitleAndName(doctorSearchData) }}</div>
        <div class="font-size-md">{{ doctorSearchData?.doctorMetadata?.specialty }}</div>
        <div class="textOrange font-size-md">{{ getBookingRestrictionsMessage(doctorSearchData) }}</div>
      </div>

      <ng-container *ngTemplateOutlet="editButton"></ng-container>
    </div>
    
    <ng-container *ngTemplateOutlet="appointmentDetails"></ng-container>
  </div>
</ng-template>

<ng-template #desktopView>
  <div class="d-none d-md-flex flex-column">
    <div class="d-flex justify-content-between align-items-center mb-4 px-2">
      <div class="font-size-lg bold">Appointment Details</div>
      <ng-container *ngTemplateOutlet="editButton"></ng-container>
    </div>

    <div class="d-flex gap-4">
      <div class="d-flex flex-column w-25 p-2">
        <div class="mb-1">
          <insig-ui-profile-picture
            [imageUrl]="doctorSearchData?.doctorMetadata?.profilePictureUrl"
            width="55px"
            height="55px"
            alt="Doctor's profile picture"
          ></insig-ui-profile-picture>
        </div>
        <div class="bold notranslate lineClampedText" [style.-webkit-line-clamp]="2">{{ getDoctorTitleAndName(doctorSearchData) }}</div>
        <div class="textSecondary">{{ doctorSearchData?.doctorMetadata?.specialty }}</div>
      </div>

      <div class="flex-grow-1 p-2">
        <ng-container *ngTemplateOutlet="appointmentDetails"></ng-container>
      </div>
    </div>
    <div class="ps-2 textOrange">{{ getBookingRestrictionsMessage(doctorSearchData) }}</div>

  </div>
</ng-template>

<ng-template #appointmentDetails>
  <insig-ui-table>
    <table aria-label="Appointment details">
      <tr class="border-bottom" *ngIf="servicePrice">
        <th scope="row">Price:</th>
        <td *ngIf="!discountedPrice">{{ getServicePriceText(servicePrice) }}</td>
        <td *ngIf="discountedPrice">Discount&nbsp;applied! <span class="textPink text-decoration-line-through">{{ getServicePriceText(servicePrice) }}</span>&nbsp;<span class="textGreen">{{ getServicePriceText(discountedPrice) }}</span></td>
      </tr>
      <tr class="border-bottom">
        <th scope="row">Date & Time:</th>
        <td>{{ getFormattedStartDateAndTime(draftAppointment?.startTime) }}</td>
      </tr>
      <tr class="border-bottom">
        <th class="d-none d-md-table-cell" scope="row">Appointment Type:</th>
        <th class="d-table-cell d-md-none" scope="row">Appointment:</th>
        <td>{{ serviceType }}</td>
      </tr>
      <tr>
        <th scope="row">Medium:</th>
        <td>
          <insig-booking-appointment-medium-select
            [availableMediums]="draftAppointment?.availableServiceMediums"
            [selectedMedium]="draftAppointment?.serviceMedium" 
            [displayText]="false"
            (selectedMediumChange)="handleAppointmentMediumSelected($event)"
          ></insig-booking-appointment-medium-select>
        </td>
      </tr>
    </table>
  </insig-ui-table>
</ng-template>

<ng-template #editButton>
  <div role="button" class="d-flex align-items-center" (click)="handleEditButtonClicked()">
    <img class="d-inline-block me-1" src="assets/img/ico-edit-pencil.svg" alt="Edit">
    <span class="bold textBlue">Edit</span>
  </div>
</ng-template>
