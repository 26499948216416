<div class="d-flex justify-content-between align-items-center">
  <h1 mat-dialog-title class="pt-0">New Patient Information</h1>
</div>

<form mat-dialog-content class="d-flex flex-column overflow-auto" [formGroup]="addNewPatientForm">
  <insig-booking-patient-profile-form
    #patientProfileForm
    [parentForm]="addNewPatientForm"
  ></insig-booking-patient-profile-form>
</form>

<div mat-dialog-actions class="d-flex justify-content-between">
  <insig-ui-button
    mat-dialog-close
    stylePreset="tertiary"
    (click)="handleBackButtonClicked()"
  >
    Back
  </insig-ui-button>
  <insig-ui-button
    [disabled]="addNewPatientForm.invalid"
    iconSrc="assets/img/ico-continue.svg"
    (click)="handleSaveButtonClicked(addNewPatientForm)"
  >
    Save
  </insig-ui-button>
</div>
