<div
  class="d-flex justify-content-center align-items-center px-3 py-2 fadeInAnimation timeExtendCont"
  [ngClass]="{ 'cursor-not-allowed': !currentReservedAppointmentSlot?.canDraftAppointmentExpiryBeExtended }"
  (click)="extendTime()"
>
  <div class="timeLabel">Time slot will expire&nbsp;in</div>
  <div class="timeCounter notranslate">{{ expiryCountdown }}</div>

  <ng-container *ngIf="currentReservedAppointmentSlot?.canDraftAppointmentExpiryBeExtended">
    <div class="separator"></div>

    <img src="assets/img/ico-tap-time.svg" alt="">
    <div class="tapText">Tap to Extend</div>
  </ng-container>
</div>
