import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';

import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ApiModule as DoctorBookingFlowApiModule, Configuration as DoctorBookingFlowConfiguration } from '@insig-health/api/doctor-booking-flow-api-v1';
import { ApiModule as PatientManagementApiModule, Configuration as PatientManagementConfiguration } from '@insig-health/api/patient-management-api';
import { ApiModule as PatientRegistrationApiModule, Configuration as PatientRegistrationConfiguration } from '@insig-health/api/patient-registration-api';
import { ApiModule as UserManagementApiModule, Configuration as UserManagementConfiguration } from '@insig-health/api/user-management-api';
import { ApiModule as AuthApiModule, Configuration as AuthConfiguration } from '@insig-health/api/auth-api';
import { ApiModule as DoctorApiModule, Configuration as DoctorConfiguration } from '@insig-health/api/doctor-api';

import { AppRoutingModule } from './app-routing.module';
import { InputMasksModule } from '@insig-health/input-masks/input-masks.module';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { StepperComponent } from './header/stepper/stepper.component';
import { ChooseDoctorComponent } from './choose-doctor/choose-doctor.component';
import { ChooseTimeComponent } from './choose-time/choose-time.component';
import { YourDetailsComponent } from './your-details/your-details.component';
import { ConfirmBookingComponent } from './confirm-booking/confirm-booking.component';
import { SearchComponent } from './search/search.component';
import { DailySlotComponent } from './choose-time/doc-details/daily-slot/daily-slot.component';
import { HourlySlotComponent } from './choose-time/doc-details/hourly-slot/hourly-slot.component';
import { DateChangeDialogComponent } from './choose-time/doc-details/date-change-dialog/date-change-dialog.component';
import { ConfirmAppointmentDetailsComponent } from './confirm-booking/confirm-appointment-details/confirm-appointment-details.component';
import { FamilyMemberSelectionComponent } from './confirm-booking/family-member-selection/family-member-selection.component';
import { DeletePatientWarningDialogComponent } from './confirm-booking/delete-patient-warning-dialog/delete-patient-warning-dialog.component';
import { PatientProfileFormComponent } from './confirm-booking/patient-profile-form/patient-profile-form.component';
import { EditPatientFormDialogComponent } from './confirm-booking/edit-patient-form-dialog/edit-patient-form-dialog.component';
import { EditFamilyMemberFormDialogComponent } from './confirm-booking/edit-family-member-form-dialog/edit-family-member-form-dialog.component';
import { SuccessfulBookingComponent } from './successful-booking/successful-booking.component';
import { TimeAlertComponent } from './header/time-alert/time-alert.component';
import { QuickConnectComponent } from './quick-connect/quick-connect.component';
import { ClinicSelectionComponent } from './clinic-selection/clinic-selection.component';
import { LanguageDropdownComponent } from './header/language-dropdown/language-dropdown.component';
import { UiComponentsModule } from '@insig-health/components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AWS_WAF_CAPTCHA_API_KEY, AWS_WAF_CAPTCHA_SCRIPT_URL, JAVA_BACKEND_ENDPOINT } from '@insig-health/config/config';

import { TimeslotReservationGuard } from '../guards/timeslot-reservation/timeslot-reservation.guard';

import { DoctorService } from '@insig-health/services/doctor/doctor.service';
import { FamilyMemberService } from '@insig-health/services/family-member/family-member.service';
import { GooglePlaceService } from '@insig-health/services/google-place/google-place.service';
import { TranslateService } from '@insig-health/services/translate/translate.service';
import { StripeCustomerService } from '../services/stripe-customer/stripe-customer.service';

import { MaterialComponentsModule } from './material-components.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PatientSignUpComponent } from './login/patient-sign-up/patient-sign-up.component';
import { PatientSignUpDetailsComponent } from './login/patient-sign-up/patient-sign-up-details.component';
import { DoctorCardComponent } from './choose-doctor/components/doctor-card/doctor-card.component';
import { AvailabilityLabelComponent } from './choose-doctor/components/availability-label/availability-label.component';
import { SpecialtyDropdownComponent } from './search/specialty-dropdown/specialty-dropdown.component';
import { InsigBookingSpringAuthorizationInterceptor } from '../interceptors/insig-booking-spring-authorization-interceptor/insig-booking-spring-authorization-interceptor';
import { ReferralNetworkService } from '@insig-health/services/referrals/referral-network.service';
import { NewTiaPatientTermsDialogComponent } from './terms-and-conditions/patient-terms-and-conditions/new-tia-patient-terms-dialog.component';
import { NavigationWarningDialogComponent } from './navigation-warning-dialog/navigation-warning-dialog.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { StripePaymentRedirectComponent } from './stripe-payment-redirect-page/stripe-payment-redirect.component';
import { AppointmentTypeDialogComponent } from './choose-doctor/components/appointment-type-dialog/appointment-type-dialog.component';
import { PharmacyMapComponent } from './pharmacy-map/pharmacy-map.component';
import { GcpIpModule } from '@insig-health/gcp-ip/gcp-ip.module';
import { DevEnvironmentLabelModule } from '@insig-health/dev-environment-label/dev-environment-label.module';
import { PharmacyInfoFormComponent } from './confirm-booking/pharmacy-info-form/pharmacy-info-form.component';
import { PharmacyStandAloneFormComponent } from './confirm-booking/pharmacy-info-stand-alone-form/pharmacy-stand-alone-form.component';
import { CompanyBookingComponent } from './company-booking/company-booking.component';
import { DoctorBookingComponent } from './doctor-booking/doctor-booking.component';
import { LoginComponent } from './login/login.component';
import { ChooseDoctorLoginComponent } from './choose-doctor-login/choose-doctor-login.component';
import { ChooseTimeLoginComponent } from './choose-time-login/choose-time-login.component';
import { DraftBookingComponent } from './draft-booking/draft-booking.component';
import { SelectedAppointmentOptionsComponent } from './choose-time/doc-details/selected-appointment-options/selected-appointment-options.component';
import { AppointmentReservationService } from '../services/appointment-reservation/appointment-reservation.service';
import { AppointmentMediumSelectComponent } from './appointment-medium-select/appointment-medium-select.component';
import { AppointmentSlotCalendarComponent } from './choose-time/appointment-slot-calendar/appointment-slot-calendar.component';
import { DailySlotHeaderComponent } from './choose-time/doc-details/daily-slot/daily-slot-header/daily-slot-header.component';
import { AppointmentSummaryComponent } from './choose-time/appointment-summary/appointment-summary.component';
import { AppointmentSlotWeekSelectComponent } from './choose-time/appointment-slot-week-select/appointment-slot-week-select.component';
import { ProvinceBookingComponent } from './province-booking/province-booking.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { DoctorCardDialogComponent } from './choose-doctor/components/doctor-card/doctor-card-dialog/doctor-card-dialog.component';
import { AddNewPatientFormDialogComponent } from './confirm-booking/add-new-patient-form-dialog/add-new-patient-form-dialog.component';
import { HealthCardNumberFormComponent } from './confirm-booking/health-card-number-form/health-card-number-form.component';
import { DiscountCodeFormComponent } from './confirm-booking/discount-code-form/discount-code-form.component';
import { LanguageSidenavComponent } from './sidenav/language-sideav/language-sidenav.component';
import { BillingTypeBookingComponent } from './billing-type-booking/billing-type-booking.component';
import { ProvinceSelectionDialogComponent } from './choose-doctor/components/province-selection-dialog/province-selection-dialog.component';
import { ReauthenticateDialogComponent } from '../dialogs/reauthenticate/reauthenticate-dialog.component';
import { StripePaymentSourceFormComponent } from './payment-source-form/stripe-payment-source-form/stripe-payment-source-form.component';
import { QuickBookComponent } from './choose-doctor/components/quick-book/quick-book.component';
import { PrescriptionDeliveryComponent } from './prescription-delivery/prescription-delivery.component';
import { PrescriptionDeliveryDialogComponent } from '../dialogs/prescription-delivery/prescription-delivery-dialog.component';
import { FocusMentalWellnessComponent } from './choose-doctor/components/focus-mental-wellness/focus-mental-wellness.component';
import { DefaultProvinceRedirectComponent } from './default-province-redirect-component/default-province-redirect.component';
import { PillwayPharmacyChangeWarningDialogComponent } from './prescription-delivery/pillway-pharmacy-change-warning-dialog/pillway-pharmacy-change-warning-dialog.component';
import { InternationalBookingComponent } from './international-booking/international-booking.component';
import { PatientTermsOfUseModule } from '@insig-health/patient-terms-of-use/patient-terms-of-use.module';
import { AWS_WAF_CAPTCHA_API_KEY_TOKEN, AWS_WAF_CAPTCHA_SCRIPT_URL_TOKEN } from '@insig-health/services/aws-waf-captcha/aws-waf-captcha.service';
import { FamilyDoctorFormComponent } from './confirm-booking/family-doctor-form/family-doctor-form.component';
import { DoctorTitleAndNamePipe } from '../pipes/doctor-title-and-name/doctor-title-and-name.pipe';
import { AwsWafCaptchaInterceptor } from '@insig-health/services/aws-waf-captcha/aws-waf-captcha.interceptor';
import { DateToTimeStringPipe } from '../pipes/date-to-time-string/date-to-time-string.pipe';
import { AwsWafCaptchaModule } from '@insig-health/services/aws-waf-captcha/aws-waf-captcha.module';
import { FamilyDoctorSearchModule } from '@insig-health/family-doctor-search/family-doctor-search.module';
import { DoctorLocationPipe } from '../pipes/doctor-location/doctor-location.pipe';
import { ScrollbarPaddingDirective } from '../directives/scrollbar-padding/scrollbar-padding.directive';
import { AddMinutesToDatePipe } from '../pipes/add-minutes-to-date/add-minutes-to-date.pipe';
import { LogoutButtonDirective } from '../directives/logout-button/logout-button.directive';

function apiConfigFactory<T>(configurationClass: new (params: { basePath: string }) => T): () => T {
  const params = {
    basePath: JAVA_BACKEND_ENDPOINT.slice(0, JAVA_BACKEND_ENDPOINT.length - 1),
  };
  return () => new configurationClass(params);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    StepperComponent,
    ChooseDoctorComponent,
    ChooseTimeComponent,
    YourDetailsComponent,
    ConfirmBookingComponent,
    SelectedAppointmentOptionsComponent,
    SearchComponent,
    DailySlotComponent,
    DailySlotHeaderComponent,
    HourlySlotComponent,
    DateChangeDialogComponent,
    ConfirmAppointmentDetailsComponent,
    FamilyMemberSelectionComponent,
    DeletePatientWarningDialogComponent,
    PatientProfileFormComponent,
    EditPatientFormDialogComponent,
    EditFamilyMemberFormDialogComponent,
    SuccessfulBookingComponent,
    PatientSignUpComponent,
    PatientSignUpDetailsComponent,
    TimeAlertComponent,
    QuickConnectComponent,
    ClinicSelectionComponent,
    LanguageDropdownComponent,
    DoctorCardComponent,
    AvailabilityLabelComponent,
    SpecialtyDropdownComponent,
    NewTiaPatientTermsDialogComponent,
    NavigationWarningDialogComponent,
    ErrorDialogComponent,
    StripePaymentRedirectComponent,
    AppointmentTypeDialogComponent,
    PharmacyMapComponent,
    PharmacyInfoFormComponent,
    PharmacyStandAloneFormComponent,
    CompanyBookingComponent,
    DoctorBookingComponent,
    DraftBookingComponent,
    BillingTypeBookingComponent,
    LoginComponent,
    ChooseDoctorLoginComponent,
    ChooseTimeLoginComponent,
    AppointmentMediumSelectComponent,
    AppointmentSlotCalendarComponent,
    AppointmentSummaryComponent,
    AppointmentSlotWeekSelectComponent,
    ProvinceBookingComponent,
    InternationalBookingComponent,
    SidenavComponent,
    LanguageSidenavComponent,
    DoctorCardDialogComponent,
    PharmacyMapComponent,
    AddNewPatientFormDialogComponent,
    HealthCardNumberFormComponent,
    DiscountCodeFormComponent,
    ProvinceSelectionDialogComponent,
    ReauthenticateDialogComponent,
    StripePaymentSourceFormComponent,
    QuickBookComponent,
    PrescriptionDeliveryComponent,
    PrescriptionDeliveryDialogComponent,
    PillwayPharmacyChangeWarningDialogComponent,
    FocusMentalWellnessComponent,
    DefaultProvinceRedirectComponent,
    FamilyDoctorFormComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    MaterialComponentsModule,
    DoctorBookingFlowApiModule.forRoot(apiConfigFactory(DoctorBookingFlowConfiguration)),
    PatientManagementApiModule.forRoot(apiConfigFactory(PatientManagementConfiguration)),
    PatientRegistrationApiModule.forRoot(apiConfigFactory(PatientRegistrationConfiguration)),
    UserManagementApiModule.forRoot(apiConfigFactory(UserManagementConfiguration)),
    AuthApiModule.forRoot(apiConfigFactory(AuthConfiguration)),
    DoctorApiModule.forRoot(apiConfigFactory(DoctorConfiguration)),
    CommonModule,
    BrowserModule,
    HttpClientModule,
    GoogleMapsModule,
    AppRoutingModule,
    InputMasksModule,
    NgbModule,
    UiComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    GcpIpModule,
    DevEnvironmentLabelModule,
    GcpIpModule,
    PatientTermsOfUseModule,
    AwsWafCaptchaModule,
    FamilyDoctorSearchModule,
    DoctorTitleAndNamePipe,
    DateToTimeStringPipe,
    DoctorLocationPipe,
    AddMinutesToDatePipe,
    ScrollbarPaddingDirective,
    LogoutButtonDirective,
  ],
  providers: [
    HttpClient,
    AppointmentReservationService,
    DoctorService,
    FamilyMemberService,
    GooglePlaceService,
    ReferralNetworkService,
    TranslateService,
    TimeslotReservationGuard,
    StripeCustomerService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InsigBookingSpringAuthorizationInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AwsWafCaptchaInterceptor,
      multi: true,
    },
    {
      provide: AWS_WAF_CAPTCHA_API_KEY_TOKEN,
      useValue: AWS_WAF_CAPTCHA_API_KEY,
    },
    {
      provide: AWS_WAF_CAPTCHA_SCRIPT_URL_TOKEN,
      useValue: AWS_WAF_CAPTCHA_SCRIPT_URL,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
