import '@angular/compiler';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '@insig-health/config/environment';
import firebase from 'firebase/compat/app';

import '@stripe/stripe-js';
import { initializeSentry } from './main.utilities';
import { initializeBookingFirebaseAnalyticsApp, initializeBookingFirebaseApp } from '@insig-health/config/firebaseConfig';

initializeSentry(environment);

if (environment.production) {
  enableProdMode();
}

if (firebase.apps.length === 0) {
  initializeBookingFirebaseApp({ isProduction: environment.production ?? false });
  initializeBookingFirebaseAnalyticsApp({ isProduction: environment.production ?? false });
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch((error) => console.error(error));
