import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'insig-ui-checkbox-button',
  templateUrl: './insig-checkbox-button.component.html',
  styleUrls: ['./insig-checkbox-button.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: InsigCheckboxButtonComponent,
  }],
})
export class InsigCheckboxButtonComponent implements ControlValueAccessor {
  public onChange?: (value: boolean) => void;
  public onTouched?: () => void;

  public _isChecked = false;
  public _isDisabled = false;
  public _isRequired = false;

  @Input() set isChecked(isChecked: boolean | 'true' | 'false') {
    this._isChecked = coerceBooleanProperty(isChecked);
  }
  @Input() set isDisabled(isDisabled: boolean | 'true' | 'false') {
    this._isDisabled = coerceBooleanProperty(isDisabled);
  }

  @Input() set isRequired(isRequired: boolean | 'true' | 'false') {
    this._isRequired = coerceBooleanProperty(isRequired);
  }

  @Input() label?: string;

  writeValue(isChecked: boolean): void {
    this._isChecked = isChecked;
  }

  registerOnChange(onChange: (value: boolean) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this._isDisabled = isDisabled;
  }

  handleChange(event: Event): void {
    const newValue = (event.target as HTMLInputElement).checked;
    this._isChecked = newValue;
    this.onChange?.(newValue);
    this.onTouched?.();
  }

}
