import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DoctorService, DoctorSearchData } from '@insig-health/services/doctor/doctor.service';
import { DateAndTimeService } from '@insig-health/services/date-and-time/date-and-time.service';
import { AppointmentMediumService } from '../../services/appointment-medium/appointment-medium.service';
import { Province } from '@insig-health/services/province/province.service';
import { BookingStepService } from '../../services/booking-step/booking-step.service';
import { CompanyBookingComponent } from '../company-booking/company-booking.component';
import { BillingType } from '../../services/billing-type/billing-type.service';

@Component({
  selector: 'insig-booking-successful-booking',
  templateUrl: './successful-booking.component.html',
  styleUrls: ['./successful-booking.component.scss'],
})
export class SuccessfulBookingComponent implements OnInit {
  private readonly route = inject(ActivatedRoute);
  private readonly bookingStepService = inject(BookingStepService);
  private readonly dateAndTimeService = inject(DateAndTimeService);
  private readonly doctorDataSearchWrapper = inject(DoctorService);
  private readonly appointmentMediumService = inject(AppointmentMediumService);
  public doctorId: string;
  public companyId: string;
  public appointmentMedium: string;
  public serviceId: string;
  public serviceType: string;
  public startTime: number;

  public doctorData: DoctorSearchData | undefined;

  public loading = false;

  constructor(
  ) {
    const companyBookingComponentRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.route, [CompanyBookingComponent]);
    this.companyId = companyBookingComponentRoute.snapshot.params.companyId;

    const successfulBookingComponentRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.route, [SuccessfulBookingComponent]);
    this.doctorId = successfulBookingComponentRoute.snapshot.params.doctorId;
    this.appointmentMedium = successfulBookingComponentRoute.snapshot.params.appointmentMedium;
    this.serviceId = successfulBookingComponentRoute.snapshot.params.serviceId;
    this.serviceType = successfulBookingComponentRoute.snapshot.params.serviceType;
    this.startTime = parseInt(successfulBookingComponentRoute.snapshot.params.startTime, 10);

  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.doctorData = await this.getDoctorData(this.doctorId, this.companyId);
    this.loading = false;
  }

  async getDoctorData(doctorId: string, companyId: string): Promise<DoctorSearchData | undefined> {
    const doctorFacetSearchData = await this.doctorDataSearchWrapper.getDoctorAndFacetData({
      companyId,
      doctorId,
      province: Province.ON,
      billingType: BillingType.PUBLIC,
    });

    this.loading = false;
    if (doctorFacetSearchData) {
      const foundDoctorData = doctorFacetSearchData.doctorSearchData.find((doctorSearchDataEntry) => {
        return doctorSearchDataEntry.doctorMetadata.id === doctorId;
      });

      if (foundDoctorData) {
        return foundDoctorData;
      }
    }
  }

  getFullStartDate(startTime: number | undefined): string {
    if (startTime === undefined) {
      return '';
    } else {
      const date = this.getDateFromUtcTimestamp(startTime);
      const timezone = this.dateAndTimeService.getLocalTimeZone();

      return this.dateAndTimeService.getReadableCalendarDate(date, timezone);
    }
  }

  getFormattedStartDate(startTime: number | undefined): string {
    if (startTime === undefined) {
      return '';
    } else {
      const date = this.getDateFromUtcTimestamp(startTime);
      const timezone = this.dateAndTimeService.getLocalTimeZone();
      return this.dateAndTimeService.getDateAsYearMonthDay(date, timezone);
    }
  }

  getFormattedStartTime(startTime: number | undefined): string {
    if (startTime === undefined) {
      return '';
    } else {
      const date = this.getDateFromUtcTimestamp(startTime);
      const timezone = this.dateAndTimeService.getLocalTimeZone();
      return this.dateAndTimeService.getHourMinuteAndPeriod(date, timezone);
    }
  }

  getDateFromUtcTimestamp(utcTimestamp: number): Date {
    return new Date(utcTimestamp);
  }

  getFormattedAppointmentMedium(appointmentMediumString: string | undefined): string {
    const appointmentMedium = this.appointmentMediumService.parseAppointmentMedium(appointmentMediumString);
    if (appointmentMedium) {
      return this.appointmentMediumService.getFormattedAppointmentMedium(appointmentMedium);
    } else {
      return '';
    }
  }
}
