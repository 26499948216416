<h1 matDialogTitle>{{titleLine}}</h1>

<div mat-dialog-content>
  <div class="px-2">
    <insig-health-patient-terms-of-use></insig-health-patient-terms-of-use>
  </div>
</div>

<hr>

<div class="d-flex justify-content-between px-4 pb-2">
  <insig-ui-button stylePreset="tertiary" (click)="dialogRef.close(false)">
    Decline
  </insig-ui-button>
  <insig-ui-button stylePreset="primary" (click)="dialogRef.close(true)">
    Accept
  </insig-ui-button>
</div>
