import { AfterViewInit, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CreateCustomerRequest } from '@insig-health/api/user-management-api';
import { SNACK_BAR_AUTO_DISMISS_MILLISECONDS } from '@insig-health/config/config';
import { StripeService } from '../../../services/stripe/stripe.service';
import { CreateTokenCardData, StripeCardCvcElement, StripeCardElementChangeEvent, StripeCardExpiryElement, StripeCardNumberElement, StripeElement, StripeElementType, StripeElements } from '@stripe/stripe-js';
import { PatientProfile } from '@insig-health/services/patient-profile/patient-profile.service';
import { StripeCustomerService, StripeCustomer } from 'apps/insig-booking/src/services/stripe-customer/stripe-customer.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'insig-booking-stripe-payment-source-form',
  templateUrl: './stripe-payment-source-form.component.html',
})
export class StripePaymentSourceFormComponent implements AfterViewInit {
  private static PAYMENT_FORM_ELEMENT_ID_PREFIX = 'insig-payment-form-element-';

  private static STRIPE_ELEMENT_STYLES = {
    base: {
      color: '#32325D',
      fontWeight: 500,
      fontFamily: 'Roboto',
      fontSize: '16px',
      fontSmoothing: 'antialiased',

      '::placeholder': {
        color: '#CFD7DF',
      },
      ':-webkit-autofill': {
        color: '#e39f48',
      },
    },
    invalid: {
      color: '#E25950',

      '::placeholder': {
        color: '#FFCCA5',
      },
    },
  };
  private static STRIPE_ELEMENT_CLASSES = {
    focus: 'focused',
    empty: 'empty',
    invalid: 'invalid',
  };

  private static componentCount = 0;

  public static readonly GENERIC_ERROR_MESSAGE = 'An error occurred';

  private readonly stripeService = inject(StripeService);
  private readonly stripeCustomerService = inject(StripeCustomerService);
  private readonly snackbar = inject(MatSnackBar);

  @Input() accountHolderProfile: PatientProfile | undefined;

  @Output() paymentSourceSaved = new EventEmitter<StripeCustomer>();

  public isCardFormComplete = false;
  public readonly CARD_NUMBER_ELEMENT_ID = StripePaymentSourceFormComponent.getElementId(StripePaymentSourceFormComponent.PAYMENT_FORM_ELEMENT_ID_PREFIX);
  public readonly CARD_EXPIRY_ELEMENT_ID = StripePaymentSourceFormComponent.getElementId(StripePaymentSourceFormComponent.PAYMENT_FORM_ELEMENT_ID_PREFIX);
  public readonly CARD_CVC_ELEMENT_ID = StripePaymentSourceFormComponent.getElementId(StripePaymentSourceFormComponent.PAYMENT_FORM_ELEMENT_ID_PREFIX);

  public stripeCardNumberElement: StripeCardNumberElement | undefined;

  public static getElementId(elementIdPrefix: string): string {
    this.componentCount++;
    return `${elementIdPrefix}${this.componentCount}`;
  }

  ngAfterViewInit(): void {
    this.mountStripeElementsCard();
  }

  async mountStripeElementsCard(): Promise<void> {
    const stripeElements = await this.stripeService.getStripeElements();

    this.stripeCardNumberElement = this.createStripeElement(stripeElements, 'cardNumber');
    this.stripeCardNumberElement.mount(`#${this.CARD_NUMBER_ELEMENT_ID}`);

    const stripeCardExpiryElement = this.createStripeElement(stripeElements, 'cardExpiry');
    stripeCardExpiryElement.mount(`#${this.CARD_EXPIRY_ELEMENT_ID}`);

    const stripeCardCvcElement = this.createStripeElement(stripeElements, 'cardCvc');
    stripeCardCvcElement.mount(`#${this.CARD_CVC_ELEMENT_ID}`);
  }

  createStripeElement(stripeElements: StripeElements, stripeElementType: 'cardNumber'): StripeCardNumberElement;
  createStripeElement(stripeElements: StripeElements, stripeElementType: 'cardExpiry'): StripeCardExpiryElement;
  createStripeElement(stripeElements: StripeElements, stripeElementType: 'cardCvc'): StripeCardCvcElement;
  createStripeElement(stripeElements: StripeElements, stripeElementType: StripeElementType): StripeElement {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore call overloaded function without generic
    return stripeElements.create(stripeElementType, {
      style: StripePaymentSourceFormComponent.STRIPE_ELEMENT_STYLES,
      classes: StripePaymentSourceFormComponent.STRIPE_ELEMENT_CLASSES,
      placeholder: '',
    });
  }

  handleCardElementChange(event: StripeCardElementChangeEvent): void {
    this.isCardFormComplete = event.complete;
  }

  async handleSaveButtonClicked(patientProfile: PatientProfile | undefined, stripeCardNumberElement: StripeCardNumberElement | undefined): Promise<void> {
    if (!patientProfile || !stripeCardNumberElement) {
      return;
    }

    let tokenId: string;
    try {
      tokenId = await this.getStripeTokenIdWithAdditionalUserData(patientProfile, stripeCardNumberElement);
      await this.createPatientCustomer(patientProfile, tokenId);
    } catch (error) {
      let errorMessage = '';
      if (error instanceof Error) {
        errorMessage = error.message;
      } else if (error instanceof HttpErrorResponse) {
        errorMessage = error.error.errorMessage;
      } else {
        errorMessage = StripePaymentSourceFormComponent.GENERIC_ERROR_MESSAGE;
      }
      this.snackbar.open(errorMessage, undefined, {
        duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS,
      });
      console.error(error);
    }
  }

  async createPatientCustomer(patientProfile: PatientProfile, tokenId: string): Promise<void> {
    const createCustomerRequest: CreateCustomerRequest = {
      email: patientProfile.email,
      paymentSourceToken: tokenId,
    };

    try {
      const createCustomerResponse = await this.stripeCustomerService.createPatientCustomer(patientProfile.uid, createCustomerRequest);

      this.paymentSourceSaved.emit(createCustomerResponse);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  getStripeTokenIdWithAdditionalUserData(accountHolderProfile: PatientProfile, stripeCardNumberElement: StripeCardNumberElement): Promise<string> {
    const additionalData: CreateTokenCardData = {
      name: `${accountHolderProfile.firstName} ${accountHolderProfile.lastName}`,
    };

    return this.stripeService.getStripeCardNumberElementTokenId(stripeCardNumberElement, additionalData);
  }
}
