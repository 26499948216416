import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'insig-booking-choose-doctor-login',
  templateUrl: './choose-doctor-login.component.html',
})
export class ChooseDoctorLoginComponent {
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);

  handleLoggedIn(): void {
    this.router.navigate(['..'], { relativeTo: this.route, queryParamsHandling: 'merge' });
  }
}
