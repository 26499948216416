<div class="d-flex flex-column gap-2">
  <insig-booking-search *ngIf="familyMemberProfiles.length >= NUMBER_OF_FAMILY_MEMBERS_TO_RENDER_SEARCH_BAR" (searchInputChanged)="searchInput = $event"></insig-booking-search>
  <insig-ui-button
    stylePreset="secondary"
    iconSrc="assets/img/ico-account.svg"
    (click)="handleAddNewPatientButtonClicked()"
  >
    Add New Patient
  </insig-ui-button>
</div>
<div class="bold overflow-auto py-2 max-height-30vh">
  <ng-container *ngFor="let familyMemberProfile of familyMemberProfiles">
    <ng-container *ngIf="isFamilyMemberWithinSearchCriteria(familyMemberProfile, searchInput)">
      <div class="d-flex py-2 justify-content-between gap-4">
        <label
          role="button"
          class="d-flex align-items-center p-2 rounded w-60"
          matTooltip="This family member has hit the maximum number of bookings for the chosen day"
          [matTooltipDisabled]="!tooManyAppointmentsError?.tooManyAppointmentsInOneDay || familyMemberProfile.familyMemberId !== tooManyAppointmentsError?.familyMemberId"
          [ngClass]="{
            'redBorder':tooManyAppointmentsError?.tooManyAppointmentsInOneDay && familyMemberProfile.familyMemberId === tooManyAppointmentsError?.familyMemberId,
            'greenBorder': familyMemberProfile.familyMemberId === selectedFamilyMemberId && (!tooManyAppointmentsError?.tooManyAppointmentsInOneDay || tooManyAppointmentsError?.familyMemberId !== familyMemberProfile.familyMemberId),
            'grayBorder': familyMemberProfile.familyMemberId !== selectedFamilyMemberId && (!tooManyAppointmentsError?.tooManyAppointmentsInOneDay || tooManyAppointmentsError?.familyMemberId !== familyMemberProfile.familyMemberId)
          }"
        >
          <img [src]="familyMemberProfile.familyMemberId === selectedFamilyMemberId ? 'assets/img/ico-checked-outlined.svg' : 'assets/img/ico-unchecked.svg'" class="px-2" alt="">
          <div class="text-break">{{ familyMemberProfile.fullName }}</div>
          <input
            hidden
            class="ms-auto"
            type="radio"
            name="familyMembers"
            (click)="handleFamilyMemberButtonClicked(familyMemberProfile)"
          >
        </label>

        <div class="d-flex align-items-center justify-content-end column-gap-2">
          <div role="button" class="d-flex align-items-center" (click)="handleEditButtonClicked(familyMemberProfile)">
            <img class="d-block" src="assets/img/ico-edit-pencil.svg" alt="Edit">
            <span class="d-none d-sm-block textBlue">Edit</span>
          </div>
          <div role="button" class="d-flex align-items-center" (click)="handleDeleteButtonClicked(accountHolderProfileUid, familyMemberProfile)">
            <img class="d-block" src="assets/img/ico-trash.svg" alt="Delete">
            <span class="d-none d-sm-block textPink">Delete</span>
          </div>
        </div>
      </div>
      <div
        *ngIf="selectedFamilyMemberId === familyMemberProfile.familyMemberId && isHealthCardInvalid"
        class="d-flex flex-column w-100 py-2"
      >
        <insig-booking-health-card-number-form
          [isAccountHolder]="false"
          [patientProfile]="familyMemberProfile"
          (healthCardSaved)="handleHealthCardSaved(familyMemberProfile)"
        ></insig-booking-health-card-number-form>
      </div>
    </ng-container>
  </ng-container>
</div>
