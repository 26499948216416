import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output, ViewChild, inject } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'insig-ui-radio-button',
  templateUrl: './insig-radio-button.component.html',
  styleUrls: ['./insig-radio-button.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: InsigRadioButtonComponent,
  }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsigRadioButtonComponent implements ControlValueAccessor, AfterViewChecked {
  private readonly changeDetector = inject(ChangeDetectorRef);
  public onChange?: (value: string) => void;
  public onTouched?: () => void;

  public _isChecked = false;
  public _isDisabled = false;

  private _siblingRadioButtons: HTMLInputElement[] = [];

  @ViewChild('radioInput') radioInput: ElementRef | undefined;

  @Input() set isChecked(isChecked: boolean | 'true' | 'false') {
    this._isChecked = coerceBooleanProperty(isChecked);
  }
  @Input() set isDisabled(isDisabled: boolean | 'true' | 'false') {
    this._isDisabled = coerceBooleanProperty(isDisabled);
  }

  @Input() radioGroup?: string;
  @Input() value?: string;
  @Input() imageUrl?: string;
  @Input() clearOnClick = false;

  @Output() clicked = new EventEmitter<Event>();

  @HostBinding('class.isChecked') get className(): boolean {
    this.changeDetector.detectChanges();
    return !!((this.radioInput?.nativeElement as HTMLInputElement)?.checked);
  }
  @HostBinding('role') role = 'button';
  @HostListener('click', ['$event']) handleHostClicked(event: Event): boolean {
    this.handleClickEvent(event);
    return false;
  }

  ngAfterViewChecked(): void {
    this._siblingRadioButtons = Array.from(document.querySelectorAll(`input[name=${this.radioGroup}]`));
  }

  writeValue(isChecked: boolean): void {
    this._isChecked = isChecked;
    this.changeDetector.detectChanges();
  }

  registerOnChange(onChange: (value: string) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this._isDisabled = isDisabled;
    this.changeDetector.detectChanges();
  }

  handleChange(event: Event): void {
    this._siblingRadioButtons.forEach((siblingRadioButton) => {
      siblingRadioButton.dispatchEvent(new Event('siblingChanged'));
    });

    this._isChecked = (event.target as HTMLInputElement).checked;
    this.changeDetector.detectChanges();
    this.onChange?.((event.target as HTMLInputElement).value);
    this.onTouched?.();
  }

  handleSiblingChanged(event: Event): void {
    this._isChecked = (event.target as HTMLInputElement).checked;
    this.changeDetector.detectChanges();
    this.onChange?.((event.target as HTMLInputElement).value);
    this.onTouched?.();
  }

  handleClickEvent(event: Event): void {
    if (!this._isDisabled) {
      if (this.clearOnClick && this.radioInput !== undefined) {
        const isAlreadySelected = this._isChecked;

        this._isChecked = !isAlreadySelected;
        this.changeDetector.detectChanges();
        this.radioInput.nativeElement.checked = !isAlreadySelected;
      }
      this.clicked.emit(event);
    }
  }

}
