import { Component } from '@angular/core';

@Component({
  selector: 'insig-booking-prescription-delivery-dialog',
  templateUrl: './prescription-delivery-dialog.component.html',
  styleUrls: ['./prescription-delivery-dialog.component.scss'],
})
export class PrescriptionDeliveryDialogComponent {

}
