import { Component, inject } from '@angular/core';
import { ClinicService, Clinic } from '../../services/clinic/clinic.service';

@Component({
  selector: 'insig-booking-clinic-selection',
  templateUrl: './clinic-selection.component.html',
  styleUrls: ['./clinic-selection.component.scss'],
})
export class ClinicSelectionComponent {
  private readonly clinicService = inject(ClinicService);
  public clinics: Clinic[];

  constructor() {
    this.clinics = this.clinicService.getMockClinics();
  }

}
