<ng-container *ngTemplateOutlet="mobileView"></ng-container>
<ng-container *ngTemplateOutlet="desktopView"></ng-container>

<ng-template #mobileView>
  <insig-ui-card class="d-block d-md-none mb-2">
    <div class="h-100 d-flex flex-column justify-content-between">
      <insig-booking-pharmacy-stand-alone-form></insig-booking-pharmacy-stand-alone-form>

      <div>
        <div class="d-flex align-items-center mt-0 tiaDelivery">
          <img src="./assets/images/tiahealth-logo.png" class="max-width-30" alt="Pharmacy Logo"/>
          <div class="font-size-lg bold textBlue ps-2">Prescription Delivery</div>
        </div>
        <div class="py-1"><!-- spacer --></div>
        <div>
          <span class="bold textSecondary">
            Free prescription delivery, anywhere in Canada. <b>As fast as same day.</b>
          </span>
          <div role="button" class="mt-2 textBlue" (click)="handleFulfilledByClicked()">
            <strong>Fulfilled by {{ prescriptionDeliveryPharmacyName }}.</strong> Learn more here
          </div>
        </div>
        <div class="py-1"><!-- spacer --></div>
        <div>
          <label
            role="button"
            class="d-flex align-items-center p-2 rounded w-60"
            [ngClass]="{
              'greenBorder': getFreePrescriptionDeliverySelected,
              'grayBorder': !getFreePrescriptionDeliverySelected
            }"
          >
            <img [src]="getFreePrescriptionDeliverySelected ? 'assets/img/ico-checked-outlined.svg' : 'assets/img/ico-unchecked.svg'" class="px-2" alt="" />
            <div class="bold textSecondary">Get Free Prescription Delivery</div>
            <input
              hidden
              class="ms-auto"
              type="radio"
              name="prescriptionDeliveryMobile"
              [checked]="getFreePrescriptionDeliverySelected"
              (click)="handleGetFreePrescriptionDeliveryClicked(getFreePrescriptionDeliverySelected)"
            >
          </label>
        </div>
      </div>
    </div>
  </insig-ui-card>
</ng-template>
<ng-template #desktopView>
  <insig-ui-card class="d-none d-md-flex flex-column maxWidth325Px">
    <div class="d-flex flex-column justify-content-between">
      <insig-booking-pharmacy-stand-alone-form></insig-booking-pharmacy-stand-alone-form>

      <div class="mt-4">
        <div class="d-flex align-items-center tiaDelivery">
          <img src="./assets/images/tiahealth-logo.png" class="max-width-30" alt="Pharmacy Logo"/>
          <div class="font-size-md bold textBlue ps-2">Prescription Delivery</div>
        </div>
        <div class="font-size-md textSecondary">Free prescription delivery, anywhere in Canada.  As fast as same day.</div>
        <div role="button" class="mt-2 textBlue" (click)="handleFulfilledByClicked()">
          <strong>Fulfilled by {{ prescriptionDeliveryPharmacyName }}.</strong> Learn more here
        </div>
        <div class="mt-2">
          <label
            role="button"
            class="d-flex align-items-center p-2 rounded w-60"
            [ngClass]="{
              'greenBorder': getFreePrescriptionDeliverySelected,
              'grayBorder': !getFreePrescriptionDeliverySelected
            }"
          >
            <img [src]="getFreePrescriptionDeliverySelected ? 'assets/img/ico-checked-outlined.svg' : 'assets/img/ico-unchecked.svg'" class="px-2" alt="" />
            <div class="bold textSecondary">Get Free Prescription Delivery</div>
            <input
              hidden
              class="ms-auto"
              type="radio"
              name="prescriptionDeliveryDesktop"
              [checked]="getFreePrescriptionDeliverySelected"
              (click)="handleGetFreePrescriptionDeliveryClicked(getFreePrescriptionDeliverySelected)"
            >
          </label>
        </div>
      </div>
    </div>
  </insig-ui-card>
</ng-template>
