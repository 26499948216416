<div class="headerGrid mt-3">
  <div class="me-3 me-md-0">
    <ul role="tablist">
      <li role="tab">Virtual Appointments</li>
      <li role="tab" class="active">In-clinic Appointments</li>
    </ul>
  </div>
</div>

<div class="w-100 d-flex align-items-center gap-3">
  <div>
    <h1>Clinics near you</h1>
    <p role="doc-subtitle" class="mb-3">Choose a Clinic and move ahead.</p>
  </div>
  <insig-booking-search class="flex-grow-1"></insig-booking-search>
</div>

<img class="w-100 mb-3" src="assets/img/bnr-map-placebig.png" alt="" />

<div class="clinicListGrid">
  <insig-ui-card *ngFor="let clinic of clinics">
    <div class="h2">{{ clinic.name }}</div>
    <div class="h3">{{ clinic.type }}</div>
    <div class="h3">{{ clinic.address }}</div>

    <div class="d-flex">
      <img class="pe-2" src="{{ clinic.imageUrls[0] }}" alt="" />
      <img class="pe-2" src="{{ clinic.imageUrls[1] }}" alt="" />
      <img class="pe-2" src="{{ clinic.imageUrls[2] }}" alt="" />
    </div>

    <div class="mt-3 d-flex align-items-center">
      <img src="assets/img/ico-location.svg" alt="" class="pe-3" />
      <span class="textSecondary bold">1030 Sheppard, Ave W Unit 5</span>
    </div>

    <div class="textPink bold my-2">Open between {{ clinic.openingTime }} - {{ clinic.closingTime }}</div>
    <insig-ui-button stylePreset="pink"><img class="pe-3" src="assets/img/ico-more-pink.svg" alt="" />More</insig-ui-button>
  </insig-ui-card>
</div>
