import { AppointmentMedium } from '../appointment-medium/appointment-medium.service';

export function parseServiceMedium(input: string): AppointmentMedium {
  const serviceMediumEntries = Object.entries(AppointmentMedium);
  const serviceMediumEntry = serviceMediumEntries.find(([_, mediumName]) => {
    return mediumName.toLowerCase() === input.toLowerCase();
  });
  if (serviceMediumEntry !== undefined) {
    return serviceMediumEntry[1];
  } else {
    throw new Error(`Error parsing service medium. Unrecognized enum value: ${input}`);
  }
}
