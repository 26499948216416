import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
@Component({
  selector: 'insig-ui-select',
  templateUrl: './insig-select.component.html',
  styleUrls: ['./insig-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => InsigSelectComponent),
  }],
})
export class InsigSelectComponent implements ControlValueAccessor {
  public onChange?: (value: string) => void;
  public onTouched?: () => void;

  public _isRequired = false;
  public _isDisabled = false;

  @Input() set isRequired(isRequired: boolean | 'true' | 'false') {
    this._isRequired = coerceBooleanProperty(isRequired);
  }
  @Input() set isDisabled(isDisabled: boolean | 'true' | 'false') {
    this._isDisabled = coerceBooleanProperty(isDisabled);
  }

  @Input() label = '';
  @Input() value = '';
  @Input() placeholder = '';

  writeValue(value: string): void {
    this.value = value;
    this.onChange?.(value);
    this.onTouched?.();
  }

  registerOnChange(onChange: (value: string) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this._isDisabled = isDisabled;
  }

  handleBlur(): void {
    this.onTouched?.();
  }

  handleSelectChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    this.writeValue(selectElement.value);
  }
}
