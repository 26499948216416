<div class="w-100 d-flex flex-column align-items-start gap-2">
  <div class="d-flex flex-column w-100">
    <label
      [for]="CARD_NUMBER_ELEMENT_ID"
    >
      Card number <span class="textPink">*</span>
    </label>
    <div
      [id]="CARD_NUMBER_ELEMENT_ID"
      class="border rounded p-2"
    ></div>
  </div>
  <div class="d-flex w-100">
    <div class="w-100 pe-2 d-flex flex-column">
      <label
        [for]="CARD_EXPIRY_ELEMENT_ID"
      >
        Expiration <span class="textPink">*</span>
      </label>
      <div
        [id]="CARD_EXPIRY_ELEMENT_ID"
        class="border rounded p-2"
      ></div>
    </div>
    <div class="w-100 pb-2 d-flex flex-column">
      <label
        [for]="CARD_CVC_ELEMENT_ID"
      >
        CVC <span class="textPink">*</span>
      </label>
      <div
        [id]="CARD_CVC_ELEMENT_ID"
        class="border rounded p-2"
      ></div>
    </div>
  </div>

  <insig-ui-button
    iconSrc="assets/img/icon_save_white.svg"
    stylePreset="primary"
    (click)="handleSaveButtonClicked(accountHolderProfile, stripeCardNumberElement)"
  >
    Save
  </insig-ui-button>
</div>
