import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DoctorMetadata } from '@insig-health/api/doctor-booking-flow-api-v1';

@Pipe({
  standalone: true,
  name: 'doctorLocationPipe',
})
@Injectable()
export class DoctorLocationPipe implements PipeTransform {
  transform(doctorMetadata: DoctorMetadata): string {
    if (doctorMetadata.city !== 'NOT_DEFINED' && doctorMetadata.city !== undefined && doctorMetadata.province !== undefined) {
      const city = doctorMetadata.city
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
      return `${city}, ${doctorMetadata.province}`;
    } else {
      return '';
    }
  }

}
