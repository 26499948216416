import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { FamilyMemberProfile, FamilyMemberService } from '@insig-health/services/family-member/family-member.service';

import { AddNewPatientFormDialogComponent } from '../add-new-patient-form-dialog/add-new-patient-form-dialog.component';
import { DeletePatientWarningDialogComponent } from '../delete-patient-warning-dialog/delete-patient-warning-dialog.component';

import { SuccessResponse } from '@insig-health/api/patient-management-api';
import { EditFamilyMemberFormDialogComponent } from '../edit-family-member-form-dialog/edit-family-member-form-dialog.component';
import { lastValueFrom, Observable, Subscription } from 'rxjs';
import { TooManyAppointmentsError } from 'apps/insig-booking/src/services/appointment-reservation/appointment-reservation.service';

@Component({
  selector: 'insig-booking-family-member-selection',
  templateUrl: './family-member-selection.component.html',
  styleUrls: ['./family-member-selection.component.scss'],
})
export class FamilyMemberSelectionComponent implements OnInit, OnDestroy {

  public readonly NUMBER_OF_FAMILY_MEMBERS_TO_RENDER_SEARCH_BAR = 4;

  public familyMemberProfiles: FamilyMemberProfile[] = [];
  public searchInput = '';

  @Input() accountHolderProfileUid: string | undefined;
  @Input() invalidHealthCardFamilyMemberId: string | undefined;
  @Input() selectedFamilyMemberId: string | undefined;
  @Input() updateFamilyMembers$: Observable<void> | undefined;
  @Input() isHealthCardInvalid = false;
  @Input() tooManyAppointmentsError: TooManyAppointmentsError | undefined;

  @Output() selectedFamilyMemberChange = new EventEmitter<FamilyMemberProfile | undefined>();

  public updateFamilyMembersSubcription: Subscription | undefined;

  constructor (
    private dialog: MatDialog,
    private familyMemberService: FamilyMemberService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.updateFamilyMembers();

    this.updateFamilyMembersSubcription = this.updateFamilyMembers$?.subscribe(async () => {
      await this.updateFamilyMembers();
    });
  }

  ngOnDestroy(): void {
    this.updateFamilyMembersSubcription?.unsubscribe();
  }

  async updateFamilyMembers(): Promise<void> {
    if (this.accountHolderProfileUid) {
      this.familyMemberProfiles = await this.getFamilyMembers(this.accountHolderProfileUid);
      if (this.selectedFamilyMemberId && this.familyMemberProfiles.length > 0 && this.isSelectedFamilyMemberInFamilyMemberProfiles(this.selectedFamilyMemberId, this.familyMemberProfiles)) {
        this.selectedFamilyMemberChange.emit(this.getFamilyMemberByUid(this.selectedFamilyMemberId, this.familyMemberProfiles));
      } else if (this.familyMemberProfiles[0] !== undefined) {
        this.selectedFamilyMemberChange.emit(this.familyMemberProfiles[0]);
      }
    }
  }

  async getFamilyMembers(patientId: string): Promise<FamilyMemberProfile[]> {
    return (await this.familyMemberService.getFamilyMemberProfiles(patientId)).sort(this.sortFamilyMembersByName);
  }

  sortFamilyMembersByName(familyMemberOne: FamilyMemberProfile, familyMemberTwo: FamilyMemberProfile): number {
    return familyMemberOne.fullName.localeCompare(familyMemberTwo.fullName, undefined, { sensitivity: 'base' });
  }

  isFamilyMemberWithinSearchCriteria(familyMemberProfile: FamilyMemberProfile, searchInput: string): boolean {
    if (searchInput === '') {
      return true;
    }
    return familyMemberProfile.fullName.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1;
  }

  handleFamilyMemberButtonClicked(familyMemberProfile: FamilyMemberProfile): void {
    this.selectedFamilyMemberChange.emit(familyMemberProfile);
  }

  async handleDeleteButtonClicked(accountHolderProfileUid: string | undefined, profileToDelete: FamilyMemberProfile): Promise<void> {
    if (accountHolderProfileUid === undefined) {
      throw new Error('Id of logged in user is undefined');
    }
    if (profileToDelete.familyMemberId === undefined) {
      throw new Error('Id of profile to be deleted is undefined');
    }

    const dialogRef = this.dialog.open(DeletePatientWarningDialogComponent, {
      ...DeletePatientWarningDialogComponent.DEFAULT_DIALOG_CONFIG,
      data: { fullName: profileToDelete.fullName },
    });

    const dialogResult$ = dialogRef.afterClosed();
    const canDelete = await lastValueFrom<boolean>(dialogResult$);
    if (canDelete) {
      await this.deleteFamilyMemberProfile(accountHolderProfileUid, profileToDelete.familyMemberId);
      this.familyMemberProfiles = await this.getFamilyMembers(accountHolderProfileUid);
    }
  }

  async deleteFamilyMemberProfile(patientId: string, familyMemberId: string): Promise<SuccessResponse> {
    return this.familyMemberService.deleteFamilyMember(patientId, familyMemberId);
  }

  async handleEditButtonClicked(familyMemberProfile: FamilyMemberProfile): Promise<void> {
    const patientUpdated = await this.openEditPatientDialog(familyMemberProfile);
    if (!patientUpdated || this.accountHolderProfileUid === undefined) {
      return;
    }
    this.familyMemberProfiles = await this.getFamilyMembers(this.accountHolderProfileUid);
    if (familyMemberProfile.familyMemberId === this.selectedFamilyMemberId) {
      this.selectedFamilyMemberChange.emit(this.getFamilyMemberByUid(familyMemberProfile.familyMemberId, this.familyMemberProfiles));
    }
  }

  getFamilyMemberByUid(familyMemberProfileUid: string, familyMemberProfiles: FamilyMemberProfile[]): FamilyMemberProfile | undefined {
    return familyMemberProfiles.find((familyMemberProfile) => familyMemberProfile.familyMemberId === familyMemberProfileUid);
  }

  isSelectedFamilyMemberInFamilyMemberProfiles(selectedFamilyMemberId: string, familyMemberProfiles: FamilyMemberProfile[]): boolean {
    return familyMemberProfiles.some((familyMemberProfile) => familyMemberProfile.familyMemberId === selectedFamilyMemberId);
  }

  openEditPatientDialog(familyMemberProfile: FamilyMemberProfile): Promise<boolean> {
    const dialogRef = this.dialog.open(EditFamilyMemberFormDialogComponent, {
      data: { familyMemberProfile },
      maxWidth: EditFamilyMemberFormDialogComponent.DIALOG_MAX_WIDTH,
      width: EditFamilyMemberFormDialogComponent.DIALOG_WIDTH,
    });

    const dialogResult$ = dialogRef.afterClosed();
    return lastValueFrom<boolean>(dialogResult$);
  }

  async handleHealthCardSaved(familyMemberProfile: FamilyMemberProfile): Promise<void> {
    if (this.accountHolderProfileUid === undefined) {
      return;
    }
    this.invalidHealthCardFamilyMemberId = undefined;
    this.familyMemberProfiles = await this.getFamilyMembers(this.accountHolderProfileUid);
    if (familyMemberProfile.familyMemberId === this.selectedFamilyMemberId) {
      this.selectedFamilyMemberChange.emit(this.getFamilyMemberByUid(familyMemberProfile.familyMemberId, this.familyMemberProfiles));
    }
  }

  async handleAddNewPatientButtonClicked(): Promise<void> {
    const patientAdded = await this.openAddNewPatientDialog();

    if (patientAdded && this.accountHolderProfileUid !== undefined) {
      this.familyMemberProfiles = await this.getFamilyMembers(this.accountHolderProfileUid);
    }
  }

  openAddNewPatientDialog(): Promise<boolean> {
    const dialogRef = this.dialog.open(AddNewPatientFormDialogComponent, {
      maxWidth: AddNewPatientFormDialogComponent.DIALOG_MAX_WIDTH,
      width: AddNewPatientFormDialogComponent.DIALOG_WIDTH,
    });
    const dialogResult$ = dialogRef.afterClosed();
    return lastValueFrom<boolean>(dialogResult$);
  }
}
