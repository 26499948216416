<div>
  <h1>Create your account</h1>
  <p role="doc-subtitle" class="mb-3">Enter your account details to continue</p>
  <insig-ui-card>
    <form class="d-flex flex-column" action="javascript:void(0);" [formGroup]="patientRegistrationForm">

      <div class="d-flex flex-row">
        <div class="d-flex flex-column w-50 pe-2 py-2">
          <insig-ui-text-input label="First Name" [formControlName]="FIRST_NAME_FORM_CONTROL_NAME" isRequired="true"></insig-ui-text-input>
          <insig-ui-form-error
            [formControlForErrorMessage]="patientRegistrationForm.controls[FIRST_NAME_FORM_CONTROL_NAME]"
            errorText="First name is required."
            errorType="required"
          ></insig-ui-form-error>
        </div>
        
        <div class="d-flex flex-column w-50 ps-2 py-2">
          <insig-ui-text-input label="Last Name" [formControlName]="LAST_NAME_FORM_CONTROL_NAME" isRequired="true"></insig-ui-text-input>
          <insig-ui-form-error
            [formControlForErrorMessage]="patientRegistrationForm.controls[LAST_NAME_FORM_CONTROL_NAME]"
            errorText="Last name is required."
            errorType="required"
          ></insig-ui-form-error>
        </div>
      </div>
      
      <div class="d-flex flex-column flex-md-row">
        <div class="d-flex flex-column w-100 w-md-50 pe-0 pe-md-2 py-2">
          <insig-ui-text-input label="Email" type="email" name="email" [formControlName]="EMAIL_FORM_CONTROL_NAME" isRequired="true"></insig-ui-text-input>
          <insig-ui-form-error
            [formControlForErrorMessage]="patientRegistrationForm.controls[EMAIL_FORM_CONTROL_NAME]"
            errorText="Email is required."
            errorType="required"
          ></insig-ui-form-error>
          <insig-ui-form-error
            [formControlForErrorMessage]="patientRegistrationForm.controls[EMAIL_FORM_CONTROL_NAME]"
            errorText="Email must be a valid email."
            errorType="emailNotValid"
          ></insig-ui-form-error>
        </div>
  
        <div class="d-flex flex-column w-100 w-md-50 ps-0 ps-md-2 py-2">
          <insig-ui-text-input insig-phone-mask label="Phone Number" type="tel" name="phone" [formControlName]="PHONE_NUMBER_FORM_CONTROL_NAME" isRequired="true"></insig-ui-text-input>
          <insig-ui-form-error
            [formControlForErrorMessage]="patientRegistrationForm.controls[PHONE_NUMBER_FORM_CONTROL_NAME]"
            errorText="Phone Number is required."
            errorType="required"
          ></insig-ui-form-error>
          <insig-ui-form-error
            [formControlForErrorMessage]="patientRegistrationForm.controls[PHONE_NUMBER_FORM_CONTROL_NAME]"
            errorText="Phone Number must be a valid phone number."
            errorType="phoneNumberNotValid"
          ></insig-ui-form-error>
        </div>
      </div>
      
      <div class="d-flex flex-row">
        <div class="d-flex flex-column w-50 pe-2 py-2">
          <insig-ui-text-input class="py-2" label="Password" [formControlName]="PASSWORD_FORM_CONTROL_NAME" isRequired="true" type="password" autocomplete="new-password"></insig-ui-text-input>
          <insig-ui-form-error
            [formControlForErrorMessage]="patientRegistrationForm.controls[PASSWORD_FORM_CONTROL_NAME]"
            errorText="Passwords must have a length of 8 or more characters, at least 1 upper case letter, lower case letter, number, and special character."
            errorType="passwordNotValid"
          ></insig-ui-form-error>
          <insig-ui-form-error
            [formControlForErrorMessage]="patientRegistrationForm.controls[PASSWORD_FORM_CONTROL_NAME]"
            errorText="Password is required."
            errorType="required"
          ></insig-ui-form-error>
        </div>

        <div class="d-flex flex-column w-50 ps-2 py-2">
          <insig-ui-text-input class="py-2" label="Confirm Password" [formControlName]="CONFIRM_PASSWORD_FORM_CONTROL_NAME" isRequired="true" type="password"></insig-ui-text-input>
          <insig-ui-form-error
            [formControlForErrorMessage]="patientRegistrationForm"
            errorText="Passwords must match."
            errorType="controlValuesNotMatching"
          ></insig-ui-form-error>
          <insig-ui-form-error
            [formControlForErrorMessage]="patientRegistrationForm.controls[CONFIRM_PASSWORD_FORM_CONTROL_NAME]"
            errorText="Confirm Password is required."
            errorType="required"
          ></insig-ui-form-error>
        </div>
      </div>
      
      <div class="d-flex align-items-center">
        <insig-ui-checkbox-button
          label="I agree to company's"
          isRequired="true"
          [formControlName]="ACCEPT_TERMS_FORM_CONTROL_NAME"
        >
          <a class="bold" href="javascript:void(0);" role="button" (click)="openTermsAndConditions()">terms and conditions</a>
        </insig-ui-checkbox-button>
      </div>

      <insig-ui-form-error
        [formControlForErrorMessage]="patientRegistrationForm.controls[ACCEPT_TERMS_FORM_CONTROL_NAME]"
        errorText="Must accept terms and conditions."
        errorType="required"
      ></insig-ui-form-error>

      <div class="d-flex">
        <insig-ui-checkbox-button
          label="I agree to receive promo emails from Tia Health. You can unsubscribe at any time."
          [formControlName]="PROMOTION_FORM_CONTROL_NAME"
        >
        </insig-ui-checkbox-button>
      </div>

      <div class="d-flex flex-column flex-sm-row py-2 justify-content-between align-items-center continue">
        <insig-ui-button
          class="d-none d-sm-block w-50 pe-2"
          type="button"
          (click)="handleLoginInsteadButtonClicked()"
          stylePreset="tertiary"
        >
          Log in instead?
        </insig-ui-button>
        <ng-container *ngIf="showSpinner; else registerButton">
          <mat-progress-spinner
            class="d-flex align-items-end"
            mode="indeterminate"
            [diameter]="25"
          ></mat-progress-spinner>
        </ng-container>
        <ng-template #registerButton>
          <insig-ui-button
            class="w-100 w-sm-50 ps-0 ps-md-2"
            type="button"
            (click)="handleRegisterButtonClick()"
            [disabled]="patientRegistrationForm.invalid"
            iconSrc="assets/img/ico-continue.svg"
          >
            Register
          </insig-ui-button>
        </ng-template>
        <a class="bold d-block d-sm-none py-4" href="javascript:void(0);" role="button" (click)="handleLoginInsteadButtonClicked()">Log in instead?</a>
      </div>
    </form>
  </insig-ui-card>
</div>
