import { Router } from '@angular/router';
import { Province } from '@insig-health/services/province/province.service';
import { RegionType } from '../billing-region/billing-region.service';
import { BillingType } from '../billing-type/billing-type.service';
import {
  BookingStep,
  JumpToChooseDoctorStepOptions,
  JumpToChooseTimeStepOptions,
  JumpToConfirmBookingStepOptions,
  JumpToStepOptions,
  JumpToSuccessStepOptions,
  JumpToYourDetailsStepOptions,
} from './booking-step.service';
import { PromiseWithExposedExecutors } from './promise-with-exposed-executors';

export interface JumpToStepCommand {
  bookingStep: BookingStep;
  options?: JumpToStepOptions | JumpToChooseDoctorStepOptions | JumpToChooseTimeStepOptions | JumpToYourDetailsStepOptions | JumpToConfirmBookingStepOptions | JumpToSuccessStepOptions;
}

interface DeferredNavigationPromise {
  command: JumpToStepCommand;
  onNavigationSuccess: () => void;
  onNavigationFailed: () => void;
  onError: (error: unknown) => void;
}

export class JumpToStepQueue {
  private _queue: DeferredNavigationPromise[] = [];

  constructor(private router: Router) {}

  public enqueue(command: JumpToStepCommand): Promise<boolean> {
    const deferredPromise = new PromiseWithExposedExecutors<boolean>();
    const navigationPromise = {
      command,
      onNavigationSuccess: (): void => { deferredPromise.resolve(true); },
      onNavigationFailed: (): void => { deferredPromise.resolve(false); },
      onError: (error: unknown): void => { deferredPromise.reject(error); },
    };
    this._queue.push(navigationPromise);

    if (this._queue.length === 1) {
      this.dequeueAndExecuteUntilSuccess();
    }

    return deferredPromise.toPromise();
  }

  private async dequeueAndExecuteUntilSuccess(): Promise<void> {
    const navigationPromise = this.peek();
    if (!navigationPromise) {
      return;
    }

    try {
      const isNavigationSuccessful = await this.navigateByCommand(navigationPromise.command);
      this.shift();
      if (isNavigationSuccessful) {
        navigationPromise.onNavigationSuccess();
        this._queue.forEach((pendingNavigationPromise) => {
          pendingNavigationPromise.onNavigationFailed();
        });
        this.clear();
      } else {
        navigationPromise.onNavigationFailed();
      }
    } catch (error) {
      this._queue = this._queue.filter((pendingNavigationPromise) => pendingNavigationPromise !== navigationPromise);
      console.error(error);
      navigationPromise.onError(error);
    }
    this.dequeueAndExecuteUntilSuccess();
  }

  private peek(): DeferredNavigationPromise | undefined {
    return this._queue[0];
  }

  private shift(): DeferredNavigationPromise | undefined {
    return this._queue.shift();
  }

  private clear(): void {
    this._queue = [];
  }

  private async navigateByCommand(command: JumpToStepCommand): Promise<boolean> {
    const { bookingStep, options } = command;

    switch (bookingStep) {
      case BookingStep.CHOOSE_DOCTOR: {
        const region = options?.pathParams?.region;
        if (region && typeof region !== 'string') {
          if (region?.type === RegionType.INTERNATIONAL) {
            return this.router.navigate(
              ['international', 'billing', 'private', 'chooseDoctor'],
              {
                ...options?.navigationExtras,
                queryParamsHandling: 'merge',
              });
          } else if (region?.type === RegionType.PROVINCE) {
            return this.router.navigate([
              'province',
              region.provinceAbbreviation,
              'billing',
              region.billingType,
              'chooseDoctor',
            ], {
              ...options?.navigationExtras,
              queryParamsHandling: 'merge',
            });
          }
        }

        return this.router.navigate([
          'province',
          Province.ON,
          'billing',
          BillingType.PRIVATE,
          'chooseDoctor',
        ], {
          ...options?.navigationExtras,
          queryParamsHandling: 'merge',
        });
      }

      case BookingStep.CHOOSE_TIME: {
        const region = options?.pathParams?.region;
        if (region && typeof region !== 'string') {
          if (region?.type === RegionType.INTERNATIONAL) {
            return this.router.navigate(
              [
                'international',
                'billing',
                'private',
                'doctor',
                options?.pathParams?.doctorId,
                'service',
                options?.pathParams?.serviceId,
                'chooseTime',
              ],
              {
                ...options?.navigationExtras,
                queryParams: {
                  redirectedFromLogout: options?.queryParams?.redirectedFromLogout ?? undefined,
                },
                queryParamsHandling: 'merge',
              });
          } else if (region?.type === RegionType.PROVINCE) {
            return this.router.navigate([
              'province',
              region.provinceAbbreviation,
              'billing',
              region.billingType,
              'doctor',
              options?.pathParams?.doctorId,
              'service',
              options?.pathParams?.serviceId,
              'chooseTime',
            ], {
              ...options?.navigationExtras,
              queryParams: {
                redirectedFromLogout: options?.queryParams?.redirectedFromLogout ?? undefined,
              },
              queryParamsHandling: 'merge',
            });
          }
        }

        return this.router.navigate([
          'province',
          options?.pathParams?.provinceAbbreviation,
          'billing',
          options?.pathParams?.billingType,
          'doctor',
          options?.pathParams?.doctorId,
          'service',
          options?.pathParams?.serviceId,
          'chooseTime',
        ], {
          ...options?.navigationExtras,
          queryParams: {
            redirectedFromLogout: options?.queryParams?.redirectedFromLogout ?? undefined,
          },
          queryParamsHandling: 'merge',
        });
      }

      case BookingStep.LOGIN: {
        return this.router.navigate([
          'draft',
          options?.pathParams?.draftAppointmentId,
          'yourDetails',
        ], {
          ...options?.navigationExtras,
          queryParamsHandling: 'merge',
        });
      }

      case BookingStep.CONFIRM_BOOKING: {
        return this.router.navigate([
          'draft',
          options?.pathParams?.draftAppointmentId,
          'confirmBooking',
        ], {
          ...options?.navigationExtras,
          queryParamsHandling: 'merge',
        });
      }

      case BookingStep.SUCCESS: {
        return this.router.navigate([
          'doctorId',
          options?.pathParams?.doctorId,
          'appointmentMedium',
          options?.pathParams?.appointmentMedium,
          'serviceId',
          options?.pathParams?.serviceId,
          'serviceType',
          options?.pathParams?.serviceType,
          'startTime',
          options?.pathParams?.startTime,
          'success',
        ], {
          ...options?.navigationExtras,
          queryParamsHandling: 'merge',
        });
      }

      default: {
        return false;
      }
    }
  }
}
