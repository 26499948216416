<div class="pb-3">
  <div class="px-3 pt-3 font-size-lg bold">Select language</div>
  <div role="button" class="px-3 py-2 text-decoration-underline font-size-xs bold textBlue" (click)="sidenav?.close()">Cancel</div>
  <ng-container *ngIf="translateService.getRawLanguageList() as languages">
    <ng-container *ngFor="let language of languages">
      <div role="button" class="p-3" (click)="handleLanguageClicked(language)">
        {{ language }}
      </div>
    </ng-container>
  </ng-container>
</div>
