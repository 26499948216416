<insig-ui-dialog>
  <div title mat-dialog-title class="d-flex align-items-start pb-0 mb-0">
    <insig-ui-profile-picture
      [imageUrl]="doctorProfile.profilePictureUrl"
      width="64px"
      height="64px"
    ></insig-ui-profile-picture>

    <div class="d-flex flex-column h-100 ps-2">
      <div class="bold notranslate">{{ doctorProfile | doctorTitleAndNamePipe }}</div>
      <div class="font-size-md">{{ doctorProfile.specialty }}</div>
      <div class="font-size-md">{{ doctorProfile | doctorLocationPipe }}</div>
      <div class="textSecondary font-size-sm"><em>{{ doctorProfile.languagesSpoken.join(', ') || 'EN' }}</em></div>
    </div>
  </div>

  <div content mat-dialog-content class="mt-1 mb-2">
    <h2 *ngIf="doctorProfile.credentials">Qualifications</h2>
    <div>{{ doctorProfile.credentials }}</div>

    <h2 *ngIf="doctorProfile.bookingInstructions" class="mt-2">Booking Instructions</h2>
    <div>{{ doctorProfile.bookingInstructions }}</div>
  </div>
</insig-ui-dialog>
