import { Component, inject } from '@angular/core';
import { MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface PillwayPharmacyChangeWarningDialogData {
  isPillwaySelected: boolean;
}

@Component({
  selector: 'insig-booking-pillway-pharmacy-change-warning-dialog',
  templateUrl: './pillway-pharmacy-change-warning-dialog.component.html',
})
export class PillwayPharmacyChangeWarningDialogComponent {
  static readonly DIALOG_MAX_WIDTH = '480px';
  static readonly DIALOG_WIDTH = '100vw';

  static readonly DEFAULT_DIALOG_ID = 'insig-booking-pillway-pharmacy-change-warning-dialog-id';
  static readonly DEFAULT_DIALOG_CONFIG: MatDialogConfig = {
    id: PillwayPharmacyChangeWarningDialogComponent.DEFAULT_DIALOG_ID,
    disableClose: true,
    width: PillwayPharmacyChangeWarningDialogComponent.DIALOG_WIDTH,
    maxWidth: PillwayPharmacyChangeWarningDialogComponent.DIALOG_MAX_WIDTH,
  };

  public readonly pillwayPharmacyChangeWarningDialogData = inject<PillwayPharmacyChangeWarningDialogData>(MAT_DIALOG_DATA);
}
