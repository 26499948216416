<h1 matDialogTitle class="pt-0">Saved Pharmacy Change</h1>

<p mat-dialog-content class="pt-2 pb-3">
  {{ pillwayPharmacyChangeWarningDialogData.isPillwaySelected
    ? 'This will overwrite your previously saved pharmacy information. Do you want to proceed?'
    : 'This will overwrite your previously saved pharmacy information and your prescriptions will be sent to you by Pillway. Do you want to proceed?'
  }}
</p>

<div mat-dialog-actions class="d-flex justify-content-between">
  <insig-ui-button [mat-dialog-close]="false" stylePreset="tertiary">
    No
  </insig-ui-button>
  <insig-ui-button [mat-dialog-close]="true" stylePreset="primary">
    Yes
  </insig-ui-button>
</div>
