<nav>
  <div class="mainNav d-flex align-items-center justify-content-between">

    <div role="button" (click)="handleLogoClicked()">
      <insig-ui-image-container
        height="35px"
        [imageUrl]="themeLogoUrl ?? companyBranding"
        fallbackImageUrl="/assets/images/home-icon.svg"
        alt="Logo"
      >
      </insig-ui-image-container>
    </div>

    <insig-booking-stepper class="flex-grow-1"></insig-booking-stepper>

    <div role="button" class="sidenavToggle" [ngClass]="{ 'open': isSidenavOpen }" (click)="handleToggleSidenavClicked()"></div>
  </div>
</nav>

<insig-booking-time-alert *ngIf="showTimer" class="w-100"
  [currentReservedAppointmentSlot]="currentReservedAppointmentSlot"
></insig-booking-time-alert>
