<ng-container [formGroup]="discountCodeForm">
  <div class="d-flex flex-column">
    <div>
      <span class="textSecondary">Discount Code</span>
    </div>
    <div class="d-flex">
      <div class="d-flex flex-column">
        <insig-ui-text-input
          label=""
          [smallMargins]="true"
          [formControlName]="DISCOUNT_CODE_FORM_CONTROL_NAME"
        ></insig-ui-text-input>
        <insig-ui-form-error
          [formControlForErrorMessage]="discountCodeForm.controls[DISCOUNT_CODE_FORM_CONTROL_NAME]"
          errorText="Looks like that code is invalid."
          errorType="discountCodeInvalid"
        ></insig-ui-form-error>
      </div>

      <insig-ui-button
        class="px-2 mt-1"
        stylePreset="tertiary"
        [disabled]="discountCodeForm.invalid"
        (click)="handleApplyDiscountButtonClicked(discountCodeForm.controls[DISCOUNT_CODE_FORM_CONTROL_NAME].value ?? '')"
      >
        Apply Discount
      </insig-ui-button>
    </div>
  </div>
</ng-container>
