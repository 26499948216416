import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Pharmacy } from '../login/patient-sign-up/patient-sign-up-details.component';

@Component({
  selector: 'insig-booking-pharmacy-map',
  templateUrl: './pharmacy-map.component.html',
  styleUrls: ['./pharmacy-map.component.scss'],
})
export class PharmacyMapComponent implements OnChanges {
  public static readonly MARKER_ZOOM_LEVEL = 13;

  @Input() pharmacies: Pharmacy[] = [];
  @Input() selectedPharmacy: Pharmacy | undefined;

  @Output() pharmacyMarkerClicked = new EventEmitter();

  private readonly DEFAULT_ZOOM_LEVEL = 13;
  private readonly DOWNTOWN_COORDINATES: google.maps.LatLngLiteral = {
    lat: 43.655700,
    lng: -79.380650,
  };

  public mapCenter: google.maps.LatLngLiteral = this.DOWNTOWN_COORDINATES;
  public mapOptions: google.maps.MapOptions = {
    fullscreenControl: false,
    mapTypeControl: false,
    streetViewControl: false,
  };
  public mapZoom: number = this.DEFAULT_ZOOM_LEVEL;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pharmacies) {
      this.selectedPharmacy = undefined;
    }
    if (changes.selectedPharmacy) {
      this.handleSelectedPharmacyChanged(changes.selectedPharmacy.currentValue);
    }
  }

  handleSelectedPharmacyChanged(selectedPharmacy: Pharmacy | undefined): void {
    if (selectedPharmacy === undefined) {
      return;
    }
    const position = this.getPositionOfPharmacy(selectedPharmacy);
    this.setMapCenter(position);
    this.setZoomLevel(PharmacyMapComponent.MARKER_ZOOM_LEVEL);
  }

  getPositionOfPharmacy(pharmacy: Pharmacy): google.maps.LatLngLiteral {
    return {
      lat: pharmacy.lat,
      lng: pharmacy.lng,
    };
  }

  setMapCenter(position: google.maps.LatLngLiteral): void {
    this.mapCenter = position;
  }

  setZoomLevel(zoomLevel: number): void {
    this.mapZoom = zoomLevel;
  }

  handleMarkerClicked(pharmacy: Pharmacy): void {
    const position = this.getPositionOfPharmacy(pharmacy);
    this.setMapCenter(position);
    this.setZoomLevel(PharmacyMapComponent.MARKER_ZOOM_LEVEL);
    this.pharmacyMarkerClicked.emit(pharmacy);
  }
}
