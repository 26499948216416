import { NativeDateAdapter } from '@angular/material/core';

/*
  The current usage for this adapter is for birthdays, which do not carry timezone information as it is fundamentally different from a Date.
  Dates represent an instance in time chronologically, but a birthday remains constant across all timezones.
  Displaying the data back to the user in the source timezone (UTC) essentially removes timezone data from the date information, which is desirable for the birthday use case.
  However, this adapter would not be appropriate for cases where the date ought to be displayed in local time (e.g. appointment start times).

  Passed in dates are formatted and returned as if they are in UTC since that way the device will not change the returned date based on the device's timezone.
*/
export class UtcDateAdapter extends NativeDateAdapter {
  format(date: Date): string {
    let day = `${this.getDate(date)}`;
    if (parseInt(day) < 10) {
      day = `0${day}`;
    }
    let month = `${this.getMonth(date) + 1}`;
    if (parseInt(month) < 10) {
      month = `0${month}`;
    }
    const year = this.getYear(date);

    return `${year}-${month}-${day}`;
  }

  getYear(date: Date): number {
    return date.getUTCFullYear();
  }

  getMonth(date: Date): number {
    return date.getUTCMonth();
  }

  getDate(date: Date): number {
    return date.getUTCDate();
  }
}
