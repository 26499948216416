<button class="text-start h-100 w-100 doctorCard" [ngClass]="{ 'selected': selected }" (click)="handleDoctorCardClicked()">
  <insig-ui-card class="d-flex flex-column h-100">
      <div class="d-flex flex-column flex-md-row gap-0 gap-md-2">
        <insig-ui-profile-picture
          class="rounded"
          [imageUrl]="doctor.doctorMetadata.profilePictureUrl"
          width="64px"
          height="64px"
        ></insig-ui-profile-picture>
  
        <div class="d-flex d-md-none gap-2 my-2">
          <!-- offers video icon -->
          <img [ngClass]="{ 'd-none': !doesDoctorOfferServicesInThisMedium(doctor, AppointmentServiceMedium.Video) }"
            src="assets/img/ico-consult-vi.svg" alt="" />
          <!-- offers phone icon -->
          <img [ngClass]="{ 'd-none': !doesDoctorOfferServicesInThisMedium(doctor, AppointmentServiceMedium.Phone) }"
            src="assets/img/ico-consult-ph.svg" alt="" />
        </div>
  
        <div class="d-flex flex-column h-100">
          <div class="bold font-size-md notranslate lineClampedText" [style.-webkit-line-clamp]="2">{{ doctor.doctorMetadata | doctorTitleAndNamePipe }}</div>
          <div class="font-size-md">{{ doctor.doctorMetadata.specialty }}</div>
          <div class="font-size-md">{{ doctor.doctorMetadata | doctorLocationPipe }}</div>
          <div class="d-none d-md-block textSecondary notranslate font-size-md"><em>{{ doctor.doctorMetadata.languagesSpoken.join(', ') || 'EN' }}</em></div>
        </div>
      </div>
      <div class="my-2">
        <div class="text-truncate">{{ doctor.doctorMetadata.credentials }}</div>
        <div *ngIf="doctor.service.bookingRestrictions" class="textOrange">{{ doctor.service.bookingRestrictions.message }}</div>
        <button *ngIf="doctor.doctorMetadata.bookingInstructions || doctor.doctorMetadata.credentials"
          class="bold font-size-xs textGray readMoreButton d-inline"
          (click)="handleReadMoreClicked($event)"
        >
          Read more
        </button>
      </div>
  
      <div class="my-auto"><!-- spacer --></div>
  
      <div class="doctorOfferedMediums mt-1 mb-3 p-2 d-none d-md-flex align-items-md-center justify-content-between textSecondary">
        Consults On
        <span>
          <!-- offers video icon -->
          <img class="px-2" [ngClass]="{ 'unavailable': !doesDoctorOfferServicesInThisMedium(doctor, AppointmentServiceMedium.Video) }"
            src="assets/img/ico-consult-vi.svg" alt="" />
          <!-- offers phone icon -->
          <img class="px-2" [ngClass]="{ 'unavailable': !doesDoctorOfferServicesInThisMedium(doctor, AppointmentServiceMedium.Phone) }"
            src="assets/img/ico-consult-ph.svg" alt="" />
        </span>
      </div>
  
      <div class="d-flex">
        <insig-booking-availability-label [date]="nextDateDoctorIsAvailable"></insig-booking-availability-label>
      </div>
  </insig-ui-card>
</button>
