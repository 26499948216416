import { Injectable } from '@angular/core';
import { ExtendedSlotData } from '@insig-health/services/doctor/doctor.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SelectedTimeSlotService {

  private selectedTimeSlot$ = new BehaviorSubject<ExtendedSlotData | undefined>(undefined);

  setSelectedTimeSlot(timeSlot: ExtendedSlotData): void {
    this.selectedTimeSlot$.next(timeSlot);
  }

  getSelectedTimeSlot(): ExtendedSlotData | undefined {
    return this.selectedTimeSlot$.getValue();
  }

  getSelectedTimeSlotObservable(): Observable<ExtendedSlotData | undefined> {
    return this.selectedTimeSlot$.asObservable();
  }

  clearSelectedTimeSlot(): void {
    this.selectedTimeSlot$.next(undefined);
  }
}
