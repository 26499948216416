<h1 mat-dialog-title>Edit Patient Information</h1>

<form mat-dialog-content class="d-flex flex-column overflow-auto" [formGroup]="editPatientForm">
  <insig-booking-patient-profile-form
    #patientProfileForm
    [parentForm]="editPatientForm"
    [patientProfile]="familyMemberProfile"
  ></insig-booking-patient-profile-form>
</form>

<div mat-dialog-actions class="d-flex justify-content-between">
  <insig-ui-button
    [mat-dialog-close]="false"
    stylePreset="tertiary"
  >
    Back
  </insig-ui-button>
  <insig-ui-button
    [disabled]="editPatientForm.invalid"
    iconSrc="assets/img/ico-continue.svg"
    (click)="handleSaveButtonClicked(editPatientForm)"
  >
    Save
  </insig-ui-button>
</div>
