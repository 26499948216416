import { Component, inject } from '@angular/core';
import { MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  templateUrl: './reauthenticate-dialog.component.html',
})
export class ReauthenticateDialogComponent {
  static readonly DEFAULT_DIALOG_ID = 'reauthenticate-dialog-id';
  static readonly DEFAULT_DIALOG_CONFIG: MatDialogConfig = {
    id: ReauthenticateDialogComponent.DEFAULT_DIALOG_ID,
    disableClose: true,
  };
  static readonly DEFAULT_TITLE = 'Login Expiry';
  static readonly DEFAULT_MESSAGE = 'Your login has expired. Please sign in again to continue.';

  private readonly data = inject<{ title?: string, message?: string } | undefined | null>(MAT_DIALOG_DATA);

  public title: string;
  public message: string;

  constructor(
  ) {
    this.title = this.data?.title ?? ReauthenticateDialogComponent.DEFAULT_TITLE;
    this.message = this.data?.message ?? ReauthenticateDialogComponent.DEFAULT_MESSAGE;
  }
}
