import { Injectable } from '@angular/core';
import { FIREBASE_ANALYTICS_APP_NAME } from '@insig-health/config/firebaseConfig';
import { getApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

export enum AnalyticsEvent {
  BOOKING_SUCCESS = 'booking_success',
  BOOKING_FAILURE = 'booking_failure',
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  logEvent(eventName: string): void {
    const analyticsApp = getApp(FIREBASE_ANALYTICS_APP_NAME);
    const analytics = getAnalytics(analyticsApp);
    logEvent(analytics, eventName);
  }
}
