import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/database';

export const INSIG_PRODUCTION_CONFIG_NAME = 'insigProduction';
export const INSIG_DEVELOPMENT_CONFIG_NAME = 'insigDevelopment';
export const FIREBASE_ANALYTICS_APP_NAME = 'analytics';

const INSIG_PRODUCTION_CONFIG = {
  apiKey: 'AIzaSyB9e1iPCy9av-XjRK2Kp9RPjgMWJZTMqBY',
  authDomain: 'insig-ca.firebaseapp.com',
  databaseURL: 'https://insig-ca.firebaseio.com',
  projectId: 'insig-ca',
  storageBucket: 'insig-ca.appspot.com',
  messagingSenderId: '47931142088',
  appId: '1:47931142088:web:1a2314589268f1a0fcdafd',
};

const INSIG_DEVELOPMENT_CONFIG = {
  apiKey: 'AIzaSyDktbJkIAiTPe2astOKM2CgcYNtGXXdKts',
  authDomain: 'insig-dev.firebaseapp.com',
  databaseURL: 'https://insig-dev.firebaseio.com',
  projectId: 'insig-dev',
  storageBucket: 'insig-dev.appspot.com',
  messagingSenderId: '278880975480',
  appId: '1:278880975480:web:f79e5794f3fee1c1e50b8d',
};

const INSIG_BOOKING_DEVELOPMENT_CONFIG = {
  apiKey: 'AIzaSyBWKz1Op6mw2uEXv7Cxg3frRW_0virEh58',
  authDomain: 'insig-booking-dev.firebaseapp.com',
  projectId: 'insig-booking-dev',
  storageBucket: 'insig-booking-dev.appspot.com',
  messagingSenderId: '994896156153',
  appId: '1:994896156153:web:ce52cbe47a9625409e9928',
  measurementId: 'G-9JWM38D0KE',
};

const INSIG_BOOKING_PRODUCTION_CONFIG = {
  apiKey: 'AIzaSyAHMp8Qsqvfdza7j4eSbrrvYVyMrKRRe5U',
  authDomain: 'insig-booking.firebaseapp.com',
  projectId: 'insig-booking',
  storageBucket: 'insig-booking.appspot.com',
  messagingSenderId: '654690572656',
  appId: '1:654690572656:web:0280cd06fcc08e2be7d7b5',
  measurementId: 'G-N5E6WW2B12',
};

export function initializeBookingFirebaseApp(environment: { isProduction: boolean }): firebase.app.App {
  if (environment.isProduction) {
    return firebase.initializeApp(INSIG_PRODUCTION_CONFIG);
  } else {
    return firebase.initializeApp(INSIG_DEVELOPMENT_CONFIG);
  }
}

export function initializeBookingFirebaseAnalyticsApp(environment: { isProduction: boolean }): firebase.app.App {
  if (environment.isProduction) {
    return firebase.initializeApp(INSIG_BOOKING_PRODUCTION_CONFIG, 'analytics');
  } else {
    return firebase.initializeApp(INSIG_BOOKING_DEVELOPMENT_CONFIG, 'analytics');
  }
}

export function initializeFirebaseApp(environment: { isProduction: boolean }): firebase.app.App {
  if (environment.isProduction) {
    const firebaseApp = firebase.apps.find((app) => app.name === INSIG_PRODUCTION_CONFIG_NAME);
    if (firebaseApp !== undefined) {
      return firebaseApp;
    }
    return firebase.initializeApp(INSIG_PRODUCTION_CONFIG, INSIG_PRODUCTION_CONFIG_NAME);
  } else {
    const firebaseApp = firebase.apps.find((app) => app.name === INSIG_DEVELOPMENT_CONFIG_NAME);
    if (firebaseApp !== undefined) {
      return firebaseApp;
    }
    return firebase.initializeApp(INSIG_DEVELOPMENT_CONFIG, INSIG_DEVELOPMENT_CONFIG_NAME);
  }
}
