<div class="pb-2 d-flex flex-column w-100 align-items-end">
  <div class="w-100 d-flex justify-content-between justify-content-lg-end gap-3">
    <button mat-button class="text-center background-tia-border border-radius-4px"
      (click)="handlePreviousWeekButtonClick()"
      [disabled]="isPreviousWeekButtonDisabled(startDate, earliestAvailableTime)">
      <img class="height-12px"
        [src]="isPreviousWeekButtonDisabled(startDate, earliestAvailableTime) ? 'assets/img/ico-cal-prev-disabled.svg' : 'assets/img/ico-cal-prev.svg'"
        alt="Previous week">
    </button>
    <div
      *ngIf="!loading"
      class="text-center flex-grow-1 flex-lg-grow-0 textBlue font-size-sm bold p-2 background-tia-border border-radius-4px"
    >
      {{ _weekIndicatorText }}
    </div>
    <button mat-button class="text-center background-tia-border border-radius-4px"
      (click)="handleNextWeekButtonClick()">
      <img class="height-12px" src="assets/img/ico-cal-next.svg" alt="Next week">
    </button>
  </div>
</div>
