<div class="pb-2">
  <div class="font-size-md bold">Confirm Your Pharmacy Information</div>
  <ng-container *ngIf="pharmacyStandAloneFormState === PharmacyStandAloneFormState.SHOW_PHARMACY_INFO">
    <ng-container *ngTemplateOutlet="showPharmacyInfo"></ng-container>
  </ng-container>
  <ng-container *ngIf="pharmacyStandAloneFormState === PharmacyStandAloneFormState.EDIT_PHARMACY_INFO">
    <ng-container *ngTemplateOutlet="editPharmacyInfo"></ng-container>
  </ng-container>
</div>


<ng-template #showPharmacyInfo>
  <div class="d-flex justify-content-between gap">
    <div>
      <div>
        <span class="bold">Name: </span><span class="textSecondary">{{ pharmacyStandAloneForm.controls[PHARMACY_NAME_FORM_CONTROL_NAME].value }}</span>
      </div>
      <div>
        <span class="bold">Fax Number: </span><span class="textSecondary text-nowrap">{{ pharmacyStandAloneForm.controls[PHARMACY_FAX_NUMBER_FORM_CONTROL_NAME].value }}</span>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <div role="button" class="d-flex align-items-center gap-1" (click)="handleEditButtonClicked()">
        <img class="d-inline-block" src="assets/img/ico-edit-pencil.svg" alt="Edit">
        <span class="bold textBlue">Edit</span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #editPharmacyInfo>
  <ng-container [formGroup]="pharmacyStandAloneForm">
    <div class="d-flex flex-md-column gap-1 column-gap-1">
      <insig-ui-text-input
        label="Name"
        [formControlName]="PHARMACY_NAME_FORM_CONTROL_NAME"
        [smallMargins]="true"
      ></insig-ui-text-input>
  
      <div>
        <insig-ui-text-input
          label="Fax Number"
          insig-phone-mask
          [formControlName]="PHARMACY_FAX_NUMBER_FORM_CONTROL_NAME"
          [smallMargins]="true"
        ></insig-ui-text-input>
        <insig-ui-form-error
          [formControlForErrorMessage]="pharmacyStandAloneForm.controls[PHARMACY_FAX_NUMBER_FORM_CONTROL_NAME]"
          errorText="Pharmacy fax number must be valid"
          errorType="phoneNumberNotValid"
        ></insig-ui-form-error>
      </div>
    </div>
    <div class="mt-2">
      <insig-ui-button
        class="w-100"
        (click)="updatePharmacy(pharmacyStandAloneForm)"
      >
        Update Pharmacy
      </insig-ui-button>
    </div>
  </ng-container>  
</ng-template>
