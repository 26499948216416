import { Injectable, inject } from '@angular/core';
import { UserManagementService, CustomerResponse, CustomerResponseSourcesInner, CreateCustomerRequest } from '@insig-health/api/user-management-api';
import { lastValueFrom } from 'rxjs';

export interface StripeCustomer {
  id: string,
  sources: Array<StripeSource>,
}

export interface StripeSource {
  id: string,
  lastFourDigits: string,
}

@Injectable({
  providedIn: 'root',
})
export class StripeCustomerService {
  private readonly userManagementService = inject(UserManagementService);

  async createPatientCustomer(patientId: string, createCustomerRequest: CreateCustomerRequest): Promise<StripeCustomer> {
    const customerResponse = await lastValueFrom(this.userManagementService.createCustomer(patientId, createCustomerRequest));
    return this.getStripeCustomerFromCustomerResponse(customerResponse);
  }

  getCustomerResponse(patientId: string): Promise<CustomerResponse> {
    return lastValueFrom(this.userManagementService.oneCustomer(patientId));
  }

  async getStripeCustomer(patientId: string): Promise<StripeCustomer> {
    const customerResponse = await this.getCustomerResponse(patientId);
    return this.getStripeCustomerFromCustomerResponse(customerResponse);
  }

  getStripeCustomerFromCustomerResponse(customerResponse: CustomerResponse): StripeCustomer {
    return {
      id: customerResponse.id,
      sources: customerResponse.sources.map((customerResponseSourcesInner) => {
        return this.getStripeSourceFromCustomerResponseSourcesInner(customerResponseSourcesInner);
      }),
    };
  }

  getStripeSourceFromCustomerResponseSourcesInner(customerResponseSourcesInner: CustomerResponseSourcesInner): StripeSource {
    return {
      id: customerResponseSourcesInner.id,
      lastFourDigits: customerResponseSourcesInner.last4,
    };
  }
}
