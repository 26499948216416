/**
 * Insig Booking Flow APIs
 * The specification contains all booking flow APIs
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: support@insighealth.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Where the draft appointment was created on the booking site
 */
export type DraftOrigin = 'patient-selected-time' | 'quickbook' | 'other';

export const DraftOrigin = {
    PATIENT_SELECTED_TIME: 'patient-selected-time' as DraftOrigin,
    QUICKBOOK: 'quickbook' as DraftOrigin,
    OTHER: 'other' as DraftOrigin
};

