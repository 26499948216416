import { FormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FormValidatorsService } from '@insig-health/services/form-validators/form-validators.service';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Address, Coordinates, GooglePlaceService, TORONTO_COORDINATES } from '@insig-health/services/google-place/google-place.service';
import { HealthCardService } from 'apps/insig-booking/src/services/health-card/health-card.service';
import { PatientRegistrationService } from '@insig-health/services/patient-registration/patient-registration.service';
import { ReferralNetworkService } from '@insig-health/services/referrals/referral-network.service';

import { ReauthenticateDialogComponent } from '../../../dialogs/reauthenticate/reauthenticate-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import type { Nullable } from '@insig-health/insig-types/type-utils';
import { PatientRegistrationInfo } from '@insig-health/api/patient-registration-api';
import { SNACK_BAR_AUTO_DISMISS_MILLISECONDS } from '@insig-health/config/angular-material.config';
import { Subscription, firstValueFrom, startWith } from 'rxjs';
import { InsigTextInputComponent } from '@insig-health/components/lib/insig-text-input/insig-text-input.component';
import { AppointmentReservationService } from 'apps/insig-booking/src/services/appointment-reservation/appointment-reservation.service';
import { DAYS_PER_YEAR, MILLISECONDS_PER_DAY } from '@insig-health/services/date-and-time/date-and-time.constants';
import { Gender } from '@insig-health/services/patient-profile/patient-profile.service';
import { CpsEsDoctor } from '@insig-health/services/cps-es/cps-es.service';
import { PILLWAY_EAST_PHARMACY_FAX, PILLWAY_PHARMACY_NAME, PILLWAY_WEST_PHARMACY_FAX } from '../../prescription-delivery/prescription-delivery.component';
import { Province } from '@insig-health/services/province/province.service';
import { PrescriptionDeliveryDialogComponent } from 'apps/insig-booking/src/dialogs/prescription-delivery/prescription-delivery-dialog.component';

export interface Pharmacy {
  address: string,
  banner?: string,
  dist?: number,
  fax: string,
  id: string,
  lat: number,
  lng: number,
  locationCategory: string,
  locationID: string,
  logo?: string,
  name: string,
  type: string,
}

export interface SignUpFormValues {
  address: string;
  province: string;
  country: string;
  city: string;
  dateOfBirth: string;
  healthCardNumber: string;
  postalCode: string;
}

export interface PharmacyDetailsFormValues {
  pharmacyFax: string;
  pharmacyName: string;
}

export enum PrescriptionDeliveryMethod {
  PILLWAY = 'Pillway',
  PHARMACY = 'Pharmacy',
}

@Component({
  selector: 'insig-booking-patient-sign-up-details',
  styleUrls: ['./patient-sign-up-details.component.scss'],
  templateUrl: './patient-sign-up-details.component.html',
})
export class PatientSignUpDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly appointmentReservationService = inject(AppointmentReservationService);
  private readonly formBuilder = inject(FormBuilder);
  private readonly formValidatorsService = inject(FormValidatorsService);
  private readonly googlePlaceService = inject(GooglePlaceService);
  private readonly changeDetector = inject(ChangeDetectorRef);
  private readonly healthCardService = inject(HealthCardService);
  private readonly patientRegistrationService = inject(PatientRegistrationService);
  private readonly referralNetworkService = inject(ReferralNetworkService);
  private readonly snackBar = inject(MatSnackBar);
  private readonly dialog = inject(MatDialog);
  @Output() finishedSignUp = new EventEmitter<void>();

  private readonly METRES_PER_KILOMETRE = 1000;
  private readonly NEARBY_PHARMACY_DISTANCE_LIMIT = this.METRES_PER_KILOMETRE * 6;
  private readonly MAX_NUMBER_OF_PHARMACIES = 15;
  private readonly RADIUS_OF_EARTH_IN_METRES = 6371e3;
  private readonly GENERIC_ERROR_MESSAGE = 'Something went wrong';
  private readonly NON_DIGIT_REGEX = /\D+/g;
  private readonly MINIMUM_REGISTRATION_AGE = 2;

  public readonly SIGN_UP_DETAILS_FORM_NAME = 'signUpDetails';

  public readonly ADDRESS_FORM_CONTROL_NAME = 'address';
  public readonly PROVINCE_FORM_CONTROL_NAME = 'province';
  public readonly COUNTRY_FORM_CONTROL_NAME = 'country';
  public readonly CITY_FORM_CONTROL_NAME = 'city';
  public readonly POSTAL_CODE_FORM_CONTROL_NAME = 'postalCode';
  public readonly DATE_OF_BIRTH_FORM_CONTROL_NAME = 'dateOfBirth';
  public readonly HEALTH_CARD_NUMBER_FORM_CONTROL_NAME = 'healthCardNumber';
  public readonly GENDER_FORM_CONTROL_NAME = 'gender';
  public readonly FAMILY_DOCTOR_FULL_NAME_FORM_CONTROL_NAME = 'familyDoctorFullName';
  public readonly FAMILY_DOCTOR_FAX_NUMBER_FORM_CONTROL_NAME = 'familyDoctorFaxNumber';
  public readonly PRESCRIPTION_DELIVERY_METHOD_FORM_CONTROL_NAME = 'prescriptionDeliveryMethod';

  public readonly maximumBirthdate = new Date(new Date().getTime() - this.MINIMUM_REGISTRATION_AGE * DAYS_PER_YEAR * MILLISECONDS_PER_DAY);

  public readonly signUpDetailsForm = this.formBuilder.group({
    [this.ADDRESS_FORM_CONTROL_NAME]: ['', [Validators.required]],
    [this.PROVINCE_FORM_CONTROL_NAME]: ['', [Validators.required]],
    [this.COUNTRY_FORM_CONTROL_NAME]: ['Canada', [Validators.required]],
    [this.CITY_FORM_CONTROL_NAME]: ['', [Validators.required]],
    [this.POSTAL_CODE_FORM_CONTROL_NAME]: ['', [Validators.required, this.formValidatorsService.isPostalCodeValid]],
    [this.DATE_OF_BIRTH_FORM_CONTROL_NAME]: ['', {
      updateOn: 'blur',
      validators: [
        Validators.required,
        this.formValidatorsService.isDateValid,
        this.formValidatorsService.isDateInCorrectFormat,
        this.formValidatorsService.isDateBeforeDate(this.maximumBirthdate),
      ],
    }],
    [this.HEALTH_CARD_NUMBER_FORM_CONTROL_NAME]: [''],
    [this.GENDER_FORM_CONTROL_NAME]: ['', [Validators.required]],
    [this.FAMILY_DOCTOR_FULL_NAME_FORM_CONTROL_NAME]: [''],
    [this.FAMILY_DOCTOR_FAX_NUMBER_FORM_CONTROL_NAME]: ['', [this.formValidatorsService.isPhoneNumberValidValidator(true)]],
    [this.PRESCRIPTION_DELIVERY_METHOD_FORM_CONTROL_NAME]: [PrescriptionDeliveryMethod.PILLWAY, [Validators.required]],
  });

  public readonly PHARMACY_DETAILS_FORM_NAME = 'pharmacyDetails';

  public readonly PHARMACY_NAME_FORM_CONTROL_NAME = 'pharmacyName';
  public readonly PHARMACY_FAX_FORM_CONTROL_NAME = 'pharmacyFax';

  public readonly PrescriptionDeliveryMethod = PrescriptionDeliveryMethod;

  public readonly pharmacyDetailsForm = this.formBuilder.group({
    [this.PHARMACY_NAME_FORM_CONTROL_NAME]: ['', [Validators.required]],
    [this.PHARMACY_FAX_FORM_CONTROL_NAME]: ['', [Validators.required, this.formValidatorsService.isPhoneNumberValidValidator(false)]],
  });

  public readonly patientRegistrationForm = this.formBuilder.group({
    [this.SIGN_UP_DETAILS_FORM_NAME]: this.signUpDetailsForm,
    [this.PHARMACY_DETAILS_FORM_NAME]: this.pharmacyDetailsForm,
  });

  public readonly healthCardProvinces = this.healthCardService.getHealthCardProvinces();

  public pharmacies: Pharmacy[] = [];
  public selectedPharmacy: Pharmacy | undefined;
  public showSpinner = false;
  public genders = Object.values(Gender);
  public isPillwaySelected = false;

  public pharmacyDetailsFormSubscription: Subscription | undefined;
  public prescriptionDeliveryMethodSubscription: Subscription | undefined;
  public selectedAddressSubscription: Subscription | undefined;
  public nameQuery$ = this.signUpDetailsForm.get(this.FAMILY_DOCTOR_FULL_NAME_FORM_CONTROL_NAME)?.valueChanges;
  public provinceQuery$ = this.signUpDetailsForm.get(this.PROVINCE_FORM_CONTROL_NAME)?.valueChanges;

  @ViewChild('addressInput')
  public addressInput: InsigTextInputComponent | undefined;

  ngOnInit(): void {
    this.pharmacyDetailsFormSubscription = this.getPharmacyDetailsFormSubscription(this.pharmacyDetailsForm);
    this.prescriptionDeliveryMethodSubscription = this.getPrescriptionDeliveryMethodSubscription(this.signUpDetailsForm.controls[this.PRESCRIPTION_DELIVERY_METHOD_FORM_CONTROL_NAME], this.pharmacyDetailsForm);
  }

  handleFamilyDoctorClicked(familyDoctor: CpsEsDoctor): void {
    this.signUpDetailsForm.get(this.FAMILY_DOCTOR_FAX_NUMBER_FORM_CONTROL_NAME)?.setValue(familyDoctor.faxNumber);
  }

  ngAfterViewInit(): void {
    const inputElement = this.addressInput?.inputElement?.nativeElement;
    if (inputElement) {
      this.selectedAddressSubscription = this.googlePlaceService.getPlaceChangedObservable(inputElement).subscribe(async (address) => {
        this.autoFillAddress(this.signUpDetailsForm, address);
        const foundPharmacies = await this.loadReferralOptions(address.longitude ?? TORONTO_COORDINATES.longitude, 'pharmacy');
        this.pharmacies = this.getPharmaciesNearCoordinates({
          latitude: address.latitude ?? TORONTO_COORDINATES.latitude,
          longitude: address.longitude ?? TORONTO_COORDINATES.longitude,
        }, foundPharmacies, this.NEARBY_PHARMACY_DISTANCE_LIMIT, this.MAX_NUMBER_OF_PHARMACIES);

        const nearestPharmacy = this.getNearestPharmacy(this.pharmacies);
        if (nearestPharmacy) {
          this.setSelectedPharmacy(nearestPharmacy);
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.selectedAddressSubscription?.unsubscribe();
    this.pharmacyDetailsFormSubscription?.unsubscribe();
    this.prescriptionDeliveryMethodSubscription?.unsubscribe();
  }

  autoFillAddress(signUpDetailsForm: UntypedFormGroup, address: Address): void {
    const controls = signUpDetailsForm.controls;
    controls[this.ADDRESS_FORM_CONTROL_NAME].setValue(address.streetAddress);
    controls[this.ADDRESS_FORM_CONTROL_NAME].markAsDirty();

    controls[this.CITY_FORM_CONTROL_NAME].setValue(address.city);
    controls[this.CITY_FORM_CONTROL_NAME].markAsDirty();

    controls[this.PROVINCE_FORM_CONTROL_NAME].setValue(address.province);
    controls[this.PROVINCE_FORM_CONTROL_NAME].markAsDirty();

    controls[this.COUNTRY_FORM_CONTROL_NAME].setValue(address.country);
    controls[this.COUNTRY_FORM_CONTROL_NAME].markAsDirty();

    controls[this.POSTAL_CODE_FORM_CONTROL_NAME].setValue(address.postalCode);
    controls[this.POSTAL_CODE_FORM_CONTROL_NAME].markAsDirty();
  }

  async handleRegisterButtonClick(): Promise<void> {
    const patientRegistrationInfo = this.getPatientRegistrationInfoFromPatientRegistrationForm(this.patientRegistrationForm);

    this.showSpinner = true;
    try {
      await this.patientRegistrationService.updatePatientInformation(patientRegistrationInfo);

      const currentReservedAppointmentSlot = await firstValueFrom(this.appointmentReservationService.getCurrentReservedAppointmentSlot());
      if (currentReservedAppointmentSlot && currentReservedAppointmentSlot.canDraftAppointmentExpiryBeExtended) {
        await this.appointmentReservationService.extendCurrentReservedAppointmentSlot();
      }

      this.finishedSignUp.emit();
    } catch (error: unknown | HttpErrorResponse) {
      if (this.isHttpErrorResponse(error)) {
        if(error.status === 401) {
          const reauthenticateDialog =
            this.dialog.getDialogById(ReauthenticateDialogComponent.DEFAULT_DIALOG_ID) ??
            this.dialog.open(ReauthenticateDialogComponent, ReauthenticateDialogComponent.DEFAULT_DIALOG_CONFIG);

          await firstValueFrom(reauthenticateDialog.afterClosed());

          await this.patientRegistrationService.updatePatientInformation(patientRegistrationInfo);

          const currentReservedAppointmentSlot = await firstValueFrom(this.appointmentReservationService.getCurrentReservedAppointmentSlot());
          if (currentReservedAppointmentSlot && currentReservedAppointmentSlot.canDraftAppointmentExpiryBeExtended) {
            await this.appointmentReservationService.extendCurrentReservedAppointmentSlot();
          }

          this.finishedSignUp.emit();
          return;
        }

        this.snackBar.open(error.error.errorMessage, undefined, {
          duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS,
        });
      } else {
        this.snackBar.open(this.GENERIC_ERROR_MESSAGE, undefined, {
          duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS,
        });
      }

      this.showSpinner = false;
      console.error(error);
      throw error;
    }
  }

  getPatientRegistrationInfoFromPatientRegistrationForm(patientRegistrationForm: UntypedFormGroup): PatientRegistrationInfo {
    return {
      ...this.getPatientRegistrationInfoFromSignUpDetails(patientRegistrationForm.controls[this.SIGN_UP_DETAILS_FORM_NAME] as UntypedFormGroup),
      ...this.getPatientRegistrationInfoFromPharmacyDetails(patientRegistrationForm.controls[this.PHARMACY_DETAILS_FORM_NAME] as UntypedFormGroup),
    };
  }

  getPatientRegistrationInfoFromSignUpDetails(signUpDetailsForm: UntypedFormGroup): Omit<
    PatientRegistrationInfo,
    'pharmacyName' | 'pharmacyFax'
  > {
    return {
      birthdate: signUpDetailsForm.controls[this.DATE_OF_BIRTH_FORM_CONTROL_NAME].value,
      gender: signUpDetailsForm.controls[this.GENDER_FORM_CONTROL_NAME].value,
      address: signUpDetailsForm.controls[this.ADDRESS_FORM_CONTROL_NAME].value,
      city: signUpDetailsForm.controls[this.CITY_FORM_CONTROL_NAME].value,
      province: signUpDetailsForm.controls[this.PROVINCE_FORM_CONTROL_NAME].value,
      country: signUpDetailsForm.controls[this.COUNTRY_FORM_CONTROL_NAME].value,
      postalCode: signUpDetailsForm.controls[this.POSTAL_CODE_FORM_CONTROL_NAME].value,
      healthcardNumber: signUpDetailsForm.controls[this.HEALTH_CARD_NUMBER_FORM_CONTROL_NAME].value,
      familyDoctorFullName: signUpDetailsForm.controls[this.FAMILY_DOCTOR_FULL_NAME_FORM_CONTROL_NAME].value,
      familyDoctorFaxNumber: signUpDetailsForm.controls[this.FAMILY_DOCTOR_FAX_NUMBER_FORM_CONTROL_NAME].value,
    };
  }

  getPatientRegistrationInfoFromPharmacyDetails(pharmacyDetailsForm: UntypedFormGroup): Pick<
    PatientRegistrationInfo,
    'pharmacyName' | 'pharmacyFax'
  > {
    const prescriptionDeliveryMethod = this.signUpDetailsForm.controls[this.PRESCRIPTION_DELIVERY_METHOD_FORM_CONTROL_NAME]?.value;
    const province = this.signUpDetailsForm.controls[this.PROVINCE_FORM_CONTROL_NAME]?.value;
    if (prescriptionDeliveryMethod === PrescriptionDeliveryMethod.PILLWAY) {
      return {
        pharmacyName: PILLWAY_PHARMACY_NAME,
        pharmacyFax: province === Province.BC || province === Province.AB
          ? PILLWAY_WEST_PHARMACY_FAX
          : PILLWAY_EAST_PHARMACY_FAX,
      };
    }
    return {
      pharmacyName: pharmacyDetailsForm.controls[this.PHARMACY_NAME_FORM_CONTROL_NAME].value,
      pharmacyFax: pharmacyDetailsForm.controls[this.PHARMACY_FAX_FORM_CONTROL_NAME].value,
    };
  }

  isHttpErrorResponse(error: unknown): error is HttpErrorResponse {
    if (typeof error !== 'object') {
      return false;
    } else {
      return error instanceof HttpErrorResponse;
    }
  }

  areAddressFormFieldsFilledOut(formValue: Nullable<SignUpFormValues>): boolean {
    return (
      formValue.address !== '' &&
      formValue.province !== '' &&
      formValue.city !== '' &&
      formValue.country !== '' &&
      formValue.postalCode !== ''
    );
  }

  async loadReferralOptions(longitudeToGetPharmaciesNear: number, typeOfObjectToRetrieve: string): Promise<Pharmacy[]> {
    return firstValueFrom(this.referralNetworkService.loadReferralNetworkByTypeDistance(
      typeOfObjectToRetrieve,
      longitudeToGetPharmaciesNear,
    ));
  }

  getPharmaciesNearCoordinates(coordinates: Coordinates, pharmacies: Pharmacy[], searchRadius: number, limit: number): Pharmacy[] {
    const pharmaciesWithDistancesFromUserAddress = this.addDistancesFromUserAddressToPharmaciesArray(coordinates, pharmacies);

    const phamaciesWithinRangeOfUserAddress = this.getPharmaciesWithinDistance(pharmaciesWithDistancesFromUserAddress, searchRadius);

    const pharmaciesSortedByDistanceAndWeightedByPayingForPriority = this.sortPharmaciesByDistance(phamaciesWithinRangeOfUserAddress);

    return this.getMaxNumberOfPharmaciesInPharmacyArray(pharmaciesSortedByDistanceAndWeightedByPayingForPriority, limit);
  }

  addDistancesFromUserAddressToPharmaciesArray(coordinates: Coordinates, pharmacies: Pharmacy[]): Pharmacy[] {
    return pharmacies.map((pharmacy) => {
      const pharmacyLatLng = {
        latitude: pharmacy.lat,
        longitude: pharmacy.lng,
      };
      return {
        ...pharmacy,
        dist: this.getStraightLineDistanceBetweenTwoPoints(coordinates, pharmacyLatLng),
      } as Pharmacy;
    });
  }

  getStraightLineDistanceBetweenTwoPoints(pointOne: Coordinates, pointTwo: Coordinates): number {
    const pointOneLatitudeInRadians = pointOne.latitude * Math.PI/180;
    const pointOneLongitudeInRadians = pointOne.longitude * Math.PI/180;
    const pointTwoLatitudeInRadians = pointTwo.latitude * Math.PI/180;
    const pointTwoLongitudeInRadians = pointTwo.longitude * Math.PI/180;

    const xValue = (pointTwoLongitudeInRadians - pointOneLongitudeInRadians) * Math.cos((pointOneLatitudeInRadians + pointTwoLatitudeInRadians)/2);
    const yValue = pointTwoLatitudeInRadians - pointOneLatitudeInRadians;

    return Math.sqrt((xValue*xValue) + (yValue*yValue)) * this.RADIUS_OF_EARTH_IN_METRES;
  }

  sortPharmaciesByDistance(pharmacies: Pharmacy[]): Pharmacy[] {
    return pharmacies.sort((pharmacyOne, pharmacyTwo) => {
      return this.getDistanceWeightingForPharmacy(pharmacyOne) - this.getDistanceWeightingForPharmacy(pharmacyTwo);
    });
  }

  getDistanceWeightingForPharmacy(pharmacy: Pharmacy): number {
    if (!pharmacy.dist) {
      pharmacy.dist = this.NEARBY_PHARMACY_DISTANCE_LIMIT + 1;
    }

    // favor locations with a logo (paying)
    if (pharmacy.logo) {
      return pharmacy.dist - this.METRES_PER_KILOMETRE;
    } else {
      return pharmacy.dist;
    }
  }

  getPharmaciesWithinDistance(pharmacies: Pharmacy[], metres: number): Pharmacy[] {
    return pharmacies.filter((pharmacy) => {
      return !(!pharmacy.dist || (pharmacy.dist && pharmacy.dist > metres));
    });
  }

  getMaxNumberOfPharmaciesInPharmacyArray(pharmacies: Pharmacy[], maxNumberOfPharmacies: number): Pharmacy[] {
    return pharmacies.slice(0, maxNumberOfPharmacies);
  }

  getNearestPharmacy(sortedNearbyPharmacies: Pharmacy[]): Pharmacy | undefined {
    if (sortedNearbyPharmacies.length < 1) {
      return undefined;
    }
    return sortedNearbyPharmacies[0];
  }

  getAddressFromSignUpForm(formValues: SignUpFormValues): string {
    return `${formValues.address}, ${formValues.city}, ${formValues.province}, ${formValues.country}`;
  }

  handlePharmacyMarkerClicked(pharmacy: Pharmacy): void {
    this.setSelectedPharmacy(pharmacy);
  }

  handlePharmacyRadioClicked(pharmacy: Pharmacy): void {
    this.setSelectedPharmacy(pharmacy);
  }

  setSelectedPharmacy(pharmacy: Pharmacy): void {
    const pharmacyNameValue = pharmacy.name;
    const pharmacyFaxValue = this.formatPhoneNumberForDisplay(pharmacy.fax);
    this.pharmacyDetailsForm.controls[this.PHARMACY_NAME_FORM_CONTROL_NAME].setValue(pharmacyNameValue);
    this.pharmacyDetailsForm.controls[this.PHARMACY_FAX_FORM_CONTROL_NAME].setValue(pharmacyFaxValue);

    this.isPillwaySelected = false;
    this.selectedPharmacy = pharmacy;

    this.changeDetector.detectChanges();
  }

  formatPhoneNumberForDisplay(phoneNumber: string | undefined): string {
    if (!phoneNumber) {
      return '';
    }

    const areaCode = phoneNumber.slice(0, 3);
    const telephonePrefix = phoneNumber.slice(3, 6);
    const lineNumber = phoneNumber.slice(6, 10);

    return `(${areaCode}) ${telephonePrefix}-${lineNumber}`;
  }

  getPrescriptionDeliveryMethodSubscription(prescriptionDeliveryMethodFormControl: FormControl, pharmacyDetailsForm: UntypedFormGroup): Subscription {
    return prescriptionDeliveryMethodFormControl.valueChanges.pipe(
      startWith(PrescriptionDeliveryMethod.PILLWAY),
    ).subscribe((prescriptionDeliveryMethod) => {
      if (prescriptionDeliveryMethod === PrescriptionDeliveryMethod.PILLWAY) {
        pharmacyDetailsForm.disable();
      } else {
        pharmacyDetailsForm.enable();
      }
    });
  }

  getPharmacyDetailsFormSubscription(pharmacyDetailsForm: UntypedFormGroup): Subscription {
    return pharmacyDetailsForm.valueChanges.subscribe(async (formValue) => {
      this.handlePharmacyFormChange(formValue);
    });
  }

  handlePharmacyFormChange(pharmacyDetailsFormValues: PharmacyDetailsFormValues): void {
    const pharmacyWithDetails = this.getPharmacyByDetails(pharmacyDetailsFormValues, this.pharmacies);
    this.selectedPharmacy = pharmacyWithDetails;
  }

  getPharmacyByDetails(pharmacyDetailsFormValues: PharmacyDetailsFormValues, pharmacies: Pharmacy[]): Pharmacy | undefined {
    return pharmacies.find((pharmacy) =>
      pharmacy.name === pharmacyDetailsFormValues.pharmacyName &&
      pharmacy.fax === this.removeNonDigitCharactersFromPhoneNumber(pharmacyDetailsFormValues.pharmacyFax),
    );
  }

  removeNonDigitCharactersFromPhoneNumber(phoneNumber: string): string {
    return phoneNumber.replace(this.NON_DIGIT_REGEX, '');
  }

  handleFulfilledByClicked(): void {
    this.dialog.open(PrescriptionDeliveryDialogComponent);
  }
}
