<div class="px-2 px-md-5 h-100 backgroundImage">
  <div *ngIf="state === LoginState.LOGIN_WIDGET">
    <h1>Login to your account</h1>
    <p role="doc-subtitle" class="mb-3">Enter your account details to continue</p>
    <insig-health-gcp-ip-login-widget class="d-block mw-400px"
      [disableClinicianAuthentication]="true"
      [isLogoVisible]="isLogoVisible"
      (onLoggedIn)="handleLoggedIn()"
      (onPatientSignUpButtonClicked)="state = LoginState.ACCOUNT_CREATION"
    >
    </insig-health-gcp-ip-login-widget>
  </div>
  
  <div *ngIf="state === LoginState.ACCOUNT_CREATION">
    <div class="w-100 w-md-50">
      <insig-booking-patient-sign-up
        (loginButtonClicked)="handleBackToLoginButtonClicked()"
        (registerButtonClicked)="handleRegistrationRegisterButtonClicked($event)"
      ></insig-booking-patient-sign-up>
    </div>
  </div>
  <div *ngIf="state === LoginState.ADD_PATIENT_INFO_TO_ACCOUNT">
    <insig-booking-patient-sign-up-details
      (finishedSignUp)="handleLoggedIn()"
    ></insig-booking-patient-sign-up-details>
  </div>
</div>
