import { Pipe, PipeTransform, inject } from '@angular/core';
import { DateAndTimeService } from '@insig-health/services/date-and-time/date-and-time.service';

@Pipe({
  standalone: true,
  name: 'dateToTimeString',
})
export class DateToTimeStringPipe implements PipeTransform {
  private readonly dateAndTimeService = inject(DateAndTimeService);

  transform(date: Date): string {
    const localTimeZone = this.dateAndTimeService.getLocalTimeZone();
    const timeString = this.dateAndTimeService.getHourMinuteAndPeriod(date, localTimeZone);
    return timeString;
  }
}
