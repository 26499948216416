<div class="backgroundImage successGrid h-100">
  <insig-ui-card class="d-flex flex-column">
    <div class="d-flex justify-content-center">
      <img src="assets/img/ill-success.svg" alt="">
    </div>

    <ng-container *ngIf="!loading; else loadingScreen">
      <ng-container *ngTemplateOutlet="bookingSuccessScreen"></ng-container>
    </ng-container>
  </insig-ui-card>
</div>

<ng-template #bookingSuccessScreen>
  <h1>Congratulations!</h1>
  <p class="textSecondary">Your Appointment has been successfully booked
    with <span class="textGreen notranslate">{{doctorData?.doctorMetadata?.fullName ?? 'undefined'}}</span><span class="textGreen"> on {{getFullStartDate(startTime)}}.</span>
  </p>

  <div class="py-2"><!-- spacer --></div>

  <insig-ui-table>
    <table class="border-spacing-vertical-20px" aria-label="Appointment details">
      <tr>
        <th scope="row">Date:</th>
        <td>{{ getFormattedStartDate(startTime) }}</td>
      </tr>
      <tr>
        <th scope="row">Time:</th>
        <td>{{ getFormattedStartTime(startTime) }}</td>
      </tr>
      <tr>
        <th scope="row">Appointment Type:</th>
        <td>{{ serviceType }}</td>
      </tr>
      <tr>
        <th scope="row">Appointment medium:</th>
        <td>{{ getFormattedAppointmentMedium(appointmentMedium) }}</td>
      </tr>
    </table>
  </insig-ui-table>
</ng-template>

<ng-template #loadingScreen>
  <div class="flex-grow-1 d-flex justify-content-center align-items-center">
    <mat-progress-spinner
      class="m-auto"
      mode="indeterminate"
    ></mat-progress-spinner>
  </div>
</ng-template>
