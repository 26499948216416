<div class="h-100 d-flex flex-column">
  <div>
    <insig-booking-header (toggleSidenavClicked)="sidenav.toggle()" [isSidenavOpen]="sidenav.opened"></insig-booking-header>
  </div>

  <mat-sidenav-container class="flex-grow-1">
    <mat-sidenav #sidenav mode="over" position="end" autoFocus="false">
      <insig-booking-sidenav [sidenav]="sidenav"></insig-booking-sidenav>
    </mat-sidenav>
    <mat-sidenav-content class="lightBackground">
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
