import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AppointmentMedium } from 'apps/insig-booking/src/services/appointment-medium/appointment-medium.service';

@Component({
  selector: 'insig-booking-appointment-medium-select',
  templateUrl: './appointment-medium-select.component.html',
})
export class AppointmentMediumSelectComponent implements OnChanges {
  private static RADIO_GROUP_NAME_PREFIX = 'appointment-medium-select-';
  private static componentCount = 0;

  public AppointmentMedium = AppointmentMedium;
  public readonly radioGroupName = AppointmentMediumSelectComponent.getRadioGroupName(AppointmentMediumSelectComponent.RADIO_GROUP_NAME_PREFIX);

  @Input() availableMediums: AppointmentMedium[] | undefined;
  @Input() displayText = true;
  @Input() selectedMedium: AppointmentMedium | undefined;
  @Output() selectedMediumChange = new EventEmitter<AppointmentMedium>();

  public static getRadioGroupName(radioGroupNamePrefix: string): string {
    this.componentCount++;
    return `${radioGroupNamePrefix}${this.componentCount}`;
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    const availableMediums = simpleChanges.availableMediums?.currentValue as AppointmentMedium[];

    if (availableMediums !== undefined && this.selectedMedium === undefined) {
      const defaultMedium = this.getDefaultMedium(availableMediums);
      if (defaultMedium) {
        this.setSelectedMedium(defaultMedium);
      }
    }
  }

  handleMediumChange(medium: AppointmentMedium): void {
    this.setSelectedMedium(medium);
  }

  getDefaultMedium(offeredMediums: AppointmentMedium[]): AppointmentMedium | undefined {
    if (offeredMediums.includes(AppointmentMedium.PHONE)) {
      return AppointmentMedium.PHONE;
    } else if (offeredMediums.includes(AppointmentMedium.VIDEO)) {
      return AppointmentMedium.VIDEO;
    } else if (offeredMediums.includes(AppointmentMedium.MESSAGING)) {
      return AppointmentMedium.MESSAGING;
    }
  }

  setSelectedMedium(medium: AppointmentMedium): void {
    this.selectedMedium = medium;
    this.selectedMediumChange.emit(medium);
  }
}
