<ng-container [formGroup]="pharmacyInfoForm">
  <div class="bold">Preferred Pharmacy</div>
  <div>Any prescriptions for your visit may be faxed to your preferred pharmacy.</div>
  <div class="d-flex flex-column flex-md-row">
    <!-- #region pharmacy fax number -->
    <div class="d-flex flex-column w-100 w-md-50 py-2 pe-md-2">
      <insig-ui-text-input
        class="py-2"
        label="Pharmacy Name"
        [formControlName]="PHARMACY_NAME_FORM_CONTROL_NAME"
        isRequired="true"
      ></insig-ui-text-input>
      <insig-ui-form-error
        [formControlForErrorMessage]="pharmacyInfoForm.controls[PHARMACY_NAME_FORM_CONTROL_NAME]"
        errorText="Pharmacy name is required."
        errorType="required"
      ></insig-ui-form-error>
    </div>
    <!-- #endregion -->
  
    <!-- #region pharmacy fax number -->
    <div class="d-flex flex-column w-100 w-md-50 py-2 ps-md-2">
      <insig-ui-text-input
        class="py-2"
        label="Pharmacy Fax Number"
        insig-phone-mask
        [formControlName]="PHARMACY_FAX_NUMBER_FORM_CONTROL_NAME"
        isRequired="true"
      ></insig-ui-text-input>
      <insig-ui-form-error
        [formControlForErrorMessage]="pharmacyInfoForm.controls[PHARMACY_FAX_NUMBER_FORM_CONTROL_NAME]"
        errorText="Pharmacy fax number is required."
        errorType="required"
      ></insig-ui-form-error>
      <insig-ui-form-error
        [formControlForErrorMessage]="pharmacyInfoForm.controls[PHARMACY_FAX_NUMBER_FORM_CONTROL_NAME]"
        errorText="Pharmacy fax number must be valid"
        errorType="phoneNumberNotValid"
      ></insig-ui-form-error>
    </div>
    <!-- #endregion -->
  </div>
</ng-container>
