<ng-container 
  *ngFor="let hour of hours"
>
  <insig-booking-hourly-slot
    [hidden]="availableTimeSlotsByHour[hour].length === 0"
    [timeSlotData]="availableTimeSlotsByHour[hour]"
    [label]="getHourLabel(hour)"
    [currentWeekStartDate]="currentWeekStartDate"
  >
  </insig-booking-hourly-slot>
</ng-container>
