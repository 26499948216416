<mat-sidenav-container class="h-100 bg-white">
  <mat-sidenav #nestedSidenav class="w-100" mode="over" position="end" autoFocus="false">
    <insig-booking-language-sidenav *ngIf="currentNestedSidenav === NestedSidenav.LANGUAGE" [sidenav]="nestedSidenav"></insig-booking-language-sidenav>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="h-100 d-flex flex-column sidenavContent">
      <div *ngIf="isLoggedIn$ | async"class="d-flex p-3 flex-column align-items-start gap-2 border-bottom">
        <span class="h3 bold">My Account</span>
        <span class="d-inline-block text-truncate mw-100">{{ (patientProfile$ | async)?.email }}</span>
        <button class="text-deocration-underline textBlue text-small boldOnHover" (click)="handleMyAccountClicked()">Manage account &nearr;</button>
      </div>
      
      <button *ngIf="isRegionSelectorVisible" class="p-3 d-flex justify-content-between border-bottom darkenBackgroundOnInteraction" (click)="handleLocationClicked()">
        <span class="textBlue bold">Location</span>
        <div class="d-flex gap-3">
          <span class="notranslate text-dark">{{ currentRegion$ | async }}</span>
        </div>
      </button>

      <button class="p-3 d-flex justify-content-between border-bottom darkenBackgroundOnInteraction" (click)="handleLanguageClicked()">
        <span class="textBlue bold">Language</span>
        <div class="d-flex gap-3">
          <span class="text-dark">{{ currentLanguage$ | async }}</span>
        </div>
      </button>

      <button class="p-3 d-flex justify-content-between border-bottom darkenBackgroundOnInteraction" (click)="handleHelpClicked()">
        <span class="textBlue bold">Contact Support &nearr;</span>
      </button>

      <button *ngIf="(isLoggedIn$ | async) === false && isLoginButtonAvailable()"
        class="p-3 d-flex darkenBackgroundOnInteraction"
        href="javascript:void(0);"
        (click)="handleLoginClicked()"
      >
        <span class="textBlue bold">Login</span>
      </button>
        
    
      <button *ngIf="isLoggedIn$ | async"
        insigBookingLogoutButton
        class="p-3 d-flex darkenBackgroundOnInteraction"
        href="javascript:void(0);"
      >
        <span class="textBlue bold">Logout</span>
      </button>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
