/**
 * Insig Booking Flow APIs
 * The specification contains all booking flow APIs
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: support@insighealth.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ErrorCode = 'draft-already-exists' | 'availability-block-does-not-exist' | 'other-record-does-not-exist' | 'discount-code-already-used' | 'discount-code-get-record' | 'discount-code-does-not-exist' | 'forbidden' | 'bad-request' | 'internal-server-error' | 'draft-appointment-not-found' | 'patient-profile-incomplete' | 'max-public-bookings-reached' | 'clinic-constraint-failed' | 'invalid-medium' | 'medium-not-found' | 'record-not-found' | 'book-appointment-timeout' | 'book-appointment-error' | 'payment-charge-failed' | 'invalid-healthcard' | 'invalid-service' | 'doctor-not-found' | 'draft-invalid-for-doctor' | 'patient-not-found' | 'required-field-missing' | 'invalid-field-entry' | 'other-error';

export const ErrorCode = {
    DraftAlreadyExists: 'draft-already-exists' as ErrorCode,
    AvailabilityBlockDoesNotExist: 'availability-block-does-not-exist' as ErrorCode,
    OtherRecordDoesNotExist: 'other-record-does-not-exist' as ErrorCode,
    DiscountCodeAlreadyUsed: 'discount-code-already-used' as ErrorCode,
    DiscountCodeGetRecord: 'discount-code-get-record' as ErrorCode,
    DiscountCodeDoesNotExist: 'discount-code-does-not-exist' as ErrorCode,
    Forbidden: 'forbidden' as ErrorCode,
    BadRequest: 'bad-request' as ErrorCode,
    InternalServerError: 'internal-server-error' as ErrorCode,
    DraftAppointmentNotFound: 'draft-appointment-not-found' as ErrorCode,
    PatientProfileIncomplete: 'patient-profile-incomplete' as ErrorCode,
    MaxPublicBookingsReached: 'max-public-bookings-reached' as ErrorCode,
    ClinicConstraintFailed: 'clinic-constraint-failed' as ErrorCode,
    InvalidMedium: 'invalid-medium' as ErrorCode,
    MediumNotFound: 'medium-not-found' as ErrorCode,
    RecordNotFound: 'record-not-found' as ErrorCode,
    BookAppointmentTimeout: 'book-appointment-timeout' as ErrorCode,
    BookAppointmentError: 'book-appointment-error' as ErrorCode,
    PaymentChargeFailed: 'payment-charge-failed' as ErrorCode,
    InvalidHealthcard: 'invalid-healthcard' as ErrorCode,
    InvalidService: 'invalid-service' as ErrorCode,
    DoctorNotFound: 'doctor-not-found' as ErrorCode,
    DraftInvalidForDoctor: 'draft-invalid-for-doctor' as ErrorCode,
    PatientNotFound: 'patient-not-found' as ErrorCode,
    RequiredFieldMissing: 'required-field-missing' as ErrorCode,
    InvalidFieldEntry: 'invalid-field-entry' as ErrorCode,
    OtherError: 'other-error' as ErrorCode
};

