import { Component, Input, ViewChild, inject } from '@angular/core';
import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MAT_NATIVE_DATE_FORMATS } from '@angular/material/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { MatDatepickerInput, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DateAndTimeService } from '@insig-health/services/date-and-time/date-and-time.service';
import { UtcDateAdapter } from './utc-date-adapter';

export const INS_DATE_FORMATS: MatDateFormats = {
  ...MAT_NATIVE_DATE_FORMATS,
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'insig-ui-date-picker',
  templateUrl: './insig-date-picker.component.html',
  styleUrls: ['./insig-date-picker.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: UtcDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: INS_DATE_FORMATS },
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InsigDatePickerComponent,
    },
  ],
})
export class InsigDatePickerComponent implements ControlValueAccessor {
  private readonly dateAndTimeService = inject(DateAndTimeService);
  public onChange?: (value: string) => void;
  public onTouched?: () => void;

  public _isRequired = false;
  public _isDisabled = false;

  @Input() set isRequired(isRequired: boolean | 'true' | 'false') {
    this._isRequired = coerceBooleanProperty(isRequired);
  }
  @Input() set isDisabled(isDisabled: boolean | 'true' | 'false') {
    this._isDisabled = coerceBooleanProperty(isDisabled);
  }

  @Input() label = '';
  @Input() value = '';
  @Input() minDate?: Date;
  @Input() maxDate?: Date;

  @ViewChild('matDatepickerInput') matDatepickerInput: MatDatepickerInput<Date> | undefined;

  handleBlur(event: FocusEvent): void {
    if (this.dateAndTimeService.isDateValid((event.target as HTMLInputElement).value, 'YYYY-MM-DD')) {
      // @ts-ignore private access
      const model = this.matDatepickerInput?._model;
      model?.updateSelection(new Date((event.target as HTMLInputElement).value), this);
    }
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(onChange: (value: string) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this._isDisabled = isDisabled;
  }

  handleChange(event: Event): void {
    this.onChange?.((event.target as HTMLInputElement).value);
    this.onTouched?.();
  }

  handleDateChange(event: MatDatepickerInputEvent<Date>): void {
    this.onChange?.((event.targetElement as HTMLInputElement).value);
    this.onTouched?.();
  }
}
