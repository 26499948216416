import { Injectable } from '@angular/core';
import { AppointmentServiceMedium } from '@insig-health/api/doctor-booking-flow-api-v1';

export enum AppointmentMedium {
  PHONE = 'phone',
  VIDEO = 'video',
  MESSAGING = 'messaging',
  IN_PERSON = 'inPerson',
}

@Injectable({
  providedIn: 'root',
})
export class AppointmentMediumService {
  parseAppointmentMedium(appointmentMediumString: AppointmentServiceMedium): AppointmentMedium;
  parseAppointmentMedium(appointmentMediumString: string | undefined): AppointmentMedium | undefined;
  parseAppointmentMedium(appointmentMediumString: string | undefined): AppointmentMedium | undefined {
    if (appointmentMediumString === undefined) {
      return undefined;
    }

    if (appointmentMediumString === AppointmentServiceMedium.InClinic) {
      return AppointmentMedium.IN_PERSON;
    }

    for (const [_, mediumValue] of Object.entries(AppointmentMedium)) {
      if (appointmentMediumString.toLowerCase() === mediumValue.toLowerCase()) {
        return mediumValue;
      }
    }
  }

  parseAppointmentMediums(appointmentMediumStrings: AppointmentServiceMedium[]): AppointmentMedium[];
  parseAppointmentMediums(appointmentMediumStrings: string[]): AppointmentMedium[];
  parseAppointmentMediums(appointmentMediumStrings: string[]): AppointmentMedium[] {
    if (appointmentMediumStrings.length === 0) {
      return [];
    }
    const appointmentMediums = appointmentMediumStrings
      .map((appointmentMediumString) => this.parseAppointmentMedium(appointmentMediumString))
      .filter((appointmentMedium): appointmentMedium is AppointmentMedium => appointmentMedium !== undefined);
    return appointmentMediums;
  }

  getFormattedAppointmentMedium(appointmentMedium: AppointmentMedium): string {
    switch (appointmentMedium) {
      case AppointmentMedium.IN_PERSON: {
        return 'In Person';
      }
      case AppointmentMedium.MESSAGING: {
        return 'Messaging';
      }
      case AppointmentMedium.PHONE: {
        return 'Phone';
      }
      case AppointmentMedium.VIDEO: {
        return 'Video';
      }
    }
  }
}
