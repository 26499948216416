<div mat-dialog-title class="mb-2">
  <div
    *ngIf="provinceSelectionDialogState === ProvinceSelectionState.CHOOSE_PAYMENT_METHOD"
    class="d-flex"
    role="button"
    (click)="handleChangeLocationButtonClick()"
  >
    <img class="d-inline-block me-1" src="assets/img/ico-back-arrow.svg" alt="Change Location">
    <span class="h2 bold textBlue text-decoration-underline pt-0">Change Location</span>
  </div>
  <div *ngIf="provinceSelectionDialogState === ProvinceSelectionState.CHOOSE_PROVINCE" class="d-flex">
    <div
      *ngIf="selectedProvince"
      class="d-flex me-4"
      role="button"
      (click)="handleBackButtonClick()"
    >
      <img class="d-inline-block me-1" src="assets/img/ico-back-arrow.svg" alt="Back">
      <span class="h2 bold textBlue text-decoration-underline pt-0">Back</span>
    </div>
    <span class="h2 bold">Which province are you in?</span>
  </div>
</div>

<div mat-dialog-content>
  <div *ngIf="provinceSelectionDialogState === ProvinceSelectionState.CHOOSE_PAYMENT_METHOD">
    <ng-container *ngTemplateOutlet="locationInformation"></ng-container>

    <ng-container *ngTemplateOutlet="publicAndPrivatePayButtons"></ng-container>
  </div>

  <div *ngIf="provinceSelectionDialogState === ProvinceSelectionState.CHOOSE_PROVINCE">
    <ng-container *ngTemplateOutlet="selectProvince"></ng-container>
  </div>
</div>

<ng-template #changeLocation>
  <div
    *ngIf="provinceSelectionDialogState === ProvinceSelectionState.CHOOSE_PAYMENT_METHOD"
    class="bold textBlue text-decoration-underline"
  >
    Change Location
  </div>
</ng-template>

<ng-template #locationInformation>
  <div
    *ngIf="isInternationalPatient && data.region"
    class="font-size-xl textMediumGray mx-3 pb-4"
  >
    Hi, we see you are in <span class="bold">{{ getInternationalRegionName(data.region) }}</span>
  </div>
  <div
    *ngIf="!isInternationalPatient && selectedProvince"
    class="font-size-xl textMediumGray mx-3 pb-4"
  >
    Hi, we see you are in <span class="bold">{{ getProvinceName(selectedProvince) }}</span>. Do you have a valid {{ getProvinceName(selectedProvince) }} health card?
  </div>
</ng-template>

<ng-template #publicAndPrivatePayButtons>
  <div
    *ngIf="isInternationalPatient"
    class="d-flex justify-content-between">
    <insig-ui-button
      stylePreset="tertiary"
      class="pe-1 mx-auto"
      (click)="handlePayPrivatelyAsInternationalPatientButtonClick()"
    >
      I will pay privately
    </insig-ui-button>
  </div>

  <div
    *ngIf="!isInternationalPatient && selectedProvince"
    class="d-flex justify-content-between">
    <insig-ui-button
      stylePreset="tertiary"
      class="pe-1 mx-auto"
      (click)="handlePayPrivatelyButtonClick(selectedProvince)"
    >
      No I do not, I will pay privately
    </insig-ui-button>

    <insig-ui-button
      *ngIf="selectedProvince && this.provinceService.isProvinceAvailableForPublicAppointments(selectedProvince)"
      stylePreset="secondary"
      class="ps-1"
      (click)="handleValidHealthCardButtonClick(selectedProvince)"
    >
      Yes, I have a valid {{selectedProvince}} health card
    </insig-ui-button>
  </div>
</ng-template>

<ng-template #selectProvince>
  <div class="d-flex flex-wrap">
    <insig-ui-button
      *ngFor="let region of regions"
      stylePreset="gray"
      class="m-1"
      (click)="handleRegionSelectionClick(region)"
    >
      <div class="bold">
        {{ region.regionName }}
      </div>
    </insig-ui-button>
  </div>
</ng-template>
