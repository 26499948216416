import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Component, EventEmitter, Output, ViewChild, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { DateAndTimeService } from '@insig-health/services/date-and-time/date-and-time.service';
import { FamilyMemberService } from '@insig-health/services/family-member/family-member.service';
import { RegexService } from '@insig-health/services/regex/regex.service';

import { PatientProfileFormComponent } from '../patient-profile-form/patient-profile-form.component';
import { PharmacyInfoFormComponent } from '../pharmacy-info-form/pharmacy-info-form.component';

import { FamilyMemberRequest, FamilyMemberSuccessResponse } from '@insig-health/api/patient-management-api';
import { SNACK_BAR_AUTO_DISMISS_MILLISECONDS } from '@insig-health/config/angular-material.config';

@Component({
  selector: 'insig-booking-add-new-patient-form-dialog',
  templateUrl: './add-new-patient-form-dialog.component.html',
})
export class AddNewPatientFormDialogComponent {
  public static readonly DIALOG_MAX_WIDTH = '796px';
  public static readonly DIALOG_WIDTH = '100vw';

  private readonly dateAndTimeService = inject(DateAndTimeService);
  private readonly dialogRef = inject<MatDialogRef<AddNewPatientFormDialogComponent>>(MatDialogRef<AddNewPatientFormDialogComponent>);
  private readonly formBuilder = inject(UntypedFormBuilder);
  private readonly familyMemberService = inject(FamilyMemberService);
  private readonly snackBar = inject(MatSnackBar);

  public readonly addNewPatientForm = this.formBuilder.group({});

  public PATIENT_PROFILE_FORM_NAME = PatientProfileFormComponent.PATIENT_PROFILE_FORM_NAME;
  public PHARMACY_FORM_NAME = PharmacyInfoFormComponent.PHARMACY_FORM_NAME;

  @Output() patientAdded = new EventEmitter();

  @ViewChild('patientProfileForm') patientProfileForm!: PatientProfileFormComponent;
  @ViewChild('pharmacyInfoForm') pharmacyInfoForm!: PharmacyInfoFormComponent;

  handleBackButtonClicked(): void {
    this.dialogRef.close(false);
  }

  async handleSaveButtonClicked(form: UntypedFormGroup): Promise<void> {
    if (form.invalid) {
      return;
    }

    try {
      await this.saveFamilyMemberProfileInfo(form);
      this.dialogRef.close(true);
      this.snackBar.open('Patient has been added successfully', undefined, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
    } catch (error) {
      console.error(error);
      this.snackBar.open('An error occurred while creating the patient', undefined, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
    }
  }

  saveFamilyMemberProfileInfo(form: UntypedFormGroup): Promise<FamilyMemberSuccessResponse> {
    const familyMemberRequest = this.createFamilyMemberRequestFromForm(form);
    return this.familyMemberService.createNewFamilyMember(familyMemberRequest);
  }

  createFamilyMemberRequestFromForm(form: UntypedFormGroup): FamilyMemberRequest {
    const profileForm = form.get(PatientProfileFormComponent.PATIENT_PROFILE_FORM_NAME) as UntypedFormGroup;
    const birthDate = profileForm.controls[this.patientProfileForm.DATE_OF_BIRTH_FORM_CONTROL_NAME].value;

    return {
      first: profileForm.controls[this.patientProfileForm.FIRST_NAME_FORM_CONTROL_NAME].value,
      last: profileForm.controls[this.patientProfileForm.LAST_NAME_FORM_CONTROL_NAME].value,
      gender: profileForm.controls[this.patientProfileForm.GENDER_FORM_CONTROL_NAME].value,
      address: profileForm.controls[this.patientProfileForm.STREET_ADDRESS_FORM_CONTROL_NAME].value,
      city: profileForm.controls[this.patientProfileForm.CITY_FORM_CONTROL_NAME].value,
      province: profileForm.controls[this.patientProfileForm.PROVINCE_FORM_CONTROL_NAME].value,
      postalCode: profileForm.controls[this.patientProfileForm.POSTAL_CODE_FORM_CONTROL_NAME].value,
      healthCardNumber: profileForm.controls[this.patientProfileForm.HEALTH_CARD_NUMBER_FORM_CONTROL_NAME].value,
      day: this.dateAndTimeService.getDayAsNumberFromIsoString(birthDate),
      month: this.dateAndTimeService.getMonthAsNumberFromIsoString(birthDate),
      year: this.dateAndTimeService.getYearAsNumberFromIsoString(birthDate),
      phone: this.removeNonDigitCharactersFromPhoneNumber(
        profileForm.controls[this.patientProfileForm.PHONE_NUMBER_FORM_CONTROL_NAME].value,
      ),
      familyDoctorFaxNumber: profileForm.controls[this.patientProfileForm.FAMILY_DOCTOR_FAX_NUMBER_FORM_CONTROL_NAME].value,
      familyDoctorFullName: profileForm.controls[this.patientProfileForm.FAMILY_DOCTOR_FULL_NAME_FORM_CONTROL_NAME].value,
    };
  }

  removeNonDigitCharactersFromPhoneNumber(phoneNumber: string): string {
    return phoneNumber.replace(RegexService.NON_DIGIT_GLOBAL_REGEX, '');
  }
}
