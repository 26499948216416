import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyBookingComponent } from '../company-booking/company-booking.component';
import { Subscription, firstValueFrom } from 'rxjs';
import { AppointmentReservationService, DraftAppointmentSlot } from '../../services/appointment-reservation/appointment-reservation.service';
import { BookingStep, BookingStepService } from '../../services/booking-step/booking-step.service';
import { CompanyDataService } from '@insig-health/services/company/company-data.service';
import { ProvinceBookingComponent } from '../province-booking/province-booking.component';
import { BillingTypeBookingComponent } from '../billing-type-booking/billing-type-booking.component';
import { BillingTypeService } from '../../services/billing-type/billing-type.service';
import { ThemeService } from '../../services/theme/theme.service';
import { BillingRegionService } from '../../services/billing-region/billing-region.service';

@Component({
  selector: 'insig-booking-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly bookingStepService = inject(BookingStepService);
  private readonly appointmentReservationService = inject(AppointmentReservationService);
  private readonly companyDataService = inject(CompanyDataService);
  private readonly billingTypeService = inject(BillingTypeService);
  private readonly billingRegionService = inject(BillingRegionService);
  private readonly themeService = inject(ThemeService);
  public readonly router = inject(Router);
  public BookingStep = BookingStep;

  public doctorId: string | undefined;
  public showTimer = false;

  private _currentReservedAppointmentSubscription: Subscription;
  public currentReservedAppointmentSlot: DraftAppointmentSlot | undefined;
  private _currentBookingStepChangedSubscription: Subscription;
  public currentBookingStep: BookingStep | undefined;

  public companyBranding: string | undefined;
  public themeLogoUrl = this.themeService.getCurrentThemeConfig().header.logoUrl;

  @Input() isSidenavOpen = false;

  @Output() toggleSidenavClicked = new EventEmitter<void>();

  constructor(
  ) {
    this._currentReservedAppointmentSubscription = this.appointmentReservationService
      .getCurrentReservedAppointmentSlot()
      .subscribe((currentReservedAppointment) => {
        this.handleCurrentReservedAppointmentChanged(currentReservedAppointment);
      });

    this._currentBookingStepChangedSubscription = this.bookingStepService.bookingStepChange$.subscribe((bookingStep) => {
      this.currentBookingStep = bookingStep;
    });
  }

  async ngOnInit(): Promise<void> {
    this.companyBranding = await this.getCompanyBranding();
  }

  ngOnDestroy(): void {
    this._currentReservedAppointmentSubscription.unsubscribe();
    this._currentBookingStepChangedSubscription.unsubscribe();
  }

  async getCompanyBranding(): Promise<string | undefined> {
    const companyBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.activatedRoute, [CompanyBookingComponent]);
    const companyId = companyBookingRoute.snapshot.params.companyId;
    const company = await this.companyDataService.getCompanyData(companyId);
    return company.branding;
  }

  async handleLogoClicked(): Promise<void> {
    const currentReservedAppointment = await firstValueFrom(this.appointmentReservationService.getCurrentReservedAppointmentSlot());

    if (currentReservedAppointment !== undefined) {
      this.navigateToChooseDoctorByReservedAppointment(currentReservedAppointment, this.activatedRoute);
    } else {
      this.navigateToChooseDoctorByRoute(this.activatedRoute);
    }
  }

  navigateToChooseDoctorByRoute(activatedRoute: ActivatedRoute): void {
    const deepestRouteChild = this.bookingStepService.getDeepestActivatedRouteChild(activatedRoute);
    const companyBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(deepestRouteChild, [CompanyBookingComponent]);
    const provinceBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(deepestRouteChild, [ProvinceBookingComponent]);
    const provinceAbbreviation =  provinceBookingRoute.snapshot.params.provinceAbbreviation;
    const billingTypeBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(deepestRouteChild, [BillingTypeBookingComponent]);
    const billingType = this.billingTypeService.parseBillingType(billingTypeBookingRoute.snapshot.params.billingType);
    const region = this.billingRegionService.getBillingRegion(provinceAbbreviation, billingType);
    this.bookingStepService.jumpToStep(BookingStep.CHOOSE_DOCTOR, {
      navigationExtras: { relativeTo: companyBookingRoute },
      pathParams: { region },
    });
  }

  navigateToChooseDoctorByReservedAppointment(reservedAppointment: DraftAppointmentSlot, activatedRoute: ActivatedRoute): void {
    const deepestRouteChild = this.bookingStepService.getDeepestActivatedRouteChild(activatedRoute);
    const companyBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(deepestRouteChild, [CompanyBookingComponent]);
    const billingType = this.billingTypeService.parseBillingType(reservedAppointment.billingType);
    const region = this.billingRegionService.getBillingRegion(reservedAppointment.province, billingType);
    this.bookingStepService.jumpToStep(BookingStep.CHOOSE_DOCTOR, {
      navigationExtras: { relativeTo: companyBookingRoute },
      pathParams: { region },
    });
  }

  handleCurrentReservedAppointmentChanged(currentReservedAppointment: DraftAppointmentSlot | undefined): void {
    this.showTimer = currentReservedAppointment !== undefined;
    this.currentReservedAppointmentSlot = currentReservedAppointment;
  }

  handleQuickConnectClicked(): void {
    const companyBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.activatedRoute, [CompanyBookingComponent]);
    this.router.navigate(['quickConnect'], { relativeTo: companyBookingRoute });
  }

  handleToggleSidenavClicked(): void {
    this.toggleSidenavClicked.emit();
  }
}
