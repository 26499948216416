import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { PatientProfile } from 'insig-types/user-data/index';
import { JAVA_BACKEND_ENDPOINT } from '@insig-health/config/config';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PatientDataService {
  private readonly http = inject(HttpClient);
  private getGlobalPatientProfileUrl = JAVA_BACKEND_ENDPOINT + 'patient/';
  private getCompanyPatientProfileUrl = JAVA_BACKEND_ENDPOINT + 'company/';

  getGlobalPatientProfile(patientId: string, authToken: string): Promise<PatientProfile> {
    const headers = new HttpHeaders({ Authorization: `Bearer ${authToken}` });
    return firstValueFrom(this.http
      .get<PatientProfile>(this.getGlobalPatientProfileUrl + patientId, {
        headers: headers,
      }))
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getCompanyPatientProfile(companyId: string, patientId: string, authToken: string): Promise<PatientProfile> {
    const headers = new HttpHeaders({ Authorization: `Bearer ${authToken}` });
    return firstValueFrom(this.http
      .get<PatientProfile>(this.getCompanyPatientProfileUrl + companyId + '/patient/' + patientId, {
        headers: headers,
      }))
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }
}
