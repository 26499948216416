<div class="d-flex align-items-center justify-content-between my-2 mt-md-0 gap-2 flex-wrap">
  <div *ngIf="displayText" class="textSecondary font-size-sm text-nowrap bold textDark">
    <span class="d-none d-md-inline font-size-md">How would you like to speak with your practitioner?</span>
    <span class="d-inline d-md-none">Appointment medium?</span>
    <span class="textPink">&nbsp;*</span>
  </div>
  <div class="d-flex flex-grow-1 gap-2">
    <insig-ui-radio-button
      *ngIf="availableMediums?.includes(AppointmentMedium.PHONE)"
      class="p-2"
      imageUrl="assets/img/ico-doc-phone.svg"
      value="AppointmentMedium.PHONE"
      (clicked)="handleMediumChange(AppointmentMedium.PHONE)"
      [isChecked]="selectedMedium === AppointmentMedium.PHONE"
      [radioGroup]="radioGroupName"
    >
      <span *ngIf="displayText" class="d-none d-sm-inline ps-0 ps-sm-2">Phone</span>
    </insig-ui-radio-button>
    <insig-ui-radio-button
      *ngIf="availableMediums?.includes(AppointmentMedium.VIDEO)"
      class="p-2"
      imageUrl="assets/img/ico-doc-video.svg"
      value="AppointmentMedium.VIDEO"
      (clicked)="handleMediumChange(AppointmentMedium.VIDEO)"
      [isChecked]="selectedMedium === AppointmentMedium.VIDEO"
      [radioGroup]="radioGroupName"
    >
      <span *ngIf="displayText" class="d-none d-sm-inline ps-0 ps-sm-2">Video</span>
    </insig-ui-radio-button>
    <insig-ui-radio-button
      *ngIf="availableMediums?.includes(AppointmentMedium.MESSAGING)"
      class="p-2"
      imageUrl="assets/img/ico-doc-message.svg"
      value="AppointmentMedium.MESSAGING"
      (clicked)="handleMediumChange(AppointmentMedium.MESSAGING)"
      [isChecked]="selectedMedium === AppointmentMedium.MESSAGING"
      [radioGroup]="radioGroupName"
    >
      <span *ngIf="displayText" class="d-none d-sm-inline ps-0 ps-sm-2">Message</span>
    </insig-ui-radio-button>
  </div>
</div>
