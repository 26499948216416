import { Injectable, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { DraftBookingComponent } from '../../app/draft-booking/draft-booking.component';
import { SNACK_BAR_AUTO_DISMISS_MILLISECONDS } from '@insig-health/config/config';
import { AppointmentReservationService } from '../../services/appointment-reservation/appointment-reservation.service';
import { BookingStepService } from '../../services/booking-step/booking-step.service';

@Injectable({
  providedIn: 'root',
})
export class IsDraftValidGuard implements CanActivate {
  private readonly bookingStepService = inject(BookingStepService);
  private readonly appointmentReservationService = inject(AppointmentReservationService);
  private readonly snackBar = inject(MatSnackBar);

  async canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const deepestRoute = this.bookingStepService.getDeepestActivatedRouteChild(state.root);
    const draftBookingComponentRoute = this.bookingStepService.getActivatedRouteOfComponentType(deepestRoute, DraftBookingComponent);
    const draftAppointmentId = draftBookingComponentRoute.params.draftAppointmentId;
    const isDraftValid = await this.isDraftValid(draftAppointmentId);
    if (!isDraftValid) {
      this.snackBar.open('Your time slot has expired. Please select a new time slot', undefined, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
    }
    return isDraftValid;
  }

  private async isDraftValid(draftAppointmentId: string): Promise<boolean> {
    try {
      await this.appointmentReservationService.getReservedAppointmentSlot(draftAppointmentId);
      return true;
    } catch (error) {
      return false;
    }
  }
}
