import { Component, Input } from '@angular/core';
import { BillingType } from 'apps/insig-booking/src/services/billing-type/billing-type.service';

@Component({
  selector: 'insig-booking-focus-mental-wellness',
  templateUrl: './focus-mental-wellness.component.html',
  styleUrls: ['./focus-mental-wellness.component.scss'],
})
export class FocusMentalWellnessComponent {

  static readonly PUBLIC_MENTAL_FOCUS_WELLNESS_URL = 'https://app.focusmentalwellness.com/booking/tiahealth/steps/matching/?utm_source=tiahealth&utm_medium=tile-public&utm_campaign=tia-tile-public';
  static readonly PRIVATE_MENTAL_FOCUS_WELLNESS_URL = 'https://app.focusmentalwellness.com/booking/tiahealth/steps/matching/?utm_source=tiahealth&utm_medium=tile-private&utm_campaign=tia-tile-private';

  public BillingType = BillingType;
  @Input() billingType: BillingType | undefined;

  getHrefForBillingType(billingType: BillingType): string {
    return billingType === BillingType.PUBLIC
      ? FocusMentalWellnessComponent.PUBLIC_MENTAL_FOCUS_WELLNESS_URL
      : FocusMentalWellnessComponent.PRIVATE_MENTAL_FOCUS_WELLNESS_URL;
  }
}
