import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params } from '@angular/router';
import { CompanyBookingComponent } from '../company-booking/company-booking.component';
import { SNACK_BAR_AUTO_DISMISS_MILLISECONDS } from '@insig-health/config/config';
import { AppointmentReservationService } from '../../services/appointment-reservation/appointment-reservation.service';
import { BookingStep, BookingStepService } from '../../services/booking-step/booking-step.service';
import { Subscription } from 'rxjs';
import { ThemeService } from '../../services/theme/theme.service';

@Component({
  selector: 'insig-booking-draft-booking',
  templateUrl: './draft-booking.component.html',
})
export class DraftBookingComponent implements OnInit, OnDestroy {
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly snackBar = inject(MatSnackBar);
  private readonly appointmentReservationService = inject(AppointmentReservationService);
  private readonly bookingStepService = inject(BookingStepService);
  private readonly themeService = inject(ThemeService);
  private activatedRouteParamsSubscription: Subscription | undefined;

  ngOnInit(): void {
    this.activatedRouteParamsSubscription = this.activatedRoute.params.subscribe((params) => {
      this.handleParamsChange(params);
    });
  }

  ngOnDestroy(): void {
    this.activatedRouteParamsSubscription?.unsubscribe();
  }

  async handleParamsChange(params: Params): Promise<void> {
    const draftAppointmentId = params.draftAppointmentId;
    try {
      const draftAppointmentSlot = await this.appointmentReservationService.getReservedAppointmentSlot(draftAppointmentId);
      this.appointmentReservationService.setCurrentReservedAppointmentSlot(draftAppointmentSlot);
    } catch (error) {
      if (error instanceof HttpErrorResponse && error.status === 404) {
        this.handleExpiredReservedAppointmentSlot();
      } else {
        throw error;
      }
    }
  }

  handleExpiredReservedAppointmentSlot(): void {
    this.snackBar.open('Your time slot has expired. Please select a new time slot', undefined, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
    this.appointmentReservationService.clearCurrentReservedAppointmentSlot();
    const companyBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.activatedRoute, [CompanyBookingComponent]);
    const region = this.themeService.getCurrentThemeConfig().defaultBillingRegion;
    this.bookingStepService.jumpToStep(BookingStep.CHOOSE_DOCTOR, {
      navigationExtras: { relativeTo: companyBookingRoute },
      pathParams: { region },
    });
  }
}
