import { Injectable } from '@angular/core';

export interface Clinic {
  name: string;
  type: string;
  address: string;
  imageUrls: [string, string, string];
  openingTime: string;
  closingTime: string;
}

@Injectable({
  providedIn: 'root',
})
export class ClinicService {

  getMockClinics(): Clinic[] {
    return [
      {
        name: 'Medplex Walk-In Clinic',
        type: 'Women\'s health clinic',
        address: 'Brampton, Canada',
        imageUrls: [
          'assets/img/hos-place-1a.png',
          'assets/img/hos-place-1b.png',
          'assets/img/hos-place-1c.png',
        ],
        openingTime: '10AM',
        closingTime: '9PM',
      },
      {
        name: 'Panel Physician North York',
        type: 'Medical Clinic',
        address: 'Mississauga, Canada',
        imageUrls: [
          'assets/img/hos-place-2a.png',
          'assets/img/hos-place-2b.png',
          'assets/img/hos-place-2c.png',
        ],
        openingTime: '10AM',
        closingTime: '9PM',
      },
      {
        name: 'Canadian Men\'s Clinic',
        type: 'Women\'s health clinic',
        address: 'Toronto, Canada',
        imageUrls: [
          'assets/img/hos-place-1a.png',
          'assets/img/hos-place-1b.png',
          'assets/img/hos-place-1c.png',
        ],
        openingTime: '10AM',
        closingTime: '9PM',
      },
      {
        name: 'OATC North York Clinic',
        type: 'Medical clinic',
        address: 'Toronto, Canada',
        imageUrls: [
          'assets/img/hos-place-2a.png',
          'assets/img/hos-place-2b.png',
          'assets/img/hos-place-2c.png',
        ],
        openingTime: '10AM',
        closingTime: '9PM',
      },
      {
        name: 'Cleveland Clinic',
        type: 'Medical clinic',
        address: 'Toronto, Canada',
        imageUrls: [
          'assets/img/hos-place-1a.png',
          'assets/img/hos-place-1b.png',
          'assets/img/hos-place-1c.png',
        ],
        openingTime: '10AM',
        closingTime: '9PM',
      },
    ];
  }

}
