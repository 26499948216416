import { Component, inject } from '@angular/core';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DoctorMetadata } from '@insig-health/api/doctor-booking-flow-api-v1';

@Component({
  selector: 'insig-booking-doctor-card-dialog',
  templateUrl: './doctor-card-dialog.component.html',
})
export class DoctorCardDialogComponent {
  static readonly DIALOG_MAX_WIDTH = '690px';
  static readonly DIALOG_WIDTH = '80vw';
  static readonly DEFAULT_DIALOG_CONFIG: MatDialogConfig = {
    width: DoctorCardDialogComponent.DIALOG_WIDTH,
    maxWidth:  DoctorCardDialogComponent.DIALOG_MAX_WIDTH,
  };

  private readonly dialogRef = inject<MatDialogRef<DoctorCardDialogComponent, void>>(MatDialogRef<DoctorCardDialogComponent, void>);
  private readonly data = inject<{ doctorProfile: DoctorMetadata }>(MAT_DIALOG_DATA);

  public doctorProfile: DoctorMetadata;

  constructor(
  ) {
    this.doctorProfile = this.data.doctorProfile;
  }

  handleCloseClicked(): void {
    this.dialogRef.close();
  }
}
