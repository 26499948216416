<h1 matDialogTitle class="pt-0">Wait up!</h1>

<p mat-dialog-content class="pt-2 pb-3">
  You will lose your reserved time slot if you leave this page. Do you wish to continue?
</p>

<div mat-dialog-actions class="d-flex justify-content-between">
  <insig-ui-button mat-dialog-close stylePreset="tertiary" (click)="dialogRef.close(false)">
    No
  </insig-ui-button>
  <insig-ui-button stylePreset="primary" (click)="dialogRef.close(true)">
    Yes
  </insig-ui-button>
</div>
