<ul class="d-flex justify-content-around bold">
  <li
    [class.complete]="isChooseDoctorStepComplete(currentBookingStep)"
    [class.active]="isBookingStepActive(currentBookingStep, BookingStep.CHOOSE_DOCTOR) || isBookingStepActive(currentBookingStep, BookingStep.QUICK_CONNECT)"
    [attr.role]="isChooseDoctorStepComplete(currentBookingStep) ? 'button' : ''"
    [attr.tabindex]="isChooseDoctorStepComplete(currentBookingStep) ? 0 : -1"
    (click)="handleChooseDoctorStepClicked()"
  >
    <div class="d-none d-md-block">Choose a Practitioner</div>
  </li>

  <li
    [class.complete]="isChooseTimeStepComplete(currentBookingStep)"
    [class.active]="isBookingStepActive(currentBookingStep, BookingStep.CHOOSE_TIME)"
    [attr.role]="isChooseTimeStepComplete(currentBookingStep) ? 'button' : ''"
    [attr.tabindex]="isChooseTimeStepComplete(currentBookingStep) ? 0 : -1"
    (click)="handleChooseTimeStepClicked()"
  >
    <div class="d-none d-md-block">Choose a Time</div>
  </li>

  <li
    [class.complete]="isLoginStepComplete(currentBookingStep)"
    [class.active]="isBookingStepActive(currentBookingStep, BookingStep.LOGIN)"
    class="cursorNotAllowed"
  >
    <div class="d-none d-md-block">Your Details</div>
  </li>

  <li
    [class.complete]="isConfirmBookingStepComplete(currentBookingStep)"
    [class.active]="isBookingStepActive(currentBookingStep, BookingStep.CONFIRM_BOOKING)"
    [attr.role]="isConfirmBookingStepComplete(currentBookingStep) ? 'button' : ''"
    [attr.tabindex]="isConfirmBookingStepComplete(currentBookingStep) ? 0 : -1"
    (click)="handleConfirmBookingStepClicked()"
  >
    <div class="d-none d-md-block">Confirm Booking</div>
  </li>
</ul>
