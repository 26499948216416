<div class="backgroundImage h-100">
  <h1>Quick Connect</h1>
  <p role="doc-subtitle" class="mb-3">Select the medium & continue</p>

  <div *ngIf="quickConnectDoctor !== undefined" class="quickConnectGrid">
    <insig-ui-card>

      <div class="d-flex">
        <img class="objectFitContain objectPositionTop pe-3" [src]="quickConnectDoctor.doctorMetadata.profilePictureUrl" alt="">
        <div>
          <insig-ui-button stylePreset="tertiary" iconSrc="assets/img/ico-btn-shuffle.svg"
            (click)="handleShufflePractitionerClicked()"
          >
            Shuffle Practitioner
          </insig-ui-button>

          <div class="py-2">
            <!-- spacer -->
          </div>

          <div class="h2 notranslate">{{ quickConnectDoctor.doctorMetadata.fullName }}</div>
          <div class="h3">{{ quickConnectDoctor.doctorMetadata.specialty }}</div>
        </div>
      </div>

      <div class="languagesAndSpecializationsGrid mt-5">
        <div>
          <img src="assets/img/ico-doc-language.svg" alt="">
          <div class="textSecondary bold">Langauges known</div>
          <p class="textSecondary">
            <ng-container *ngFor="let language of quickConnectDoctor.doctorMetadata.languagesSpoken; let index = index">
              {{
                doctorService.getLanguageNameFromAbbreviation(language)
              }}<ng-container *ngIf="index !== quickConnectDoctor.doctorMetadata.languagesSpoken.length - 1">, </ng-container>
            </ng-container>
          </p>
        </div>
        <div>
          <img src="assets/img/ico-doc-speciali.svg" alt="">
          <div class="textSecondary bold">Credentials</div>
          <p class="textSecondary">{{ quickConnectDoctor.doctorMetadata.credentials }}</p>
        </div>
      </div>

      <div class="bold textDark mt-5">
        How would you like to speak with your practitioner?<span class="text-danger">&nbsp;*</span>
      </div>

      <div class="mediumSelectionGrid mt-2">
        <insig-ui-radio-button
          *ngIf="quickConnectDoctor.service.mediums.includes(AppointmentServiceMedium.Phone)"
          class="p-3"
          radioGroup="appointmentMedium"
          imageUrl="assets/img/ico-doc-phone.svg"
          value="AppointmentServiceMedium.Phone"
        >
          <span>Phone</span>
        </insig-ui-radio-button>
        <insig-ui-radio-button
          *ngIf="quickConnectDoctor.service.mediums.includes(AppointmentServiceMedium.Video)"
          class="p-3"
          radioGroup="appointmentMedium"
          imageUrl="assets/img/ico-doc-video.svg"
          value="AppointmentServiceMedium.Video"
        >
          <span>Video</span>
        </insig-ui-radio-button>
        <insig-ui-radio-button
          *ngIf="quickConnectDoctor.service.mediums.includes(AppointmentServiceMedium.Messaging)"
          class="p-3"
          radioGroup="appointmentMedium"
          imageUrl="assets/img/ico-doc-message.svg"
          value="AppointmentServiceMedium.Messaging"
        >
          <span>Messaging</span>
        </insig-ui-radio-button>
      </div>

      <div class="py-2">
        <!-- spacer -->
      </div>

      <div class="mt-3 d-flex align-items-center justify-content-between">
        <insig-ui-button
          stylePreset="tertiary"
          (click)="handleBackClicked()"
        >
          Back
        </insig-ui-button>
        <insig-ui-button
          iconSrc="assets/img/ico-continue.svg"
          [disabled]="isContinueDisabled"
          (click)="handleContinueClicked()"
        >
          Continue
        </insig-ui-button>
      </div>

    </insig-ui-card>

    <div class="displayText">
      Connect with a practitioner right away!<br>
      Find out what's troubling you.
      <p class="textSecondary">
        We have <span class="notranslate">{{ quickConnectDoctor.doctorMetadata.fullName }}</span>,
        a {{ quickConnectDoctor.doctorMetadata.specialty }}
        immediately available for you.
      </p>
    </div>
  </div>
</div>
